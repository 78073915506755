import React, { Fragment, Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { DOMAIN } from '../../../utils/api';

class TopHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: this.props.auth.isAuthenticated,
      header_menu: [],
      isScrollingUp: false,
    };
  }

  componentDidMount = () => {
    this.prev = window.scrollY;
    window.addEventListener('scroll', (e) => this.handleNavigation(e));
    this.getHeaderMenu();
  };

  handleNavigation = (e) => {
    const window = e.currentTarget;

    if (this.prev > window.scrollY) {
      this.setState({ isScrollingUp: true });
    } else if (this.prev < window.scrollY) {
      this.setState({ isScrollingUp: false });
    }
    this.prev = window.scrollY;
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
      window.location.reload(false);
      return false;
    }
    return true;
  };

  onLogout = (e) => {
    e.preventDefault();
    this.props.userLogout();
    this.props.history.push('/login');
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  getHeaderMenu = () => {
    // let apiUrl = `${DOMAIN}/wp-json/menus/v1/menus/header-menu`;
    let apiUrl = `${DOMAIN}/wp-json/menus/v1/menus/new-menu-en`;

    axios.get(apiUrl).then((res) => {
      const header_menu = res.data.items.map((menu) => {
        return {
          title: menu.title,
          url: menu.url,
          child_items: menu.child_items ? menu.child_items : [],
        };
      });
      // console.log(header_menu);
      this.setState({ header_menu });
    });
  };

  renderChildMenu = (url, title, child_items, topLevel = false) => {
      return (
        <li
          className={`menu-item dropdown-menu-title ${!topLevel ? 'submenu-item' : ''}`}>
          <div className="menu-link d-flex align-items-center">
            <a href={url} className="title letter-spacing-0 text-black mx-1" dangerouslySetInnerHTML={{ __html: title }} />
            <i className="fa fa-chevron-down fs-15 submenu-icon"></i>
          </div>
          <ul className="dropdown-content">
            {child_items
              ? child_items.map((subMenu, sidx) => {
                if( subMenu?.child_items ) {
                  return <Fragment key={`header-menu-${sidx + 1}`}>{this.renderChildMenu(subMenu.url, subMenu.title, subMenu?.child_items)}</Fragment>;
                } else { 
                  return (
                    <li
                      className="submenu-item"
                      key={`header-submenu-${sidx + 1}`}>
                      <a href={subMenu.url}>
                        <span className="title letter-spacing-0 text-black mx-1" dangerouslySetInnerHTML={{ __html: subMenu.title }} />
                      </a>
                    </li>
                  );
                }
                })
              : null}
          </ul>
        </li>
      );
  };

  render() {
    const { auth, history, uspHeader, isMobileScreen } = this.props;
    const { isAuthenticated, user } = auth;
    const { pathname } = history.location;
    const { header_menu, isScrollingUp } = this.state;

    let render_header;

    if (isAuthenticated) {
      render_header = header_menu.map(({ title, url, child_items }, idx) => {
        // Tutor Header
        if (user.type === 'tutor') {
          if (
            title.toLowerCase() !== 'sessions' &&
            title.toLowerCase() !== 'find a tutor' &&
            title.toLowerCase() !== 'packages'
          ) {
            if (title.toLowerCase() === 'my account' || title.toLowerCase() === 'account') {
              return (
                <li className="menu-item" key={`header-menu-${idx + 1}`}>
                  <a href={url} className="menu-link">
                    {user.avatar ? (
                      <div
                        style={{
                          backgroundImage: `url("${user.avatar}")`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          borderRadius: '50%',
                          height: 28,
                          width: 50,
                        }}></div>
                    ) : (
                      <div className="initial-profile">
                        {user.name.charAt(0)}
                      </div>
                    )}
                    {user.avatar ? (
                      <span
                        style={{ maxWidth: 100 }}
                        className="title letter-spacing-0 ml-0 text-black"
                        dangerouslySetInnerHTML={{ __html: title }} />
                    ) : (
                      <span
                        style={{ maxWidth: 100 }}
                        className="title letter-spacing-0 ml-0 text-black">
                        {title}
                      </span>
                    )}
                  </a>
                </li>
              );
            } else if (child_items) {
              return <Fragment key={`header-menu-${idx + 1}`}>{this.renderChildMenu(url, title, child_items, true)}</Fragment>
            } else if (title.toLowerCase() === 'login') {
              return <Fragment key={`header-menu-${idx + 1}`}></Fragment>;
            } else {
              return (
                <li className="menu-item" key={`header-menu-${idx + 1}`}>
                  <a href={url} className="menu-link">
                    <span className="title letter-spacing-0 text-black mx-1" dangerouslySetInnerHTML={{ __html: title }} />
                  </a>
                </li>
              );
            }
          } else {
            return null;
          }
        } else {
          // Student header menu
          if (title.toLowerCase() === 'my account' || title.toLowerCase() === 'account') {
            return (
              <li className="menu-item" key={`header-menu-${idx + 1}`}>
                <a href={url} className="menu-link">
                  {user.avatar ? (
                    <div
                      style={{
                        backgroundImage: `url("${user.avatar}")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '50%',
                        height: 32,
                        width: 32,
                      }}></div>
                  ) : (
                    <div className="initial-profile">{user.name.charAt(0)}</div>
                  )}
                  {user.avatar ? (
                    <span
                      style={{ maxWidth: 100 }}
                      className="title letter-spacing-0 ml-0 text-black"
                      dangerouslySetInnerHTML={{ __html: title }} />
                  ) : (
                    <span
                      style={{ maxWidth: 100 }}
                      className="title letter-spacing-0 ml-0 text-black">
                      {title}
                    </span>
                  )}
                </a>
              </li>
            );
          } else if (child_items) {
            return <Fragment key={`header-menu-${idx + 1}`}>{this.renderChildMenu(url, title, child_items, true)}</Fragment>;
          } else if (title.toLowerCase() === 'login') {
            return <Fragment key={`header-menu-${idx + 1}`}></Fragment>;
          } else {
            return (
              <li className="menu-item" key={`header-menu-${idx + 1}`}>
                <a href={url} className="menu-link">
                  <span className="title letter-spacing-0 text-black mx-1" dangerouslySetInnerHTML={{ __html: title }} />
                </a>
              </li>
            );
          }
        }
      });
    } else {
      render_header = header_menu.map(({ title, url, child_items }, idx) => {
        if (child_items) {
          return <Fragment key={`header-menu-${idx + 1}`}>{this.renderChildMenu(url, title, child_items, true)}</Fragment>;
        } else if (title.toLowerCase() === 'my account') {
          return <Fragment key={`header-menu-${idx + 1}`}></Fragment>;
        // } else if (title.includes('wpml')) {
        //   return null;
        } else {
          return (
            <li className="menu-item" key={`header-menu-${idx + 1}`}>
              <a href={url} className="menu-link">
                <span className="title letter-spacing-0 text-black mx-1" dangerouslySetInnerHTML={{ __html: title }} />
              </a>
            </li>
          );
        }
      });
    }

    let render_usp_header = null;

    if (
      pathname === '/book-language-course' &&
      uspHeader.usps &&
      uspHeader.usps.length > 0 &&
      !isMobileScreen
    ) {
      render_usp_header = uspHeader.usps.map((item, idx) => (
        <div
          key={`${uspHeader.id}-${idx}`}
          className="d-flex align-items-center justify-content-around bg-transparent mr-4 h-40px">
          <i className="ion-checkmark-round text-white fs-14 mr-2"></i>
          <p className="mb-0 text-white">{item}</p>
        </div>
      ));
    }

    return (
      <div>
        <div
          className="header-wrapper"
          style={{
            height:
              pathname === '/book-language-course' && render_usp_header ? '140px' : '100px',
          }}>
          <div className="d-flex align-items-center justify-content-center bg-main-blue">
            {render_usp_header}
          </div>
          <header
            id="top-header"
            className={`custom-container topbar topbar-expand-xl topbar-secondary ${
              isScrollingUp ? 'show-menu' : 'hide-menu'
            }`}
            style={{
              top: pathname === '/book-language-course' && render_usp_header ? '40px' : 0,
            }}>
            <div className="topbar-left">
              <span className="topbar-brand">
                <a href={`${DOMAIN}`}>
                  <img
                    src="/assets/img/logo.png"
                    alt="logo-icon"
                    className="h-50px"
                  />
                </a>
              </span>
            </div>
            <div className="topbar-right">
              <button className="topbar-btn topbar-menu-toggler">
                <span></span>
                <span></span>
                <span></span>
              </button>
              <nav className="topbar-navigation">
                <ul className="menu">{render_header}</ul>
              </nav>
            </div>
          </header>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(TopHeader);
