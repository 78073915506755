import React from 'react';
import ReactInputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';

const DateSelectGroup = ({
  minDate,
  maxDate,
  value,
  label,
  error,
  onChange,
  type,
  name,
  isDisabled,
  dateAvailableArray,
  isOptional,
  loadingRender,
}) => {
  let dateFilter = null;

  const isWeekday = (date) => {
    const day = new Date(date).getDay();
    return day !== 0 && day !== 6;
  };

  const isWeekend = (date) => {
    const day = new Date(date).getDay();
    return day === 0 || day === 6;
  };

  if (dateAvailableArray) {
    if (
      dateAvailableArray.includes('weekend') &&
      dateAvailableArray.includes('weekdays')
    ) {
      dateFilter = null;
    }
    if (
      dateAvailableArray.includes('weekend') &&
      !dateAvailableArray.includes('weekdays')
    ) {
      dateFilter = isWeekend;
    }
    if (
      !dateAvailableArray.includes('weekend') &&
      dateAvailableArray.includes('weekdays')
    ) {
      dateFilter = isWeekday;
    }
  }

  return (
    <div
      className={classnames('form-group', { 'has-error has-danger': error })}>
      <label className="col-form-label font-weight-bold fs-15 pl-12">
        {label}
        {isOptional ? (
          <span className="mx-1 font-weight-normal letter-spacing-0 font-italic fs-12">
            ( optional )
          </span>
        ) : null}
      </label>
      {loadingRender ? (
        <div>
          <div
            className="loading-form-wrapper form-control px-20 py-0"
            style={{ borderRadius: '25px', height: '42px!important' }}>
            <div className="running-background"></div>
          </div>
        </div>
      ) : (
        <div className="input-group">
          <div className="flex-grow-2" style={{ zIndex: 99 }}>
            {type === 'input-mask' ? (
              <ReactInputMask
                value={value}
                mask="99-99-9999"
                name={name}
                className={
                  error
                    ? 'form-control bg-transparent react-datepicker-style border-danger px-20'
                    : 'form-control react-datepicker-style px-20'
                }
                onChange={onChange}
                disabled={isDisabled}
                placeholder="DD-MM-YYYY"
              />
            ) : (
              <>
                {/* <DatePicker
                dateFormat="DD-MM-YYYY"
                selected={value}
                onChange={onChange}
                className={
                  error ? 'form-control bg-transparent react-datepicker-style px-20 border-danger'
                  : 'form-control bg-transparent react-datepicker-style px-20'
                }
                filterDate={dateFilter}
                placeholderText="DD-MM-YYYY"
                disabled={isDisabled}
                minDate={minDate ? minDate : null}
                maxDate={maxDate ? maxDate : null}
                fixedHeight={true}
                scrollableYearDropdown={false}
                showYearDropdown={false}
                showMonthDropdown={true}
                popperPlacement="bottom-start"
                readOnly
              /> */}
                <DatePicker
                  selected={value}
                  dateFormat="dd-MM-yyyy"
                  onChange={onChange}
                  className={
                    error
                      ? 'form-control bg-transparent react-datepicker-style px-20 border-danger'
                      : 'form-control bg-transparent react-datepicker-style px-20'
                  }
                  filterDate={dateFilter}
                  placeholderText="DD-MM-YYYY"
                  disabled={isDisabled}
                  minDate={minDate ? minDate : null}
                  maxDate={maxDate ? maxDate : null}
                  fixedHeight={true}
                  scrollableYearDropdown={false}
                  showYearDropdown={false}
                  showMonthDropdown={true}
                  popperPlacement="bottom-start"
                />
              </>
            )}
          </div>
          <i
            className="fa fa-calendar-o absolute-center"
            style={{
              position: 'absolute',
              top: '28%',
              right: 20,
            }}></i>
          {/* <div className="input-group-prepend">
            <span className="input-group-text p-10 bg-transparent">
            </span>
          </div> */}
        </div>
      )}
      {error && <div className="form-control-feedback">{error}</div>}
    </div>
  );
};

export default DateSelectGroup;
