import React, { Component } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { TimePicker } from 'antd';

// import SelectFieldGroup from '../../../common/SelectFieldGroup';
// import AsyncSelectFieldGroup from '../../../common/AsyncSelectFieldGroup';

import { connect } from 'react-redux';
import { validateEditInput } from '../../../common/validations/appointment';
import {
  userEditAppointment,
  userSetAppointmentExpired,
} from '../../../../redux/actions/appointmentActions';
import { userGetAllCountries } from '../../../../redux/actions/commonActions';
import MDSpinner from 'react-md-spinner';
import TextFieldGroupLogin from '../../../common/TextFieldGroupLogin';

class TutorAppointmentDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpcomingAppointment: false,
      isEditing: false,
      data: {},
      edited_data: {
        city: null,
        country: null,
        meeting_point: '',
        time_session: moment(this.props.data.appointment_date, 'MM/DD/YYYY')
          .hour(0)
          .minute(0),
      },
      isLoading: false,
      notification: {},
      errors: {},
      city_options: [],
      isOpenTimepicker: false,
    };
  }

  componentWillMount = () => {
    const today = moment(new Date()).format('MM/DD/YYYY');
    const isUpcomingAppointment = moment(
      this.props.data.appointment_date,
    ).isSameOrAfter(today);

    this.setState({
      data: this.props.data,
      isUpcomingAppointment,
    });
  };

  onEdit = () => {
    const recent_time = moment(this.props.data.time_session, 'h:mm a');
    this.setState({
      isEditing: true,
      edited_data: {
        ...this.state.edited_data,
        meeting_point: !isEmpty(this.props.data.meeting_point)
          ? this.props.data.meeting_point
          : '',
        time_session: this.props.data.time_session
          ? recent_time
          : moment(this.props.data.appointment_date, 'MM/DD/YYYY')
              .hour(0)
              .minute(0),
      },
    });
  };

  onCancelEdit = () => {
    this.setState({
      isEditing: false,
    });
  };

  isValid = () => {
    const { edited_data, data } = this.state;
    const { errors, isValid } = validateEditInput(
      edited_data,
      data.session_type.value,
    );
    if (!isValid) {
      this.setState({ errors: errors });
    }
    return isValid;
  };

  onSaveEdit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, notification: {}, errors: {} });
    if (this.isValid()) {
      const { edited_data, data } = this.state;
      const expired_date = moment(
        this.state.data.created_date,
        'MM/DD/YYYY, HH:mm:ss',
      ).add(2, 'days');

      const today = moment(new Date()).format('MM/DD/YYYY, HH:mm:ss');
      const todayMoment = moment(today, 'MM/DD/YYYY, HH:mm:ss');

      const isExpired = moment(todayMoment).isAfter(expired_date);

      const dataToSubmit = {
        _id: data._id,
        isConfirmed: data.isConfirmed,
        time_session: edited_data.time_session.format('h:mm a'),
      };
      if (data.session_type.value === 'live') {
        // dataToSubmit['country'] = edited_data.country;
        // dataToSubmit['city'] = edited_data.city;
        dataToSubmit['meeting_point'] = edited_data.meeting_point;
      }

      if (data.isConfirmed === false && isExpired) {
        this.props
          .userSetAppointmentExpired(dataToSubmit)
          .then((res) => {
            if (res.status !== 200) {
              this.setState({
                isLoading: false,
                notification: {
                  text: 'Internal server error. Please try again later.',
                  isError: true,
                },
              });
            } else {
              this.setState({
                isLoading: false,
                data: res.message.data,
                isEditing: false,
                edited_data,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              isLoading: false,
              notification: {
                text: 'Failed to edit appointment. Please try again later.',
                isError: true,
              },
            });
          });
      } else {
        this.props
          .userEditAppointment(dataToSubmit)
          .then((res) => {
            if (res.status !== 200) {
              this.setState({
                isLoading: false,
                notification: {
                  text: 'Internal server error. Please try again later.',
                  isError: true,
                },
              });
            } else {
              this.setState({
                isLoading: false,
                data: res.message.data,
                isEditing: false,
                edited_data,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              isLoading: false,
              notification: {
                text: 'Failed to edit appointment. Please try again later.',
                isError: true,
              },
            });
          });
      }
    } else {
      this.setState({ isLoading: false });
    }
  };

  onChangeTimePicker = (value) => {
    this.setState({
      edited_data: {
        ...this.state.edited_data,
        time_session: value,
      },
    });
  };

  onChangeSelectCountry = (val) => {
    const country = {
      value: val.value,
      label: val.label,
    };
    const city_options = val.city_options;
    this.setState({
      edited_data: {
        ...this.state.edited_data,
        country,
      },
      errors: {
        ...this.state.errors,
        country: null,
      },
      city_options,
    });
  };

  onChangeCity = (val) => {
    this.setState({
      edited_data: {
        ...this.state.edited_data,
        city: val,
      },
      errors: {
        ...this.state.errors,
        city: null,
      },
    });
  };

  onChangeMeetingPoint = (e) => {
    this.setState({
      edited_data: {
        ...this.state.edited_data,
        meeting_point: e.target.value,
      },
      errors: {
        ...this.state.errors,
        meeting_point: null,
      },
    });
  };

  loadOptionsCountry = (inputValue, callback) => {
    this.props
      .userGetAllCountries({ query: inputValue })
      .then((response) => {
        callback(response.message.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      // person,
      student_id,
      time_session,
      appointment_date,
      booking_id,
      session_type,
      session_duration,
      isCanceled,
      meeting_point,
      // isConfirmed
      // country,
      // city
    } = this.state.data;

    const {
      isUpcomingAppointment,
      isEditing,
      edited_data,
      // city_options,
      isLoading,
      errors,
    } = this.state;

    // const timeFormat = 'h:mm a';

    // Check confirmation expiry
    const expired_date = moment(
      this.state.data.created_date,
      'MM/DD/YYYY, HH:mm:ss',
    ).add(2, 'days');

    const today = moment(new Date()).format('MM/DD/YYYY, HH:mm:ss');
    const todayMoment = moment(today, 'MM/DD/YYYY, HH:mm:ss');

    const isExpired = moment(todayMoment).isAfter(expired_date);

    return (
      <div className={this.props.isOpenNotesModal ? 'd-none' : 'd-block'}>
        <div
          className="modal modal-center fade show"
          id="modal-center"
          style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div
              className="modal-content"
              style={{ width: this.props.isMobileScreen ? '100%' : '692px' }}>
              <div className="text-right p-10">
                <i
                  onClick={this.props.closeModal}
                  className="ion-close-round cursor-pointer"></i>
              </div>
              <div
                className="modal-header justify-content-between pt-20 pb-10 px-30"
                style={{
                  flexDirection: this.props.isMobileScreen ? 'column' : 'row',
                }}>
                <h5 className="modal-title fw-500 fs-25">
                  {student_id.first_name} {student_id.last_name}
                </h5>
                <h5 className="modal-title fw-500 fs-25">ID : {booking_id}</h5>
              </div>
              <div className="modal-body m-0 p-30">
                <div className="row m-auto">
                  <div className="col-md-4 h-80px">
                    <label className="mb-0 fs-14">Order date</label>
                    <p className="mb-0 fs-18 font-weight-bold">
                      {moment(appointment_date, 'MM/DD/YYYY').format(
                        'MMMM DD, YYYY',
                      )}
                    </p>
                  </div>
                  <div className="col-md-4 h-80px">
                    <label className="mb-0 fs-14">Session type</label>
                    <p className="mb-0 fs-18 font-weight-bold">
                      {session_type.label}
                    </p>
                  </div>
                  <div className="col-md-4 h-80px">
                    <label className="mb-0 fs-14">Session Duration</label>
                    <p className="mb-0 fs-18 font-weight-bold">
                      {`${
                        Math.floor(session_duration / 60) > 0
                          ? `${Math.floor(session_duration / 60)}h`
                          : ''
                      } ${session_duration % 60}m`}
                    </p>
                  </div>
                  {isEditing ? (
                    <div className="col-md-4 h-80px">
                      <label
                        className={
                          isEditing ? 'mb-0 fs-14 pl-12' : 'mb-0 fs-14'
                        }>
                        Time
                      </label>
                      <div style={{ display: 'grid' }}>
                        <TimePicker
                          minuteStep={5}
                          use12Hours
                          format="h:mm a"
                          value={edited_data.time_session}
                          onChange={this.onChangeTimePicker}
                          onSelect={this.onChangeTimePicker}
                          className="form-control"
                          inputReadOnly={true}
                          showNow={false}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-4 h-80px">
                      <label className="mb-0 fs-14">Time</label>
                      <p
                        className="mb-0 fs-18 text-uppercase"
                        style={{
                          color: !isEmpty(time_session)
                            ? 'black'
                            : 'rgba(0,0,0,0.5)',
                          fontWeight: !isEmpty(time_session)
                            ? 'bold'
                            : 'normal',
                        }}>
                        {!isEmpty(time_session) ? time_session : 'TBD'}
                      </p>
                    </div>
                  )}
                  {session_type.value === 'live' ? (
                    isEditing ? (
                      <div className="col-md-8 h-80px">
                        <TextFieldGroupLogin
                          label="Meeting Point"
                          field="meeting_point"
                          labelAddStyle={{
                            padding: 0,
                          }}
                          value={edited_data.meeting_point}
                          placeholder="Type something"
                          onChange={this.onChangeMeetingPoint}
                          disabled={isLoading}
                          error={errors.meeting_point}
                        />
                      </div>
                    ) : (
                      <div className="col-md-8 h-80px">
                        <label className="mb-0 fs-14">Meeting Point</label>
                        <p
                          className="mb-0 text-capitalize"
                          style={{
                            color: !isEmpty(meeting_point)
                              ? 'black'
                              : 'rgba(0,0,0,0.5)',
                            fontWeight: !isEmpty(meeting_point)
                              ? 'bold'
                              : 'normal',
                            fontSize: !isEmpty(meeting_point) ? '18px' : '18px',
                          }}>
                          {!isEmpty(meeting_point) ? meeting_point : 'TBD'}
                        </p>
                      </div>
                    )
                  ) : null}
                  {/* { session_type.value === 'live' ?
                    isEditing ?
                      <div className='col-md-4 h-80px'>
                        <label className='mb-0 fs-14'>City</label>
                        <SelectFieldGroup
                          label='City'
                          value={edited_data.city}
                          options={city_options}
                          labelClassName='d-none '
                          onChange={this.onChangeCity}
                          isClearable={false}
                          error={errors.city}
                          isDisabled={isLoading}
                          isSearchable={true}
                          labelWeightNormal={false}
                        />
                      </div>
                      : <div className='col-md-4 h-80px'>
                      <label className='mb-0 fs-14'>City</label>
                      <p
                        className='mb-0 fs-18 text-capitalize'
                        style={{
                          color: !isEmpty(city) ? 'black' : 'rgba(0,0,0,0.5)',
                          fontWeight: !isEmpty(city) ? 'bold' : 'normal'
                        }}
                      >{!isEmpty(city) ? city.label : 'TBD' }</p>
                    </div>
                  : null} */}
                  {/* { session_type.value === 'skype' ?
                    <div className='col-md-4 h-80px'>
                      <label className='mb-0 fs-14'>Skype ID</label>
                      <p className='mb-0 fs-18 font-weight-bold'>{student_id.skype_id}</p>
                    </div>
                  : null } */}
                  {session_type.value === 'live' ? (
                    <div className="col-md-4 h-80px">
                      <label className="mb-0 fs-14">Phone number</label>
                      <p className="mb-0 fs-18 font-weight-bold text-capitalize">
                        {!isEmpty(student_id.phone) ? student_id.phone : '-'}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              {isUpcomingAppointment && !isCanceled ? (
                <div className="modal-footer px-30">
                  {isEditing ? (
                    isLoading ? (
                      <MDSpinner />
                    ) : (
                      <div className="d-flex">
                        <button
                          onClick={this.onCancelEdit}
                          className="btn button-transparent mr-3">
                          Cancel
                        </button>
                        <button
                          onClick={this.onSaveEdit}
                          className="btn btn-main-pink">
                          Save & send to student
                        </button>
                      </div>
                    )
                  ) : (
                    <button onClick={this.onEdit} className="btn btn-main-pink">
                      Edit
                    </button>
                  )}
                </div>
              ) : null}
              {(!isUpcomingAppointment && isExpired) ||
              (!isUpcomingAppointment &&
                this.state.data.booking_status === 'expired') ? (
                <div className="modal-footer px-30">
                  <p className="text-danger font-italic">
                    Confirmation time for this appointment has expired
                  </p>
                </div>
              ) : null}
              {isCanceled ? (
                <div className="modal-footer px-30">
                  <p
                    className="mb-0 font-italic fs-13"
                    style={{ color: '#d9d9d9' }}>
                    This appointment has been canceled
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  }
}

export default connect(null, {
  userGetAllCountries,
  userEditAppointment,
  userSetAppointmentExpired,
})(TutorAppointmentDetailModal);
