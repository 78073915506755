import React, { Component, useMemo } from "react";
import {
	Redirect,
	Route,
	Switch,
	withRouter,
	useParams,
} from "react-router-dom";

import SearchTutor from "./views/SearchTutor";
import TutorDetails from "./views/TutorDetails";
import BookingOverview from "./views/BookingOverview";

import { connect } from "react-redux";
import { resetSearchData } from "../../redux/actions/bookActions";
import { isEmpty } from "lodash";

class BookingPage extends Component {
	componentWillUnmount = () => {
		this.props.resetSearchData();
	};

	componentDidMount = () => {
		const { search } = window.location;
		const searchParse = new URLSearchParams(search.substring(1));
		const emailSource = searchParse.get("email_source");

		if (emailSource && isEmpty(this?.props?.auth?.user)) {
			window.location = "/login/student";
		}
	};

	render() {
		const { match, auth, history, location } = this.props;

		return (
			<div className='main-content'>
				<BookingPageRoute />
				<div className='mb-50 mx-auto' style={{ maxWidth: 1528 }}>
					<Switch>
						{auth.user.type === "tutor" ? (
							<Redirect to='/profile/appointments' />
						) : (
							<>
								<Route
									path={`${match.path}/:name/booking-overview`}
									match={match}
									history={history}
									component={BookingOverview}
								/>
								<Route
									exact
									path={`${match.path}/:name`}
									match={match}
									history={history}
									component={() => (
										<BookingPageRoute
											match={match}
											history={history}
											location={location}
										/>
									)}
								/>
								<Route
									exact
									path={`${match.path}`}
									match={match}
									history={history}
									component={SearchTutor}
								/>
							</>
						)}
					</Switch>
				</div>
			</div>
		);
	}
}

const BookingPageRoute = ({ match, history, location }) => {
	const thisParam = useParams();
	const { name } = thisParam;

	const typeBookingRoute = useMemo(() => {
		if (name) {
			return name.split("-")[0];
		}

		return "";
	}, [name]);

	if (typeBookingRoute === "learn") {
		return (
			<SearchTutor
				params={thisParam}
				match={match}
				history={history}
				location={location}
			/>
		);
	}

	if (typeBookingRoute === "tutor") {
		return (
			<TutorDetails match={match} history={history} location={location} />
		);
	}
};

function mapStateToProps(state) {
	return {
		auth: state.auth,
	};
}

export default withRouter(
	connect(mapStateToProps, { resetSearchData })(BookingPage)
);
