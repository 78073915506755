import React, { Component } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';

import Notification from '../../../common/Notification';
import SelectFieldGroup from '../../../common/SelectFieldGroup';
// import EditableTextInput from '../../../common/EditableTextInput';
import TextFieldGroupLogin from '../../../common/TextFieldGroupLogin';
// import AsyncSelectFieldGroup from '../../../common/AsyncSelectFieldGroup';

import { connect } from 'react-redux';
import {
  validateAddressDetails,
  validateEmergencyContact,
  validateInputLoginDetails,
} from '../../../common/validations/tutorProfile';
import {
  userChangePassword,
  userGetSingleProfile,
  userUpdateProfileInfo,
} from '../../../../redux/actions/tutorActions';
import {
  userGetAllCountries,
  userGetAllWorldCountries,
  userGetCountryDetails,
} from '../../../../redux/actions/commonActions';
import MDSpinner from 'react-md-spinner';

class TutorAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileScreen: false,
      data: {
        current_bank_number: '',
        new_bank_number: '',
        // Login Details
        email: '',
        current_password: '',
        new_password: '',
        confirm_password: '',
        // Address Details
        living_in_country: '',
        living_in_city: '',
        postcode: '',
        street_name: '',
        house_number: '',
        phone: '',
        // Emergency Contact ( ec )
        full_name_ec: '',
        email_ec: '',
        country_ec: '',
        city_ec: '',
        street_name_ec: '',
        house_number_ec: '',
        postcode_ec: '',
        phone_ec: '',
      },
      country_options: [],
      city_options: [],
      city_ec_options: [],
      isLoading: false,
      notification: {},
      bankNumberEdit: false,
      showAccountDetails: false,
      isLoading_bank_details: false,
      isLoading_login_details: false,
      isLoading_address_details: false,
      isLoading_ec_details: false,
      notification_bank_details: {},
      notification_login_details: {},
      notification_address_details: {},
      notification_ec_details: {},
      errors_bank_details: {},
      errors_login_details: {},
      errors_address_details: {},
      errors_ec_details: {},
      isEditLoginSuccess: false,
      isEditAddressSuccess: false,
      isEditECSuccess: false,
    };
  }

  componentWillMount = () => {
    this.getAllWorldCountries();
    this.setState({ isLoading: true, notification: {} });
    this.props
      .userGetSingleProfile({ _id: this.props.auth.user.id })
      .then((res) => {
        if (res.status === 200) {
          const data = {
            ...res.message.data,
            current_bank_number: res.message.data.bank_number,
            new_bank_number: res.message.data.bank_number,
            current_password: '',
            new_password: '',
            confirm_password: '',
            date_of_birth: res.message.data.date_of_birth
              ? moment(res.message.data.date_of_birth, 'MM/DD/YYYY').format(
                  'DD-MM-YYYY',
                )
              : '',
          };

          if (
            res.message.data.living_in_country &&
            !isEmpty(res.message.data.living_in_country)
          ) {
            this.props
              .userGetCountryDetails({
                country_code: res.message.data.living_in_country.value,
              })
              .then((res) => {
                if (res.status === 200) {
                  this.setState({
                    city_options: res.message.data[0].city_options,
                  });
                } else {
                  console.log('Failed to collect the city options.');
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }

          if (
            res.message.data.country_ec &&
            !isEmpty(res.message.data.country_ec)
          ) {
            this.props
              .userGetCountryDetails({
                country_code: res.message.data.country_ec.value,
              })
              .then((res) => {
                if (res.status === 200) {
                  this.setState({
                    city_ec_options: res.message.data[0].city_options,
                  });
                } else {
                  console.log('Failed to collect the city options.');
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }

          this.setState({
            isLoading: false,
            showAccountDetails: true,
            data,
          });
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: 'Failed to load your profile data. Please try again later.',
              isError: true,
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          notification: {
            text: 'Failed to load your profile data. Please try again later.',
            isError: true,
          },
        });
      });
  };

  getAllWorldCountries = () => {
    this.props
      .userGetAllWorldCountries()
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            country_options: res.message.data,
          });
          return res.message.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
    window.scrollTo(0, 0);
  };

  onChangeBankNumber = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        new_bank_number: e.target.value,
      },
    });
  };

  onSaveBankNumber = () => {
    this.setState({
      isLoading_bank_details: false,
      notification_bank_details: {},
      errors_bank_details: {},
    });

    const bank_number = this.state.data.new_bank_number;
    const dataToSubmit = {
      _id: this.props.auth.user.id,
      type: this.props.auth.user.type,
      data: { bank_number },
    };

    this.props.userUpdateProfileInfo(dataToSubmit).then((res) => {
      if (res.status === 200) {
        this.setState({
          isLoading_bank_details: false,
          data: {
            ...res.message.data,
            new_bank_number: res.message.data.bank_number,
            current_bank_number: res.message.data.bank_number,
          },
          bankNumberEdit: false,
        });
      } else {
        this.setState({
          isLoading_bank_details: false,
          notification_bank_details: {
            text: 'We are experiencing technical difficulties at the moment, please try again later',
            isError: true,
          },
          data: {
            ...this.state.data,
            new_bank_number: this.state.data.current_bank_number,
            current_bank_number: this.state.data.current_bank_number,
          },
        });
      }
    });
  };

  onCancelBankNumber = () => {
    const bank_number = this.state.data.current_bank_number;
    this.setState({
      data: {
        ...this.state.data,
        current_bank_number: bank_number,
        new_bank_number: bank_number,
      },
      bankNumberEdit: false,
    });
  };

  onChangeLoginDetails = (e) => {
    this.setState({
      errors_login_details: {
        ...this.state.errors_login_details,
        [e.target.name]: null,
      },
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeAddressDetails = (e) => {
    this.setState({
      errors_address_details: {
        ...this.state.errors_address_details,
        [e.target.name]: null,
      },
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeContactDetails = (e) => {
    this.setState({
      errors_ec_details: {
        ...this.state.errors_ec_details,
        [e.target.name]: null,
      },
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  isValid = (form_type) => {
    if (form_type === 'login_details') {
      const { errors, isValid } = validateInputLoginDetails(this.state.data);
      if (!isValid) {
        this.setState({ errors_login_details: errors });
      }
      return {
        value: isValid,
        form_type,
      };
    }

    if (form_type === 'address_details') {
      const { errors, isValid } = validateAddressDetails(this.state.data);
      if (!isValid) {
        this.setState({ errors_address_details: errors });
      }
      return {
        value: isValid,
        form_type,
      };
    }
    if (form_type === 'ec_details') {
      const { errors, isValid } = validateEmergencyContact(this.state.data);
      if (!isValid) {
        this.setState({ errors_ec_details: errors });
      }
      return {
        value: isValid,
        form_type,
      };
    }
  };

  onSubmitLoginDetails = (e) => {
    e.preventDefault();
    const { data } = this.state;

    this.setState({
      isLoading_login_details: true,
      notification_login_details: {},
      errors_login_details: {},
    });
    const data_changed = {
      current_password: data.current_password,
      new_password: data.new_password,
      confirm_password: data.confirm_password,
    };

    const dataToSubmit = {
      _id: this.props.auth.user.id,
      type: this.props.auth.user.type,
      data: data_changed,
    };

    if (this.isValid('login_details').value) {
      this.props
        .userChangePassword(dataToSubmit)
        .then((res) => {
          if (res.status === 200) {
            const data = {
              ...res.message.data,
              current_bank_number: res.message.data.bank_number,
              new_bank_number: res.message.data.bank_number,
              current_password: '',
              new_password: '',
              confirm_password: '',
            };
            this.setState({
              isEditLoginSuccess: true,
              data,
            });
            setTimeout(() => {
              this.setState({
                isLoading_login_details: false,
                isEditLoginSuccess: false,
              });
            }, 3000);
          } else if (res.status === 401) {
            this.setState({
              isLoading_login_details: false,
              errors_login_details: {
                current_password: 'Your old password is does not match',
              },
              notification_login_details: {
                text: 'Failed to update your password. Please insert your old password correctly',
                isError: true,
              },
              data,
            });
          } else {
            this.setState({
              isLoading_login_details: false,
              notification_login_details: {
                text: 'Internal server error. Please try again later',
                isError: true,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading_login_details: false,
            notification_login_details: {
              text: 'We are experiencing technical difficulties at the moment, please try again later',
              isError: true,
            },
          });
        });
    } else {
      this.setState({
        isLoading_login_details: false,
        notification_login_details: {
          text: 'Please complete the form',
          isError: true,
        },
      });
    }
  };

  onSubmit = (e, form_type) => {
    e.preventDefault();
    let data_changed;
    const { data } = this.state;

    if (form_type === 'address_details') {
      data_changed = {
        living_in_country: data.living_in_country,
        living_in_city: data.living_in_city,
        postcode: data.postcode,
        street_name: data.street_name,
        house_number: data.house_number,
        phone: data.phone,
      };
      this.setState({
        isLoading_address_details: true,
        notification_address_details: {},
        errors_address_details: {},
      });
    }
    if (form_type === 'ec_details') {
      data_changed = {
        full_name_ec: data.full_name_ec,
        email_ec: data.email_ec,
        country_ec: data.country_ec,
        city_ec: data.city_ec,
        street_name_ec: data.street_name_ec,
        house_number_ec: data.house_number_ec,
        postcode_ec: data.postcode_ec,
        phone_ec: data.phone_ec,
      };
      this.setState({
        isLoading_ec_details: true,
        notification_ec_details: {},
        errors_ec_details: {},
      });
    }

    if (this.isValid(form_type).value) {
      const dataToSubmit = {
        _id: this.props.auth.user.id,
        type: this.props.auth.user.type,
        data: data_changed,
        form_type,
      };

      // Redux action
      this.props
        .userUpdateProfileInfo(dataToSubmit)
        .then((res) => {
          const data = {
            ...res.message.data,
            current_bank_number: res.message.data.bank_number,
            new_bank_number: res.message.data.bank_number,
            current_password: '',
            new_password: '',
            confirm_password: '',
          };
          if (form_type === 'address_details') {
            this.setState({
              isEditAddressSuccess: true,
              data,
            });
            setTimeout(() => {
              this.setState({
                isLoading_address_details: false,
                isEditAddressSuccess: false,
              });
            }, 3000);
          }
          if (form_type === 'ec_details') {
            this.setState({
              isEditECSuccess: true,
              data,
            });
            setTimeout(() => {
              this.setState({
                isLoading_ec_details: false,
                isEditECSuccess: false,
              });
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          if (form_type === 'address_details') {
            this.setState({
              isLoading_address_details: false,
              notification_address_details: {
                text: 'We are experiencing technical difficulties at the moment, please try again later',
                isError: true,
              },
            });
          }
          if (form_type === 'ec_details') {
            this.setState({
              isLoading_ec_details: false,
              notification_ec_details: {
                text: 'We are experiencing technical difficulties at the moment, please try again later',
                isError: true,
              },
            });
          }
        });
    } else {
      if (form_type === 'address_details') {
        this.setState({
          isLoading_address_details: false,
          notification_address_details: {
            text: 'Please complete the form',
            isError: true,
          },
        });
      }
      if (form_type === 'ec_details') {
        this.setState({
          isLoading_ec_details: false,
          notification_ec_details: {
            text: 'Please complete the form',
            isError: true,
          },
        });
      }
    }
  };

  loadOptionsCountry = (inputValue, callback) => {
    this.props
      .userGetAllCountries({ query: inputValue })
      .then((response) => {
        callback(response.message.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  onChangeSelectCountry = (val, form_type) => {
    const { data, errors_address_details, errors_ec_details } = this.state;
    let selected_country, city_options;

    if (form_type === 'address_details') {
      selected_country = {
        value: val.value,
        label: val.label,
      };

      city_options = val.city_options;

      this.setState({
        errors_address_details: {
          ...errors_address_details,
          living_in_country: null,
        },
        data: {
          ...data,
          living_in_country: selected_country,
          living_in_city: null,
        },
        city_options,
      });
    } else {
      selected_country = {
        value: val.value,
        label: val.label,
      };

      city_options = val.city_options;

      this.setState({
        errors_ec_details: {
          ...errors_ec_details,
          country_ec: null,
        },
        data: {
          ...data,
          country_ec: selected_country,
          city_ec: null,
        },
        city_ec_options: city_options,
      });
    }
  };

  onChangeSelect = (e) => {
    const data = {
      ...this.state.data,
      [e.target.name]: e.target.value,
    };

    this.setState({
      data,
      errors_address_details: {
        ...this.state.errors_address_details,
        [e.target.name]: null,
      },
      errors_ec_details: {
        ...this.state.errors_ec_details,
        [e.target.name]: null,
      },
    });
  };

  render() {
    const {
      data,
      isMobileScreen,
      isLoading,
      // bankNumberEdit,
      // isLoading_bank_details,
      isLoading_login_details,
      isLoading_address_details,
      isLoading_ec_details,
      // notification_bank_details,
      notification_login_details,
      notification_address_details,
      notification_ec_details,
      // errors_bank_details,
      errors_login_details,
      errors_address_details,
      errors_ec_details,
      isEditLoginSuccess,
      isEditAddressSuccess,
      isEditECSuccess,
      country_options,
      city_options,
      city_ec_options,
    } = this.state;

    return (
      <div id="account-details" className="col-md-8">
        <div className="card">
          <div className="card-body py-20 px-40">
            <h5 className="fw-500 mb-0 font-italic fs-14">
              <i className="ion-android-alert fs-15 mr-2"></i>
              All of the information below is not visible to student and only
              visible for admin.
            </h5>
          </div>
        </div>
        {/* BANK DETAIL */}
        {/* <div className='card'>
          <div className={isMobileScreen ? "card-header justify-content-start align-items-center p-20" : "card-header justify-content-start align-items-center px-40 py-20"}>
            <img src='/assets/img/icon/bank.svg' alt=''/>
            <h3 className="fw-400 m-0 fs-20">Bank Details</h3>
          </div>
          <div className={isMobileScreen ? "card-body justify-content-start align-items-center p-20 mb-20" : "card-body justify-content-start align-items-center px-40 py-20 mb-20"}>
            <Notification notification={notification_bank_details} />
            <label className="fs-14">IBAN number</label>
              <div
                className={isMobileScreen ? 'd-block' : 'd-flex justify-content-between align-items-center' }
                style={{ flexDirection: isMobileScreen ? 'column' : 'row'}}
              >
                <EditableTextInput
                  field='new_bank_number'
                  empty_value_message='No bank number'
                  value={data.new_bank_number}
                  placeholder='8029123212312123'
                  onChange={this.onChangeBankNumber}
                  disabled={isLoading_bank_details}
                  isEditing={bankNumberEdit}
                  error={errors_bank_details.new_bank_number}
                />
                { !bankNumberEdit ?
                  <img className='mt-2' onClick={() => this.setState({ bankNumberEdit: true })} src="/assets/img/icon/edit.svg" alt='' />
                : null }
                { bankNumberEdit ?
                  <div className={isMobileScreen ? 'd-flex mt-2' : 'd-flex'}>
                    <button onClick={this.onCancelBankNumber} className={isMobileScreen ? 'btn btn-outline-main-pink mr-2' : 'btn btn-outline-main-pink mx-2'}>Cancel</button>
                    <button onClick={this.onSaveBankNumber} className='btn btn-main-pink'>Save</button>
                  </div>
                : null }
              </div>
          </div>
        </div> */}

        {/* LOGIN DETAIL */}
        {isLoading ? (
          <div
            style={{ height: '185px' }}
            className="card bg-white d-flex justify-content-center align-items-center">
            <MDSpinner />
          </div>
        ) : (
          <div className="card">
            <div
              className={
                isMobileScreen
                  ? 'card-body justify-content-start align-items-center p-20 mb-20'
                  : 'card-body justify-content-start align-items-center px-40 py-20 mb-20'
              }>
              <h3 className="fs-20 fw-600 my-20">Login Details</h3>
              <TextFieldGroupLogin
                label="Email"
                field="email"
                value={data.email}
                placeholder="Type something"
                onChange={this.onChangeLoginDetails}
                disabled={true}
                error={errors_login_details.email}
              />
              <label className="col-form-label font-weight-bold letter-spacing-0 fs-15">
                Password
              </label>
              <TextFieldGroupLogin
                label="Current password"
                field="current_password"
                nonBoldLabel={true}
                type="password"
                value={data.current_password}
                placeholder="Your current password"
                onChange={this.onChangeLoginDetails}
                disabled={isLoading_login_details}
                error={errors_login_details.current_password}
              />
              <TextFieldGroupLogin
                label="New password"
                field="new_password"
                nonBoldLabel={true}
                type="password"
                value={data.new_password}
                placeholder="Type new password"
                onChange={this.onChangeLoginDetails}
                disabled={isLoading_login_details}
                error={errors_login_details.new_password}
              />
              <TextFieldGroupLogin
                label="Confirm password"
                field="confirm_password"
                nonBoldLabel={true}
                type="password"
                value={data.confirm_password}
                placeholder="Type your new password again"
                onChange={this.onChangeLoginDetails}
                disabled={isLoading_login_details}
                error={errors_login_details.confirm_password}
              />
              <Notification notification={notification_login_details} />
              <div
                className={
                  isMobileScreen
                    ? 'd-flex align-items-center flex-column mt-40 text-center bg-white'
                    : 'd-flex align-items-center mt-40 justify-content-between bg-white'
                }>
                <label
                  className="fs-13 mb-0 text-success d-flex align-items-center justify-content-center mt-0"
                  style={{
                    visibility: isEditLoginSuccess ? 'visible' : 'hidden',
                  }}>
                  <span>Changes saved successfully</span>
                  <i className="fa fa-check-circle-o fs-20 mx-2"></i>
                </label>
                <button
                  onClick={this.onSubmitLoginDetails}
                  className="btn btn-main-pink btn-w-md letter-spacing-0">
                  Save Change
                </button>
              </div>
            </div>
          </div>
        )}

        {/* ADDRESS DETAIL */}
        {isLoading ? null : (
          <div className="card">
            <div
              className={
                isMobileScreen
                  ? 'card-body justify-content-start align-items-center p-20 mb-20'
                  : 'card-body justify-content-start align-items-center px-40 py-20 mb-20'
              }>
              <h3 className="fs-20 fw-600 my-20">Address</h3>
              <div className="row m-auto">
                <div
                  className={
                    isMobileScreen ? 'col-md-6 p-0' : 'col-md-6 pl-0 pr-30'
                  }>
                  <SelectFieldGroup
                    label="Country"
                    value={data.living_in_country}
                    options={country_options}
                    onChange={(val) =>
                      this.onChangeSelectCountry(val, 'address_details')
                    }
                    error={errors_address_details.living_in_country}
                    isClearable={false}
                    isDisabled={isLoading_address_details}
                    isSearchable={true}
                  />
                </div>
                <div className="col-md-6 p-0">
                  <SelectFieldGroup
                    label="City"
                    value={data.living_in_city}
                    options={city_options}
                    onChange={(val) =>
                      this.onChangeSelect({
                        target: { name: 'living_in_city', value: val },
                      })
                    }
                    error={errors_address_details.living_in_city}
                    isClearable={false}
                    isDisabled={
                      isLoading_address_details || isEmpty(data.country)
                    }
                    isSearchable={true}
                  />
                </div>
                <div
                  className={
                    isMobileScreen ? 'col-md-6 p-0' : 'col-md-6 pl-0 pr-30'
                  }>
                  <TextFieldGroupLogin
                    label="Street Name"
                    field="street_name"
                    value={data.street_name}
                    placeholder="Type something"
                    onChange={this.onChangeAddressDetails}
                    disabled={isLoading_address_details}
                    error={errors_address_details.street_name}
                  />
                </div>
                <div className="col-md-6 p-0">
                  <TextFieldGroupLogin
                    label="House Number"
                    field="house_number"
                    value={data.house_number}
                    placeholder="Type something"
                    onChange={this.onChangeAddressDetails}
                    disabled={isLoading_address_details}
                    error={errors_address_details.house_number}
                  />
                </div>
                <div
                  className={
                    isMobileScreen ? 'col-md-6 p-0' : 'col-md-6 pl-0 pr-30'
                  }>
                  <TextFieldGroupLogin
                    label="Postal code"
                    field="postcode"
                    value={data.postcode}
                    placeholder="Type something"
                    onChange={this.onChangeAddressDetails}
                    disabled={isLoading_address_details}
                    error={errors_address_details.postcode}
                  />
                </div>
                <div className="col-md-6 p-0">
                  <TextFieldGroupLogin
                    label="Phone Number"
                    field="phone"
                    value={data.phone}
                    placeholder="Type something"
                    onChange={this.onChangeAddressDetails}
                    disabled={isLoading_address_details}
                    error={errors_address_details.phone}
                  />
                </div>
              </div>
              <Notification notification={notification_address_details} />
              <div
                className={
                  isMobileScreen
                    ? 'd-flex align-items-center flex-column mt-40 text-center bg-white'
                    : 'd-flex align-items-center mt-40 justify-content-between bg-white'
                }>
                <label
                  className="fs-13 mb-0 text-success d-flex align-items-center justify-content-center mt-0"
                  style={{
                    visibility: isEditAddressSuccess ? 'visible' : 'hidden',
                  }}>
                  <span>Changes saved successfully</span>
                  <i className="fa fa-check-circle-o fs-20 mx-2"></i>
                </label>
                {isLoading_address_details ? (
                  <MDSpinner />
                ) : (
                  <button
                    onClick={(e) => this.onSubmit(e, 'address_details')}
                    className="btn btn-main-pink btn-w-md letter-spacing-0">
                    Save Change
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {/* EMERGENCY CONTACT DETAIL */}
        {isLoading ? null : (
          <div className="card">
            <div
              className={
                isMobileScreen
                  ? 'card-body justify-content-start align-items-center p-20 mb-20'
                  : 'card-body justify-content-start align-items-center px-40 py-20 mb-20'
              }>
              <h3 className="fs-20 fw-600 my-20">Emergency Contact</h3>
              <div className="row m-auto">
                <div
                  className={
                    isMobileScreen ? 'col-md-6 p-0' : 'col-md-6 pl-0 pr-30'
                  }>
                  <TextFieldGroupLogin
                    label="Full name"
                    field="full_name_ec"
                    value={data.full_name_ec}
                    placeholder="Type something"
                    onChange={this.onChangeContactDetails}
                    disabled={isLoading_ec_details}
                    error={errors_ec_details.full_name_ec}
                  />
                </div>
                <div className="col-md-6 p-0">
                  <TextFieldGroupLogin
                    label="Email"
                    field="email_ec"
                    value={data.email_ec}
                    placeholder="Type something"
                    onChange={this.onChangeContactDetails}
                    disabled={isLoading_ec_details}
                    error={errors_ec_details.email_ec}
                  />
                </div>
                <div
                  className={
                    isMobileScreen ? 'col-md-6 p-0' : 'col-md-6 pl-0 pr-30'
                  }>
                  {/* <AsyncSelectFieldGroup
                    placeholder='Type to search'
                    label="Country"
                    value={data.country_ec}
                    loadOptions={this.loadOptionsCountry}
                    onChange={(val) => this.onChangeSelectCountry(val, 'ec_details')}
                    error={errors_ec_details.country_ec}
                    isDisabled={isLoading_ec_details}
                    isClearable={false}
                  /> */}
                  <SelectFieldGroup
                    label="Country"
                    value={data.country_ec}
                    options={country_options}
                    onChange={(val) =>
                      this.onChangeSelectCountry(val, 'ec_details')
                    }
                    error={errors_ec_details.country_ec}
                    isClearable={false}
                    isDisabled={isLoading_ec_details}
                    isSearchable={true}
                  />
                </div>
                <div className="col-md-6 p-0">
                  <SelectFieldGroup
                    label="City"
                    value={data.city_ec}
                    options={city_ec_options}
                    onChange={(val) =>
                      this.onChangeSelect({
                        target: { name: 'city_ec', value: val },
                      })
                    }
                    error={errors_ec_details.city_ec}
                    isClearable={false}
                    isDisabled={
                      isLoading_ec_details || isEmpty(data.country_ec)
                    }
                    isSearchable={true}
                  />
                </div>
                <div
                  className={
                    isMobileScreen ? 'col-md-6 p-0' : 'col-md-6 pl-0 pr-30'
                  }>
                  <TextFieldGroupLogin
                    label="Street Name"
                    field="street_name_ec"
                    value={data.street_name_ec}
                    placeholder="Type something"
                    onChange={this.onChangeContactDetails}
                    disabled={isLoading_ec_details}
                    error={errors_ec_details.street_name_ec}
                  />
                </div>
                <div className="col-md-6 p-0">
                  <TextFieldGroupLogin
                    label="House Number"
                    field="house_number_ec"
                    value={data.house_number_ec}
                    placeholder="Type something"
                    onChange={this.onChangeContactDetails}
                    disabled={isLoading_ec_details}
                    error={errors_ec_details.house_number_ec}
                  />
                </div>
                <div
                  className={
                    isMobileScreen ? 'col-md-6 p-0' : 'col-md-6 pl-0 pr-30'
                  }>
                  <TextFieldGroupLogin
                    label="Postal code"
                    field="postcode_ec"
                    value={data.postcode_ec}
                    placeholder="Type something"
                    onChange={this.onChangeContactDetails}
                    disabled={isLoading_ec_details}
                    error={errors_ec_details.postcode_ec}
                  />
                </div>
                <div className="col-md-6 p-0">
                  <TextFieldGroupLogin
                    label="Phone Number"
                    field="phone_ec"
                    value={data.phone_ec}
                    placeholder="Type something"
                    onChange={this.onChangeContactDetails}
                    disabled={isLoading_ec_details}
                    error={errors_ec_details.phone_ec}
                  />
                </div>
              </div>
              <Notification notification={notification_ec_details} />
              <div
                className={
                  isMobileScreen
                    ? 'd-flex align-items-center flex-column mt-40 text-center bg-white'
                    : 'd-flex align-items-center mt-40 justify-content-between bg-white'
                }>
                <label
                  className="fs-13 mb-0 text-success d-flex align-items-center justify-content-center mt-0"
                  style={{
                    visibility: isEditECSuccess ? 'visible' : 'hidden',
                  }}>
                  <span>Changes saved successfully</span>
                  <i className="fa fa-check-circle-o fs-20 mx-2"></i>
                </label>
                {isLoading_ec_details ? (
                  <MDSpinner />
                ) : (
                  <button
                    onClick={(e) => this.onSubmit(e, 'ec_details')}
                    className="btn btn-main-pink letter-spacing-0">
                    Save changes
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  userGetSingleProfile,
  userChangePassword,
  validateAddressDetails,
  validateEmergencyContact,
  userUpdateProfileInfo,
  userGetAllCountries,
  userGetAllWorldCountries,
  userGetCountryDetails,
})(TutorAccountDetails);
