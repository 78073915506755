export const timezone_options = [
  { value: 'Europe/Belfast (GMT)', label: 'Europe/Belfast (GMT)' },
  { value: 'Europe/Dublin (GMT)', label: 'Europe/Dublin (GMT)' },
  { value: 'Europe/Lisbon (GMT)', label: 'Europe/Lisbon (GMT)' },
  { value: 'Europe/London (GMT)', label: 'Europe/London (GMT)' },
  {
    value: 'Europe/Amsterdam (GMT+01:00)',
    label: 'Europe/Amsterdam (GMT+01:00)',
  },
  {
    value: 'Europe/Belgrade (GMT+01:00)',
    label: 'Europe/Belgrade (GMT+01:00)',
  },
  {
    value: 'Europe/Brussels (GMT+01:00)',
    label: 'Europe/Brussels (GMT+01:00)',
  },
  { value: 'Europe/Minsk (GMT+03:00)', label: 'Europe/Minsk (GMT+03:00)' },
  { value: 'Europe/Moscow (GMT+04:00)', label: 'Europe/Moscow (GMT+04:00)' },
  { value: 'Etc(GMT-12:00)', label: 'Etc(GMT-12:00)' },
  { value: 'Etc/(GMT-11:00)', label: 'Etc/(GMT-11:00)' },
  { value: 'Pacific/Midway (GMT-11:00)', label: 'Pacific/Midway (GMT-11:00)' },
  { value: 'America/Adak (GMT-10:00)', label: 'America/Adak (GMT-10:00)' },
  {
    value: 'America/Anchorage (GMT-09:00)',
    label: 'America/Anchorage (GMT-09:00)',
  },
  {
    value: 'Pacific/Gambier (GMT-09:00)',
    label: 'Pacific/Gambier (GMT-09:00)',
  },
  {
    value: 'America/Dawson_Creek (GMT-08:00)',
    label: 'America/Dawson_Creek (GMT-08:00)',
  },
  {
    value: 'America/Ensenada (GMT-08:00)',
    label: 'America/Ensenada (GMT-08:00)',
  },
  {
    value: 'America/Los_Angeles (GMT-08:00)',
    label: 'America/Los_Angeles (GMT-08:00)',
  },
  {
    value: 'America/Chihuahua (GMT-07:00)',
    label: 'America/Chihuahua (GMT-07:00)',
  },
  { value: 'America/Denver (GMT-07:00)', label: 'America/Denver (GMT-07:00)' },
  { value: 'America/Belize (GMT-06:00)', label: 'America/Belize (GMT-06:00)' },
  { value: 'America/Cancun (GMT-06:00)', label: 'America/Cancun (GMT-06:00)' },
  {
    value: 'America/Chicago (GMT-06:00)',
    label: 'America/Chicago (GMT-06:00)',
  },
  {
    value: 'Chile/EasterIsland (GMT-06:00)',
    label: 'Chile/EasterIsland (GMT-06:00)',
  },
  { value: 'America/Bogota (GMT-05:00)', label: 'America/Bogota (GMT-05:00)' },
  { value: 'America/Havana (GMT-05:00)', label: 'America/Havana (GMT-05:00)' },
  {
    value: 'America/New_York (GMT-05:00)',
    label: 'America/New_York (GMT-05:00)',
  },
  {
    value: 'America/Caracas (GMT-04:30)',
    label: 'America/Caracas (GMT-04:30)',
  },
  {
    value: 'America/Campo_Grande (GMT-04:00)',
    label: 'America/Campo_Grande (GMT-04:00)',
  },
  {
    value: 'America/Glace_Bay (GMT-04:00)',
    label: 'America/Glace_Bay (GMT-04:00)',
  },
  {
    value: 'America/Goose_Bay (GMT-04:00)',
    label: 'America/Goose_Bay (GMT-04:00)',
  },
  {
    value: 'America/Santiago (GMT-04:00)',
    label: 'America/Santiago (GMT-04:00)',
  },
  { value: 'America/La_Paz (GMT-04:00)', label: 'America/La_Paz (GMT-04:00)' },
  {
    value: 'America/Argentina/Buenos_Aires (GMT-03:00)',
    label: 'America/Argentina/Buenos_Aires (GMT-03:00)',
  },
  {
    value: 'America/Montevideo (GMT-03:00)',
    label: 'America/Montevideo (GMT-03:00)',
  },
  {
    value: 'America/Araguaina (GMT-03:00)',
    label: 'America/Araguaina (GMT-03:00)',
  },
  {
    value: 'America/Godthab (GMT-03:00)',
    label: 'America/Godthab (GMT-03:00)',
  },
  {
    value: 'America/Miquelon (GMT-03:00)',
    label: 'America/Miquelon (GMT-03:00)',
  },
  {
    value: 'America/Sao_Paulo (GMT-03:00)',
    label: 'America/Sao_Paulo (GMT-03:00)',
  },
  {
    value: 'America/St_Johns (GMT-03:30)',
    label: 'America/St_Johns (GMT-03:30)',
  },
  {
    value: 'America/Noronha (GMT-02:00)',
    label: 'America/Noronha (GMT-02:00)',
  },
  {
    value: 'Atlantic/Cape_Verde (GMT-01:00)',
    label: 'Atlantic/Cape_Verde (GMT-01:00)',
  },
  { value: 'Africa/Abidjan (GMT)', label: 'Africa/Abidjan (GMT)' },
  { value: 'UTC (UTC)', label: 'UTC (UTC)' },
  { value: 'Africa/Algiers (GMT+01:00)', label: 'Africa/Algiers (GMT+01:00)' },
  {
    value: 'Africa/Windhoek (GMT+01:00)',
    label: 'Africa/Windhoek (GMT+01:00)',
  },
  {
    value: 'Atlantic/Azores (GMT+01:00)',
    label: 'Atlantic/Azores (GMT+01:00)',
  },
  {
    value: 'Atlantic/Stanley (GMT+01:00)',
    label: 'Atlantic/Stanley (GMT+01:00)',
  },
  { value: 'Africa/Cairo (GMT+02:00)', label: 'Africa/Cairo (GMT+02:00)' },
  {
    value: 'Africa/Blantyre (GMT+02:00)',
    label: 'Africa/Blantyre (GMT+02:00)',
  },
  { value: 'Asia/Beirut (GMT+02:00)', label: 'Asia/Beirut (GMT+02:00)' },
  { value: 'Asia/Damascus (GMT+02:00)', label: 'Asia/Damascus (GMT+02:00)' },
  { value: 'Asia/Gaza (GMT+02:00)', label: 'Asia/Gaza (GMT+02:00)' },
  { value: 'Asia/Jerusalem (GMT+02:00)', label: 'Asia/Jerusalem (GMT+02:00)' },
  {
    value: 'Africa/Addis_Ababa (GMT+03:00)',
    label: 'Africa/Addis_Ababa (GMT+03:00)',
  },
  { value: 'Asia/Riyadh89 (GMT+03:00)', label: 'Asia/Riyadh89 (GMT+03:00)' },
  { value: 'Asia/Tehran (GMT+03:30)', label: 'Asia/Tehran (GMT+03:30)' },
  { value: 'Asia/Dubai (GMT+04:00)', label: 'Asia/Dubai (GMT+04:00)' },
  { value: 'Asia/Yerevan (GMT+04:00)', label: 'Asia/Yerevan (GMT+04:00)' },
  { value: 'Asia/Kabul (GMT+04:30)', label: 'Asia/Kabul (GMT+04:30)' },
  { value: 'Asia/Tashkent (GMT+05:00)', label: 'Asia/Tashkent (GMT+05:00)' },
  { value: 'Asia/Kolkata (GMT+05:30)', label: 'Asia/Kolkata (GMT+05:30)' },
  { value: 'Asia/Katmandu (GMT+05:45)', label: 'Asia/Katmandu (GMT+05:45)' },
  { value: 'Asia/Dhaka (GMT+06:00)', label: 'Asia/Dhaka (GMT+06:00)' },
  {
    value: 'Asia/Yekaterinburg (GMT+06:00)',
    label: 'Asia/Yekaterinburg (GMT+06:00)',
  },
  { value: 'Asia/Rangoon (GMT+06:30)', label: 'Asia/Rangoon (GMT+06:30)' },
  { value: 'Asia/Bangkok (GMT+07:00)', label: 'Asia/Bangkok (GMT+07:00)' },
  {
    value: 'Asia/Novosibirsk (GMT+07:00)',
    label: 'Asia/Novosibirsk (GMT+07:00)',
  },
  { value: 'Etc/GMT+8 (GMT+08:00)', label: 'Etc/GMT+8 (GMT+08:00)' },
  { value: 'Asia/Hong_Kong (GMT+08:00)', label: 'Asia/Hong_Kong (GMT+08:00)' },
  {
    value: 'Asia/Krasnoyarsk (GMT+08:00)',
    label: 'Asia/Krasnoyarsk (GMT+08:00)',
  },
  {
    value: 'Australia/Perth (GMT+08:00)',
    label: 'Australia/Perth (GMT+08:00)',
  },
  {
    value: 'Australia/Eucla (GMT+08:45)',
    label: 'Australia/Eucla (GMT+08:45)',
  },
  { value: 'Asia/Irkutsk (GMT+09:00)', label: 'Asia/Irkutsk (GMT+09:00)' },
  { value: 'Asia/Seoul (GMT+09:00)', label: 'Asia/Seoul (GMT+09:00)' },
  { value: 'Asia/Tokyo (GMT+09:00)', label: 'Asia/Tokyo (GMT+09:00)' },
  {
    value: 'Australia/Adelaide (GMT+09:30)',
    label: 'Australia/Adelaide (GMT+09:30)',
  },
  {
    value: 'Australia/Darwin (GMT+09:30)',
    label: 'Australia/Darwin (GMT+09:30)',
  },
  {
    value: 'Pacific/Marquesas (GMT+09:30)',
    label: 'Pacific/Marquesas (GMT+09:30)',
  },
  { value: 'Etc/GMT+10 (GMT+10:00)', label: 'Etc/GMT+10 (GMT+10:00)' },
  {
    value: 'Australia/Brisbane (GMT+10:00)',
    label: 'Australia/Brisbane (GMT+10:00)',
  },
  {
    value: 'Australia/Hobart (GMT+10:00)',
    label: 'Australia/Hobart (GMT+10:00)',
  },
  { value: 'Asia/Yakutsk (GMT+10:00)', label: 'Asia/Yakutsk (GMT+10:00)' },
  {
    value: 'Australia/Lord_Howe (GMT+10:30)',
    label: 'Australia/Lord_Howe (GMT+10:30)',
  },
  {
    value: 'Asia/Vladivostok (GMT+11:00)',
    label: 'Asia/Vladivostok (GMT+11:00)',
  },
  {
    value: 'Pacific/Norfolk (GMT+11:30)',
    label: 'Pacific/Norfolk (GMT+11:30)',
  },
  { value: 'Etc/GMT+12 (GMT+12:00)', label: 'Etc/GMT+12 (GMT+12:00)' },
  { value: 'Asia/Anadyr (GMT+12:00)', label: 'Asia/Anadyr (GMT+12:00)' },
  { value: 'Asia/Magadan (GMT+12:00)', label: 'Asia/Magadan (GMT+12:00)' },
  {
    value: 'Pacific/Auckland (GMT+12:00)',
    label: 'Pacific/Auckland (GMT+12:00)',
  },
  {
    value: 'Pacific/Chatham (GMT+12:45)',
    label: 'Pacific/Chatham (GMT+12:45)',
  },
  {
    value: 'Pacific/Tongatapu (GMT+13:00)',
    label: 'Pacific/Tongatapu (GMT+13:00)',
  },
  {
    value: 'Pacific/Kiritimati (GMT+14:00)',
    label: 'Pacific/Kiritimati (GMT+14:00)',
  },
];

export const gender_options = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

export const session_type_options = [
  { value: 'skype', label: 'Online session' },
  { value: 'live', label: 'Live session' },
  { value: 'children', label: 'Kids session' },
];

export const language_options = [
  { value: 'english', label: 'English' },
  { value: 'dutch', label: 'Dutch' },
  { value: 'amharic', label: 'Amharic' },
  { value: 'arabic', label: 'Arabic' },
  { value: 'azerbaijani', label: 'Azerbaijani' },
  { value: 'chinese', label: 'Chinese' },
  { value: 'chinese_cantonese', label: 'Chinese Cantonese' },
  { value: 'croatian', label: 'Croatian' },
  { value: 'estonian', label: 'Estonian' },
  { value: 'farsi', label: 'Farsi' },
  { value: 'finnish', label: 'Finnish' },
  { value: 'french', label: 'French' },
  { value: 'frysk', label: 'Frysk' },
  { value: 'german', label: 'German' },
  { value: 'greek', label: 'Greek' },
  { value: 'hindi', label: 'Hindi' },
  { value: 'hungarian', label: 'Hungarian' },
  { value: 'italian', label: 'Italian' },
  { value: 'japanese', label: 'Japanese' },
  { value: 'maori', label: 'Maori' },
  { value: 'maori_conversational', label: 'Maori (conversational)' },
  { value: 'norwegian', label: 'Norwegian' },
  { value: 'papiamentu', label: 'Papiamentu' },
  { value: 'persian', label: 'Persian' },
  { value: 'portuguese', label: 'Portuguese' },
  { value: 'russian', label: 'Russian' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'sranan_tongo', label: 'Sranan Tongo' },
  { value: 'swedish', label: 'Swedish' },
  { value: 'turkish', label: 'Turkish' },
  { value: 'welsh', label: 'Welsh' },
];

export const language_level_options = [
  { value: 'beginner', label: 'Beginner' },
  { value: 'intermediate', label: 'Intermediate' },
  { value: 'advance', label: 'Advance' },
];

export const topics_options = [
  { value: 'welcome_session', label: 'Welcome' },
  { value: 'social_conversation', label: 'Social conversation' },
  { value: 'walk_and_talk', label: 'Walk & talk' },
  { value: 'shopping_trip', label: 'Shopping' },
  { value: 'expat_life', label: 'Expat life' },
  { value: 'art_culture', label: 'Art & culture' },
  { value: 'history_politics', label: 'History & politics' },
  { value: 'video_games', label: 'Video & games' },
  { value: 'business_it', label: 'Business & IT' },
  { value: 'snap_city', label: 'Snap the city' },
  { value: 'innovation_design', label: 'Innovation & design' },
  { value: 'culinary', label: 'Culinary' },
  { value: 'intercultural_awareness', label: 'Intercultural awareness' },
  { value: 'blog_writing', label: 'Blog-writing & story telling' },
  { value: 'body_and_mind', label: 'Body & mind' },
  { value: 'sports', label: 'Sports' },
  { value: 'grammar', label: 'Grammar' },
  { value: 'job_interview', label: 'Job interview' },
  { value: 'civic_state_exams', label: 'Civic & state exams' },
];

export const sort_by_options = [
  { value: 'rating', label: 'Rating' },
  { value: 'session', label: 'Number of sessions' },
];

export const session_time_options = [
  { value: 'morning', label: 'Morning 8-12' },
  { value: 'day', label: 'Afternoon 12-18' },
  { value: 'evening', label: 'Evening 18-22' },
];
