import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateInput(data) {
  let errors = {};
  let isValid = true;

  if (!isEmpty(data.email)) {
    if (!Validator.isEmail(data.email)) {
      errors.email = 'Email not valid';
      isValid = false;
    }
  } else {
    errors.email = 'Please input your email';
    isValid = false;
  }
  if (Validator.isEmpty(data.password)) {
    errors.password = 'Please input your password';
    isValid = false;
  }
  return {
    errors,
    isValid,
  };
}
