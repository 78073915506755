import { API } from '../../utils/api';

export function userGetPaymentMethodsList() {
  return (dispatch) => {
    return API.get('/payment/methods-list').then((res) => {
      return res.data;
    });
  };
}

export function userCreateNewPayment(data) {
  return (dispatch) => {
    return API.post('/payment/create', data).then((res) => {
      return res.data;
    });
  };
}

export function userCreateNewVoucherPayment(data) {
  return (dispatch) => {
    return API.post('/payment/create-voucher', data).then((res) => {
      return res.data;
    });
  };
}

export function userCheckPayment(data) {
  return (dispatch) => {
    return API.post('/payment/detail', data).then((res) => {
      return res.data;
    });
  };
}

export function userCheckVoucherPayment(data) {
  return (dispatch) => {
    return API.post('/payment/detail-voucher', data).then((res) => {
      return res.data;
    });
  };
}

export function userUpdatePurchaseGtmTrackedStatus(data) {
  return (dispatch) => {
    return API.post('/payment/update-gtm', data).then((res) => {
      return res.data;
    });
  };
}

export function userUpdatePurchaseVoucherGtmTrackedStatus(data) {
  return (dispatch) => {
    return API.post('/payment/update-gtm-voucher', data).then((res) => {
      return res.data;
    });
  };
}
