import { API } from '../../utils/api';

export function userGetAllLanguages(data) {
  return (dispatch) => {
    return API.get('/language/all', data).then((res) => {
      return res.data;
    });
  };
}

export function userGetAllCountries(data) {
  return (dispatch) => {
    return API.post('/common/country-selection', data).then((res) => {
      return res.data;
    });
  };
}

export function userGetAllWorldCountries() {
  return (dispatch) => {
    return API.get('/common/world-countries').then((res) => {
      return res.data;
    });
  };
}

export function userGetCountryDetails(data) {
  return (dispatch) => {
    return API.post('/common/country-details', data).then((res) => {
      return res.data;
    });
  };
}

export function userDownloadReceipt(data) {
  return (dispatch) => {
    return API.post('/common/receipt', data).then((res) => {
      return res.data;
    });
  };
}

export function userDownloadVoucherReceipt(data) {
  return (dispatch) => {
    return API.post('/common/receipt-voucher', data).then((res) => {
      return res.data;
    });
  };
}
