import React, { Component } from 'react';
import TagManager from 'react-gtm-module';

import { BOOKING_DOMAIN } from '../../../utils/api';

import { connect } from 'react-redux';
import { userUpdateBookingGtmTrackedStatus } from '../../../redux/actions/appointmentActions';
class BookingSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileScreen: this.props.isMobileScreen,
      bookingResData: this.props.bookingResData,
    };
  }

  initializeGTM = (appointment_data) => {
    const tagManagerArgs = {
      gtmId: 'GTM-NG4WRF7',
      dataLayer: {
        event: 'appointment',
      },
    };

    TagManager.initialize(tagManagerArgs);

    this.props
      .userUpdateBookingGtmTrackedStatus({
        booking_id: appointment_data.booking_id,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  componentDidMount = () => {
    const { bookingResData } = this.props;
    this.initializeGTM(bookingResData);
  };

  render() {
    const { isMobileScreen, bookingResData } = this.state;
    return (
      <div
        className={isMobileScreen ? 'card-body p-20' : 'card-body px-40 py-20'}>
        <div className="text-center mb-10">
          <img
            src="/assets/img/icon/success.svg"
            className="h-50px"
            alt="success"
          />
        </div>
        <h2 className="fs-25 mb-2 text-center letter-spacing-0">
          Thank you for your booking!
        </h2>
        <h2 className="fs-20 mb-20 text-center letter-spacing-0 text-secondary">
          Session ID : {bookingResData.booking_id}
        </h2>
        <hr
          className="mt-20 mb-40"
          style={{ borderStyle: 'dashed', borderTopWidth: 4 }}
        />
        <p className="text-center mb-30 fs-16">
          Your appointment has been created successfully.
          {isMobileScreen
            ? ' '
            : ' Your tutor will contact you directly by phone or email to discuss details of your session (exact time, place and topic). Have a nice and fun session!'}
        </p>
        <div className="text-center mb-20">
          <a
            href={`${BOOKING_DOMAIN}/profile/appointments`}
            rel="noopener noreferrer"
            className="btn btn-main-pink btn-w-xl text-white letter-spacing-0">
            See my appointment
          </a>
        </div>
      </div>
    );
  }
}

export default connect(null, { userUpdateBookingGtmTrackedStatus })(
  BookingSuccess,
);
