import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import StudentProfile from './views/student/studentProfile';
import TutorProfile from './views/tutor/tutorProfile';
import SideBarNav from './views/sideBarNav';
import { connect } from 'react-redux';
import TutorAppointment from './views/tutor/tutorAppointment';
import TutorAccountDetails from './views/tutor/tutorAccountDetails';
import StudentAppointment from './views/student/studentAppointment';
import StudentAccountDetails from './views/student/studentAccountDetails';
import studentAppointmentDetails from './views/student/studentAppointmentDetails';

function ProfilePage(props) {
  const { match } = props;
  const type = props.auth.user.type;

  return (
    <div className="main-content" style={{ paddingTop: '15px!important' }}>
      <div className="px-15 mb-50 profile-wrapper">
        <div className="row">
          <div className="col-md-4">
            <SideBarNav />
          </div>
          <Switch>
            <Route
              path={`${match.path}/appointments/detail`}
              component={studentAppointmentDetails}
            />
            <Route
              path={`${match.path}/appointments`}
              component={
                type === 'tutor' ? TutorAppointment : StudentAppointment
              }
            />
            <Route
              path={`${match.path}/account`}
              component={
                type === 'tutor' ? TutorAccountDetails : StudentAccountDetails
              }
            />
            <Route
              path={`${match.path}/edit`}
              component={type === 'tutor' ? TutorProfile : StudentProfile}
            />
            <Redirect to={`${match.path}/edit`} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(ProfilePage);
