import { API } from '../../utils/api';

export function userGetAllPackages() {
  return (dispatch) => {
    return API.get('/package/all').then((res) => {
      return res.data;
    });
  };
}

export function userGetTrialPackages() {
  return (dispatch) => {
    return API.get('/package/trial').then((res) => {
      return res.data;
    });
  };
}

export function userGetSinglePackage(data) {
  return (dispatch) => {
    return API.post('/package/single', data).then((res) => {
      return res.data;
    });
  };
}
