import React, { Component } from 'react';
import Rating from 'react-rating';
import { isEmpty } from 'lodash';
import moment from 'moment';

import LoadingGift from '../../common/LoadingGift';
import Notification from '../../common/Notification';

import { connect } from 'react-redux';
import { userCreateNewAppointment } from '../../../redux/actions/appointmentActions';
import { userGetSingleProfile } from '../../../redux/actions/tutorActions';
import { userGetSinglePackageDetail } from '../../../redux/actions/singleSessionActions';
import { userGetAllPackages } from '../../../redux/actions/packagesActions';
import BookingSuccess from './BookingSuccess';
import { BOOKING_DOMAIN } from '../../../utils/api';
import { withRouter } from 'react-router-dom';
import MDSpinner from 'react-md-spinner';
import PackageTabs from './PackageTabs';

class BookingOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			appointment_data: {},
			student_data: {},
			tutor_data: {},
			isLoading: false,
			notification: {},
			isMobileScreen: false,
			default_avatar: '/assets/img/icon/male.svg',
			bookingSuccess: false,
			bookingResData: {},
			selectedPackage: {},
			// MAIN PACKAGES
			mainPackages: [],
			isLoadingPackages: false,
			notificationPackages: {},
			// SINGLE SESSION PACKAGE
			singleSessionPackage: null,
			isLoadingSingleSession: false,
			notificationSingleSession: {},
		};
	}

	componentWillUnmount = () => {
		if (this.state.bookingSuccess) {
			this.props.history.push('/book-language-course');
		}
	};

	screenSize = () => {
		this.setState({ isMobileScreen: window.innerWidth <= 900 });
	};

	componentDidMount = () => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		window.addEventListener('screenSize', this.screenSize());
		const { student_data, appointment_data } = this.props.location.state;
		if (student_data.credit_balance < appointment_data.book_price || this.isCreditExpired(student_data)) {
			this.getSingleSessionPackage(appointment_data);
			this.getAllMainPackages();
		}
	};

	componentWillMount = () => {
		if (this.props.location.state) {
			const { appointment_data, student_data, tutor_data } = this.props.location.state;

			if (isEmpty(tutor_data)) {
				this.props.history.goBack();
			} else {
				const history_appointment = [];
				const today = moment(new Date()).format('MM/DD/YYYY');

				if (tutor_data.appointments.length > 0) {
					tutor_data.appointments.map((data) => {
						const appointmentDate = data.appointment_date;
						const history = moment(appointmentDate).isBefore(today);

						if (history || data.isCanceled) {
							history_appointment.push(data);
						}

						return data;
					});
				}
				this.setState({
					appointment_data,
					student_data,
					tutor_data: {
						...tutor_data,
						total_session: history_appointment.length,
					},
				});
				this.getTutorDetails(this.props.location.state.tutor_data._id);
			}
		} else {
			window.location.replace(`${BOOKING_DOMAIN}/book-language-course`);
		}
	};

	isCreditExpired = (student_data) => {
		const todayDate = new Date();
		let creditExpiryDate = '';
		let isCreditExpired = false;

		if (student_data.credit_expired && !isEmpty(student_data.credit_expired)) {
			creditExpiryDate = moment(student_data.credit_expired, 'MM/DD/YYYY');
			isCreditExpired = moment(todayDate).isAfter(creditExpiryDate);
		}

		return isCreditExpired;
	};

	getSingleSessionPackage = (appointment_data) => {
		this.setState({
			isLoadingSingleSession: true,
			notificationSingleSession: {},
		});
		const dataToSubmit = {
			book_price: appointment_data.book_price,
			person: appointment_data.person.value,
		};

		this.props
			.userGetSinglePackageDetail(dataToSubmit)
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						isLoadingSingleSession: false,
						singleSessionPackage: res.message.data,
						selectedPackage: res.message.data,
					});
				} else {
					this.setState({
						isLoadingSingleSession: false,
						notificationSingleSession: {
							isError: true,
							text: res.message,
						},
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isLoadingSingleSession: false,
					notificationSingleSession: {
						isError: true,
						text: 'Network connection error. Please check your connection and try again.',
					},
				});
			});
	};

	getAllMainPackages = () => {
		this.setState({ isLoadingPackages: true, notificationPackages: {} });

		this.props
			.userGetAllPackages()
			.then((res) => {
				if (res.status === 200) {
					this.setState({
						isLoadingPackages: false,
						mainPackages: res.message.data,
					});
				} else {
					this.setState({
						isLoadingPackages: false,
						notificationPackages: {
							isError: true,
							text: res.message,
						},
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isLoadingPackages: false,
					notificationPackages: {
						isError: true,
						text: 'Network connection error. Please check your connection and try again.',
					},
				});
			});
	};

	getTutorDetails = (tutor_id) => {
		this.props.userGetSingleProfile({ _id: tutor_id }).then((res) => {
			if (res.status === 200) {
				this.setState({
					tutor_data: {
						...this.state.tutor_data,
						bridge_language: res.message.data.bridge_language,
						teach_language: res.message.data.teach_language,
					},
				});
			} else {
				this.setState({
					notification: {
						text: 'Failed to load tutor overview. Please try again later.',
						isError: true,
					},
				});
			}
		});
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.setState({ isLoading: true, notification: {} });

		const { appointment_data, student_data, tutor_data } = this.state;

		const dataToSubmit = {
			student_id: student_data._id,
			tutor_id: tutor_data._id,
			person: appointment_data.person.value,
			appointment_date: appointment_data.appointment_date,
			book_price: appointment_data.book_price,
			session_type: {
				value: appointment_data.session_type.value,
				label: appointment_data.session_type.label,
			},
			session_duration: appointment_data.session_duration.value,
		};

		this.props
			.userCreateNewAppointment(dataToSubmit)
			.then((res) => {
				if (res.status !== 200) {
					this.setState({
						isLoading: false,
						notification: {
							text: res.message,
							isError: true,
						},
					});
				} else {
					this.setState({
						isLoading: false,
						bookingSuccess: true,
						bookingResData: res.message.data,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isLoading: false,
					notification: {
						text: 'Internal server error. Please try again later.',
						isError: true,
					},
				});
			});
	};

	setSelectedPackage = (data) => {
		this.setState({ selectedPackage: data });
	};

	onSubmitCheckoutAndBook = () => {
		const { appointment_data, student_data, tutor_data, selectedPackage } = this.state;

		this.props.history.push({
			pathname: '/checkout-and-book',
			state: {
				appointment_data,
				student_data,
				tutor_data,
				package_data: selectedPackage,
			},
		});
	};

	render() {
		const {
			isMobileScreen,
			isLoading,
			notification,
			bookingSuccess,
			default_avatar,
			appointment_data,
			student_data,
			tutor_data,
			bookingResData,
			selectedPackage,
			mainPackages,
			singleSessionPackage,
			isLoadingSingleSession,
			notificationSingleSession,
		} = this.state;

		let packageOptions = mainPackages;
		if (singleSessionPackage) {
			packageOptions = [singleSessionPackage, ...mainPackages];
		}

		const render_teach_language = tutor_data.teach_language.map((language, idx) => {
			const lastIdx = tutor_data.teach_language.length - 1;
			if (idx === lastIdx) return language.label;
			return `${language.label}, `;
		});

		const render_bridge_language = tutor_data.bridge_language.map((language, idx) => {
			const lastIdx = tutor_data.bridge_language.length - 1;
			if (idx === lastIdx) return language.label;
			return `${language.label}, `;
		});

		const render_available_session = tutor_data.time_available.map((time, idx) => {
			const lastIdx = tutor_data.time_available.length - 1;
			if (idx === lastIdx) return time;
			return `${time}, `;
		});

		const render_session_type = tutor_data.session_type.map((type, idx) => {
			const lastIdx = tutor_data.session_type.length - 1;
			if (idx === lastIdx) return type.value;
			return `${type.value}, `;
		});

		const render_city = tutor_data.city.map((city, idx) => {
			const lastIdx = tutor_data.city.length - 1;
			if (idx === lastIdx) return city.label;
			return `${city.label}, `;
		});

		return (
			<div className='row m-auto'>
				<div className='col-md-8'>
					<div className='card'>
						{bookingSuccess ? (
							<BookingSuccess
								isMobileScreen={isMobileScreen}
								bookingResData={bookingResData}
							/>
						) : (
							<div className={isMobileScreen ? 'card-body p-20' : 'card-body px-40 py-20'}>
								<div className='text-center'>
									<button
										onClick={() => this.props.history.goBack()}
										className='btn border-0 bg-transparent float-left p-0 align-items-center my-auto'
									>
										<i className='ion-arrow-left-c fs-30 text-dark' />
									</button>
									<h2 className='fs-22 mb-20 letter-spacing-0'>Booking Overview</h2>
								</div>
								<hr className='mt-20 mb-40' />
								<div className='row m-auto'>
									<div className={isMobileScreen ? 'px-0 col-md-8' : 'col-md-8'}>
										<div
											className={
												isMobileScreen
													? 'd-flex flex-column align-items-center'
													: 'd-inline-flex align-items-center'
											}
										>
											<div
												alt=''
												className='h-70px w-70px'
												style={{
													backgroundImage: `url("${
														tutor_data.avatar_display
															? tutor_data.avatar_display
															: default_avatar
													}")`,
													backgroundPosition: 'center',
													backgroundSize: 'cover',
													backgroundRepeat: 'no-repeat',
													borderRadius: '50%',
													boxShadow: '0px 2px 15px #e7e7e7',
												}}
											/>
											<div className={isMobileScreen ? 'ml-0' : 'ml-20'}>
												<p
													className={
														isMobileScreen
															? 'fs-18 letter-spacing-0 mt-20 mb-0 font-weight-bold text-capitalize'
															: 'fs-18 letter-spacing-0 mb-0 font-weight-bold text-capitalize'
													}
												>
													{tutor_data.display_name
														? tutor_data.display_name
														: `${tutor_data.first_name} ${tutor_data.last_name}`}
												</p>
												<hr
													className='mt-0 mb-1 mx-0'
													style={{
														borderTop: '2px solid var(--mainPink)',
														width: '30px',
													}}
												/>
												{isMobileScreen ? (
													<div>
														<Rating
															emptySymbol='fa fa-star-o fs-14 star-rating'
															fullSymbol='fa fa-star fs-14 star-rating'
															initialRating={tutor_data.total_rating}
															readonly
														/>
														<p
															className='fs-14'
															style={{ color: 'rgba(0, 0, 0, 0.6)' }}
														>
															{tutor_data.reviews.length === 0
																? '0 reviews'
																: `${tutor_data.reviews.length} reviews`}
														</p>
													</div>
												) : null}
												<p className='mb-0 fs-14'>
													{tutor_data.total_session}{' '}
													{tutor_data.total_session > 1 ? 'Sessions' : 'Session'}
												</p>
												{tutor_data?.living_in_country && tutor_data?.living_in_city ? (
													<p className='mb-0 fs-14'>
														Living in {tutor_data.living_in_city.label},
														{tutor_data.living_in_country.label}
													</p>
												) : tutor_data.city && tutor_data.city.length > 0 ? (
													<p className='mb-0 fs-14'>
														Living in {tutor_data.city[0].label},
														{tutor_data.city[0].country}
													</p>
												) : null}
											</div>
										</div>
									</div>
									{isMobileScreen ? null : (
										<div className='col-md-4 text-right'>
											<Rating
												emptySymbol='fa fa-star-o fs-15 star-rating'
												fullSymbol='fa fa-star fs-15 star-rating'
												initialRating={tutor_data.total_rating}
												readonly
											/>
											<p
												className='fs-15 mb-0'
												style={{ color: 'rgba(0, 0, 0, 0.6)' }}
											>
												{tutor_data.reviews.length === 0
													? '0 reviews'
													: `${tutor_data.reviews.length} reviews`}
											</p>
										</div>
									)}
								</div>
								<hr className='mt-40 mb-30' />
								<div className={isMobileScreen ? 'px-15' : 'pl-15'}>
									<h3 className='fs-12 mb-0'>Tutor Background</h3>
									<p className='fs-14 font-weight-bold'>{tutor_data.background}</p>
								</div>
								<hr className='mt-30 mb-30' />
								<div className='row m-auto'>
									<div className='col-md-4'>
										<p className='fs-12 mb-0 text-capitalize'>Teaches</p>
										<h6 className='fs-14 mb-15 fw-500'>
											{tutor_data.teach_language.length > 0 ? render_teach_language : '-'}
										</h6>
									</div>
									<div className='col-md-4'>
										<p className='fs-12 mb-0 text-capitalize'>Also speaks</p>
										<h6 className='fs-14 mb-15 fw-500'>
											{tutor_data.bridge_language.length > 0 ? render_bridge_language : '-'}
										</h6>
									</div>
									<div className='col-md-4'>
										<p className='fs-12 mb-0 text-capitalize'>Session types</p>
										<h6 className='fs-14 mb-15 fw-500 text-capitalize'>
											{tutor_data.session_type.length > 0 ? render_session_type : '-'}
										</h6>
									</div>
								</div>
								<div className='row m-auto'>
									<div className='col-md-4'>
										<p className='fs-12 mb-0 text-capitalize'>City</p>
										<h6 className='fs-14 mb-15 fw-500'>
											{!isEmpty(tutor_data.city) ? render_city : '-'}
										</h6>
									</div>
									<div className='col-md-4'>
										<p className='fs-12 mb-0 text-capitalize'>Available sessions</p>
										<h6 className='fs-14 mb-15 fw-500 text-capitalize'>
											{tutor_data.time_available.length > 0 ? render_available_session : '-'}
										</h6>
									</div>
								</div>
							</div>
						)}
					</div>

					{student_data.credit_balance < appointment_data.book_price || this.isCreditExpired(student_data) ? (
						<div className='card'>
							<div className={isMobileScreen ? 'card-body p-20' : 'card-body px-40 py-20'}>
								<p className='mb-20 text-center fs-20'>
									Top up your flowently credits to continue book this tutor
								</p>
								<PackageTabs
									packages={packageOptions}
									selectedPackage={selectedPackage}
									setSelectedPackage={(data) => this.setSelectedPackage(data)}
								/>
							</div>
						</div>
					) : null}
				</div>
				<div className='col-md-4'>
					{!bookingSuccess ? (
						<div>
							<div className='card'>
								<div className='card-body p-20'>
									<div className='d-flex align-items-center justify-content-center'>
										<p className='mb-0 font-weight-bold fs-20'>
											Your Flowently Credit is{' '}
											{student_data.credit_balance > 0
												? student_data.credit_balance.toFixed(2)
												: student_data.credit_balance}
											{this.isCreditExpired(student_data) && (
												<span className='d-block fs-14 text-center text-danger'>
													Your credit has expired
												</span>
											)}
										</p>
									</div>
								</div>
							</div>
							{student_data.credit_balance < appointment_data.book_price ||
							this.isCreditExpired(student_data) ? (
								<div className='card'>
									<div className={isMobileScreen ? 'card-body p-20' : 'card-body px-40 py-20'}>
										<div className='text-center'>
											<h2 className='fs-22 mb-20 letter-spacing-0'>Package details</h2>
											<hr className='mt-20 mb-40' />
											{isLoadingSingleSession ? (
												<div className='text-center'>
													<MDSpinner />
													<p className='mt-1'>please wait...</p>
												</div>
											) : !isEmpty(notificationSingleSession) ? (
												<div>
													<Notification notification={notificationSingleSession} />
												</div>
											) : (
												<div>
													<div className='d-flex align-items-center justify-content-between mt-20'>
														<p className='mb-0 fs-14'>Flowently credit</p>
														<p className='mb-0 fs-14'>
															{selectedPackage
																? `${selectedPackage.credit_value} ${
																		selectedPackage.credit_value > 1
																			? 'credits'
																			: 'credit'
																  }`
																: '-'}
														</p>
													</div>
													<div className='d-flex align-items-center justify-content-between mt-20'>
														<p className='mb-0 fs-14'>Price</p>
														<p className='mb-0 fs-14'>
															&euro; {selectedPackage ? selectedPackage.sale_price : '-'}
														</p>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							) : null}
						</div>
					) : null}
					<div className='card'>
						<div className={isMobileScreen ? 'card-body p-20' : 'card-body p-30'}>
							<h3 className='mb-0 text-center fs-20'>Booking Details</h3>
							<hr className='my-10' />
							<div className='d-flex align-items-center justify-content-between mt-20'>
								<p className='mb-0 fs-14'>Date</p>
								<p className='mb-0 fs-14'>
									{moment(appointment_data.appointment_date, 'MM/DD/YYYY').format('MMM DD, YYYY')}
								</p>
							</div>
							<div className='d-flex align-items-center justify-content-between mt-20'>
								<p className='mb-0 fs-14'>Session type</p>
								<p className='mb-0 fs-14 text-capitalize'>{appointment_data.session_type.value}</p>
							</div>
							<div className='d-flex align-items-center justify-content-between mt-20'>
								<p className='mb-0 fs-14'>Session duration</p>
								<p className='mb-0 fs-14'>{appointment_data.session_duration.label}</p>
							</div>
							<div className='d-flex align-items-center justify-content-between mt-20'>
								<p className='mb-0 fs-14'>Person</p>
								<p className='mb-0 fs-14'>x {appointment_data.person.value}</p>
							</div>
							<hr className='my-20' />
							{bookingSuccess && !isEmpty(bookingResData) ? (
								<>
									<div className='d-flex align-items-center justify-content-between mt-20'>
										<p className='mb-0 fs-14'>Number of credits</p>
										<p className='mb-0 fs-14'>{appointment_data.book_price}</p>
									</div>
									<hr className='my-20' />
									<div className='d-flex align-items-center justify-content-between'>
										<div className='d-flex align-items-center'>
											<img
												src='/assets/img/icon/flowently-credit.svg'
												alt=''
											/>
											<p className='mb-0 font-weight-bold ml-2'>Recent flowently credit</p>
										</div>
										<p className='mb-0 font-weight-bold fs-16'>
											{bookingResData.student_credit.toFixed(2)}
										</p>
									</div>
								</>
							) : (
								<div className='d-flex align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<img
											src='/assets/img/icon/flowently-credit.svg'
											alt=''
										/>
										<p className='mb-0 font-weight-bold ml-2'>Flowently credit</p>
									</div>
									<p className='mb-0 font-weight-bold fs-16'>{appointment_data.book_price}</p>
								</div>
							)}
							{bookingSuccess ? null : (
								<>
									<hr className='my-20' />
									<div className='d-flex align-items-center justify-content-center px-15'>
										<img
											src='/assets/img/icon/alert-circle.svg'
											alt=''
										/>
										<span className='ml-3 fs-14'>
											Your tutor will contact you directly by phone or email to discuss details of
											your session (exact time, place and topic).
										</span>
									</div>
									<div className='mt-30'>
										<Notification notification={notification} />
										{isLoading ? (
											<LoadingGift />
										) : student_data.credit_balance < appointment_data.book_price ||
										  this.isCreditExpired(student_data) ? (
											<button
												onClick={this.onSubmitCheckoutAndBook}
												className='btn btn-main-pink py-10'
												style={{ width: '100%' }}
											>
												Checkout & Book
											</button>
										) : (
											<button
												onClick={this.onSubmit}
												className='btn btn-main-pink py-10'
												style={{ width: '100%' }}
											>
												Confirm
											</button>
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		userCreateNewAppointment,
		userGetSingleProfile,
		userGetAllPackages,
		userGetSinglePackageDetail,
	})(BookingOverview)
);
