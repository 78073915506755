import React, { Component } from 'react';

export class PackageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  handleHover = () => {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
    }));
  };

  pushToCheckout = () => {
    const selected_package = this.props.item;

    this.props.history.push({
      pathname: `/packages/checkout/${selected_package.package_id}`,
      state: { selected_package },
    });
  };

  render() {
    const { item, idx, isMobileScreen } = this.props;
    const { isHovered } = this.state;

    const cardHovered = isHovered ? 'card package-card-hovered' : 'card';
    const cardScaleUp = idx === 1 ? 'card-scale-up' : '';

    const render_benefit = item.benefit_list.map((benefit, idx) => (
      <div
        key={`${item._id}-benefit-${idx}`}
        className={
          isMobileScreen
            ? 'd-flex align-items-start px-10 my-10'
            : 'd-flex align-items-start px-60 my-10'
        }>
        <i
          className="fa fa-check-circle-o fs-19"
          style={{ color: '#2acf7f' }}></i>
        <span className={benefit.bold_text ? 'ml-3 font-weight-bold' : 'ml-3'}>
          {benefit.text}
        </span>
      </div>
    ));

    return (
      <div id="package-list" className="col-md-4">
        <div
          className={`${cardHovered} ${cardScaleUp}`}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleHover}>
          <div
            className={
              isMobileScreen ? 'card-body py-40' : 'card-body px-20 py-40'
            }>
            <h4
              className="fs-25 font-weight-bold text-center mx-auto"
              style={{
                minHeight: isMobileScreen ? 0 : 65,
                color:
                  idx === 1
                    ? '#93b9fc'
                    : isHovered
                    ? 'var(--mainPink)'
                    : 'black',
                width: '65%',
              }}>
              {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
            </h4>
            {idx === 1 ? (
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center justify-content-center my-20">
                  <h4 className="font-weight-bold mb-0 fs-80 text-black lh-1">
                    {item.credit_value}
                  </h4>
                  <h4 className="font-weight-normal fs-25 text-black mb-0">
                    hours
                  </h4>
                </div>
                <div className="recommended-package-circle">
                  <p className="mb-0 mt-10 text-white fs-13 text-center font-weight-bold">
                    Recommended <br /> package
                  </p>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center my-20">
                <h4 className="font-weight-bold mb-0 fs-80 text-black lh-1">
                  {item.credit_value}
                </h4>
                <h4 className="font-weight-normal fs-25 text-black mb-0">
                  hours
                </h4>
              </div>
            )}
            <p
              className={
                isMobileScreen
                  ? 'package-description text-center fs-16 px-10 letter-spacing-0'
                  : 'package-description text-center fs-16 px-40 letter-spacing-0'
              }
              style={{ lineHeight: 1.5, minHeight: isMobileScreen ? 0 : 80 }}>
              {item.description}
            </p>
            <div className="my-20">{render_benefit}</div>
            <div
              className={
                isMobileScreen
                  ? 'price-box mx-10 pt-20 pb-0'
                  : 'price-box mx-60 pt-20 pb-0'
              }>
              <h5
                className="mb-0 fs-24 lh-1 text-center mb-2"
                style={{
                  textDecoration: 'line-through',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}>
                &euro;
                {item.origin_price}
              </h5>
              <h5 className="mb-0 fs-45 lh-1 text-center font-weight-bold">
                &euro;{item.sale_price}
              </h5>
              <button
                onClick={this.pushToCheckout}
                className="btn btn-buy-package d-flex justify-content-between align-items-center letter-spacing-0 mt-20 py-10 px-40"
                style={{
                  borderRadius: 25,
                  backgroundColor:
                    idx === 1
                      ? 'var(--mainPink)'
                      : isHovered
                      ? 'var(--mainPink)'
                      : 'white',
                  color:
                    idx === 1
                      ? 'white'
                      : isHovered
                      ? 'white'
                      : 'var(--mainPink)',
                }}>
                <span className="text-capitalize">Buy package</span>
                <i className="ion-chevron-right font-weight-bold fs-16"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PackageCard;
