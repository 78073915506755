import React from 'react';

const TextFieldDetailsGroup = ({
  value,
  label,
  onEdit,
  onChange,
  field,
  placeholder,
  disabled,
  addStyle,
  id,
  error,
  type,
  currency,
}) => {
  return (
    <div className="">
      <label style={{ color: '#959595' }} className="mb-0 fs-14 fw-500">
        {label}
      </label>
      <div
        className="pl-0 mb-2 d-flex align-items-baseline"
        style={{ minHeight: '37px' }}>
        {onEdit ? (
          <div>
            <div
              className={
                error ? 'form-group has-error has-danger' : 'form-group '
              }>
              <input
                value={value}
                onChange={onChange}
                name={field}
                placeholder={placeholder}
                className={'form-control'}
                style={addStyle}
                disabled={disabled}
                id={id}
              />
              {error && (
                <div className="form-control-feedback d-block fs-11">
                  {error}
                </div>
              )}
            </div>
            <span className="mb-0 ml-3">{type}</span>
          </div>
        ) : (
          <p className="fw-600 mb-0 fs-14" style={addStyle}>
            {currency ? currency : null} {value}
          </p>
        )}
      </div>
    </div>
  );
};

export default TextFieldDetailsGroup;
