import React, { Component } from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { userGetSingleProfile } from '../../../../redux/actions/studentActions';
import isEmpty from 'lodash/isEmpty';
import MDSpinner from 'react-md-spinner';

class StudentAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isMobileScreen: false,
      isOpenNotesModal: false,
      upcoming_appointment: [],
      history_appointment: [],
    };
  }

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
    window.scrollTo(0, 0);
  };

  componentWillMount = () => {
    this.setState({ isLoading: true, notification: {} });
    this.props
      .userGetSingleProfile({ email: this.props.auth.user.email })
      .then((res) => {
        if (res.status === 200) {
          const today = moment(new Date()).format('MM/DD/YYYY');
          const upcoming_appointment = [];
          const history_appointment = [];

          // eslint-disable-next-line
          let new_data = res.message.data.appointments.map(data => {
            const appointmentDate = data.appointment_date;
            const history = moment(appointmentDate).isBefore(today);

            // Check unconfirmed appointment
            const expired_date = moment(
              data.created_date,
              'MM/DD/YYYY, HH:mm:ss',
            ).add(1, 'days');

            const todayTime = moment(new Date()).format('MM/DD/YYYY, HH:mm:ss');
            const todayMoment = moment(todayTime, 'MM/DD/YYYY, HH:mm:ss');

            const isExpired = moment(todayMoment).isAfter(expired_date);

            if (!isEmpty(data.tutor_id)) {
              if (
                history ||
                data.isCanceled ||
                (!data.isConfirmed && isExpired)
              ) {
                history_appointment.push(data);
              } else {
                upcoming_appointment.push(data);
              }
            }

            return data;
          });

          upcoming_appointment.sort((first, second) => {
            return (
              new Date(second.appointment_date_iso) -
              new Date(first.appointment_date_iso)
            );
          });

          history_appointment.sort((first, second) => {
            return (
              new Date(second.appointment_date_iso) -
              new Date(first.appointment_date_iso)
            );
          });

          this.setState({
            isLoading: false,
            upcoming_appointment,
            history_appointment,
          });
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: 'Failed to load your profile data. Please try again later.',
              isError: true,
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          notification: {
            text: 'Failed to load your profile data. Please try again later.',
            isError: true,
          },
        });
      });
  };

  pushToDetail = (data) => {
    const { match } = this.props;
    this.props.history.push({
      pathname: `${match.path}/detail`,
      state: { appointment_data: data },
    });
  };

  render() {
    const {
      isLoading,
      isMobileScreen,
      upcoming_appointment,
      history_appointment,
    } = this.state;

    const render_upcoming_appointment = upcoming_appointment.map((app) => {
      return (
        <tr
          onClick={() => this.pushToDetail(app)}
          id="appointment-table-row"
          key={`appointment-${app.booking_id}`}>
          <td
            className={
              isMobileScreen
                ? 'fs-14 cursor-default'
                : 'fs-14 cursor-default pl-30'
            }>
            {app.booking_id}
          </td>
          <td className="fs-14 cursor-default">
            {app.tutor_id.first_name} {app.tutor_id.last_name}
          </td>
          {!isMobileScreen ? (
            <td className="fs-14 cursor-default">
              {moment(app.appointment_date, 'MM/DD/YYYY').format(
                'DD MMMM YYYY',
              )}
            </td>
          ) : null}
          {!isMobileScreen ? (
            <td className="fs-14 cursor-default text-uppercase">
              {app.time_session}
            </td>
          ) : null}
          {!isMobileScreen ? (
            <td
              className="fs-14 cursor-default text-uppercase"
              style={{
                color:
                  app.booking_status === 'cancel'
                    ? 'red'
                    : app.booking_status === 'expired'
                    ? 'gray'
                    : isEmpty(app.time_session)
                    ? 'rgba(0,0,0,0.4)'
                    : app.booking_status === 'confirmed'
                    ? '#43e30e'
                    : 'black',
                fontWeight: 'bold',
              }}>
              {app.booking_status === 'open'
                ? 'New'
                : app.booking_status === 'success'
                ? 'Completed'
                : app.booking_status === 'cancel'
                ? 'Cancelled'
                : app.booking_status === 'confirmed'
                ? 'Confirmed'
                : app.booking_status}
            </td>
          ) : null}
          <td>
            <label
              onClick={() => this.pushToDetail(app)}
              className="fs-14 text-info mb-0 cursor-pointer z1 letter-spacing-0">
              view details
            </label>
          </td>
        </tr>
      );
    });

    const render_history_appointment = history_appointment.map((app) => {
      return (
        <tr
          onClick={() => this.pushToDetail(app)}
          id="appointment-table-row"
          key={`appointment-${app.booking_id}`}>
          <td
            className={
              isMobileScreen
                ? 'fs-14 cursor-default'
                : 'fs-14 cursor-default pl-30'
            }>
            {app.booking_id}
          </td>
          <td className="fs-14 cursor-default">
            {app.tutor_id.first_name} {app.tutor_id.last_name}
          </td>
          {!isMobileScreen ? (
            <td
              className="fs-14 cursor-default"
              style={{
                color: app.booking_status === 'cancel' ? 'red' : 'black',
                textTransform:
                  app.booking_status === 'cancel' ? 'uppercase' : 'capitalize',
                fontWeight: app.booking_status === 'cancel' ? 'bold' : 'normal',
              }}>
              {app.booking_status === 'cancel'
                ? 'Cancel'
                : moment(app.appointment_date, 'MM/DD/YYYY').format(
                    'MMMM DD, YYYY',
                  )}
            </td>
          ) : null}
          {!isMobileScreen ? (
            <td
              className="fs-14 cursor-default text-uppercase"
              style={{
                color:
                  app.booking_status === 'cancel'
                    ? 'red'
                    : app.booking_status === 'expired'
                    ? 'red'
                    : isEmpty(app.time_session)
                    ? 'rgba(0,0,0,0.4)'
                    : app.booking_status === 'confirmed' ||
                      app.booking_status === 'success'
                    ? '#43e30e'
                    : 'black',
                fontWeight: 'bold',
              }}>
              {app.booking_status === 'open'
                ? 'New'
                : app.booking_status === 'success'
                ? 'Completed'
                : app.booking_status === 'cancel'
                ? 'Cancelled'
                : app.booking_status === 'confirmed'
                ? 'Confirmed'
                : app.booking_status}
            </td>
          ) : null}
          <td>
            <label
              onClick={() => this.pushToDetail(app)}
              className="fs-14 mb-0 cursor-pointer z1 text-info letter-spacing-0">
              view details
            </label>
          </td>
        </tr>
      );
    });

    return (
      <div id="my-appointment" className="col-md-8">
        <ul className="nav nav-tabs nav-tabs-inverse-mode shadow-2">
          <li
            className="nav-item"
            style={{ width: isMobileScreen ? '100%' : '50%', height: '100%' }}>
            <a
              className="nav-link p-30 active d-flex justify-content-center align-items-center"
              data-toggle="tab"
              href="#upcoming-appointment">
              <img
                src="/assets/img/icon/upcoming-appointment.svg"
                alt=""
                className="mr-2"
                style={{ height: 24 }}
              />
              <span className="fs-20 font-weight-bold">
                Upcoming Appointments
              </span>
            </a>
          </li>
          <li
            className="nav-item"
            style={{ width: isMobileScreen ? '100%' : '50%', height: '100%' }}>
            <a
              className="nav-link p-30 d-flex justify-content-center align-items-center"
              data-toggle="tab"
              href="#appointment-history">
              <img
                src="/assets/img/icon/history-appointment.svg"
                className="mr-2"
                alt=""
                style={{ height: '24px' }}
              />
              <span className="fs-20 font-weight-bold">
                Previous Appointments
              </span>
            </a>
          </li>
        </ul>
        <div className="tab-content shadow-3 mt-30 p-0">
          <div className="tab-pane fade active show" id="upcoming-appointment">
            {isLoading ? (
              <div
                style={{ height: '185px' }}
                className="bg-white d-flex justify-content-center align-items-center">
                <MDSpinner />
              </div>
            ) : upcoming_appointment.length > 0 ? (
              <table id="appointment-table" className="table bg-white">
                <thead>
                  <tr>
                    <th
                      className={isMobileScreen ? '' : 'pl-30'}
                      style={{ width: isMobileScreen ? '30%' : '15%' }}>
                      Session ID
                    </th>
                    <th style={{ width: 'auto' }}>Tutor</th>
                    {!isMobileScreen ? (
                      <th style={{ width: '20%' }}>Date</th>
                    ) : null}
                    {!isMobileScreen ? (
                      <th style={{ width: '15%' }}>Time</th>
                    ) : null}
                    {!isMobileScreen ? (
                      <th style={{ width: '15%' }}>Status</th>
                    ) : null}
                    {!isMobileScreen ? (
                      <th style={{ width: '15%' }}>
                        Total: {upcoming_appointment.length}
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>{render_upcoming_appointment}</tbody>
              </table>
            ) : (
              <div
                style={{ height: '185px' }}
                className="bg-white d-flex justify-content-center align-items-center">
                <p className="mb-0">There is no upcoming appointment</p>
              </div>
            )}
          </div>
          <div className="tab-pane fade" id="appointment-history">
            {isLoading ? (
              <div
                style={{ height: '185px' }}
                className="bg-white d-flex justify-content-center align-items-center">
                <MDSpinner />
              </div>
            ) : history_appointment.length > 0 ? (
              <table id="appointment-table" className="table bg-white">
                <thead>
                  <tr>
                    <th
                      className={isMobileScreen ? '' : 'pl-30'}
                      style={{ width: isMobileScreen ? '30%' : '15%' }}>
                      Session ID
                    </th>
                    <th style={{ width: 'auto' }}>Tutor</th>
                    {!isMobileScreen ? (
                      <th style={{ width: '20%' }}>Date</th>
                    ) : null}
                    {!isMobileScreen ? (
                      <th style={{ width: '15%' }}>Status</th>
                    ) : null}
                    {!isMobileScreen ? (
                      <th style={{ width: '15%' }}>
                        Total: {history_appointment.length}
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>{render_history_appointment}</tbody>
              </table>
            ) : (
              <div
                style={{ height: '185px' }}
                className="bg-white d-flex justify-content-center align-items-center">
                <p className="mb-0">There is no history appointment</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { userGetSingleProfile })(
  StudentAppointment,
);
