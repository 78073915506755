import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoginFormStudent from './loginFormStudent';
import LoginFormTutor from './loginFormTutor';

function loginPage(props) {
  const { match } = props;

  return (
    <div className="main-content">
      <div className="login-wrapper mx-20">
        <div className="row m-auto overflow-hidden">
          <div className="col-md-5 signup-form-wrapper">
            <Switch>
              <Route path={`${match.path}/tutor`} component={LoginFormTutor} />
              <Route
                path={`${match.path}/student`}
                component={LoginFormStudent}
              />
              <Redirect to={`${match.path}/student`} />
            </Switch>
          </div>
          <div className="col-md-7 bg-login"></div>
        </div>
      </div>
    </div>
  );
}

export default loginPage;
