import { API } from '../../utils/api';

export function userCreateNewAppointment(data) {
  return (dispatch) => {
    return API.post('/appointment/add-appointment', data).then((res) => {
      return res.data;
    });
  };
}

export function userGetSingleAppointment(data) {
  return (dispatch) => {
    return API.post('/appointment/single', data).then((res) => {
      return res.data;
    });
  };
}

export function userGetAppointmentBySessionID(data) {
  return (dispatch) => {
    return API.post('/appointment/detail', data).then((res) => {
      return res.data;
    });
  };
}

export function userEditAppointment(data) {
  return (dispatch) => {
    return API.post('/appointment/edit', data).then((res) => {
      return res.data;
    });
  };
}

export function userSetAppointmentExpired(data) {
  return (dispatch) => {
    return API.post('/appointment/expired', data).then((res) => {
      return res.data;
    });
  };
}

export function userCancelAppointment(data) {
  return (dispatch) => {
    return API.post('/appointment/cancel', data).then((res) => {
      return res.data;
    });
  };
}

export function userAddNewNote(data) {
  return (dispatch) => {
    return API.post('/appointment/add-note', data).then((res) => {
      return res.data;
    });
  };
}

export function userSubmitReview(data) {
  return (dispatch) => {
    return API.post('/appointment/add-review', data).then((res) => {
      return res.data;
    });
  };
}

export function userUpdateBookingGtmTrackedStatus(data) {
  return (dispatch) => {
    return API.post('/appointment/update-gtm', data).then((res) => {
      return res.data;
    });
  };
}
