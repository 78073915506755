import React, { Component } from 'react';
import moment from 'moment';
import { sortBy } from 'lodash';

import TextAreaFieldGroup from '../../../common/TextAreaFieldGroup';

import { connect } from 'react-redux';
import { userAddNewNote } from '../../../../redux/actions/appointmentActions';

class StudentAppointmentNotesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      new_note: {
        text: '',
      },
      isMobileScreen: this.props.isMobileScreen,
      isLoading: false,
      notification: {},
      errors: {},
    };
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.data !== this.props.data) {
      // this.props.refreshData();
      return false;
    }
    return true;
  };

  onChangeNewNote = (e) => {
    const new_note = {
      ...this.state.new_note,
      [e.target.name]: e.target.value,
    };

    this.setState({
      errors: {
        ...this.state.errors,
        [e.target.name]: null,
      },
      new_note,
    });
  };

  parseNotes = (data) => {
    const result = data.notes.map((note) => {
      return {
        ...note,
        errors: null,
        isEditing: false,
        isLoading: false,
      };
    });
    return result;
  };

  onSaveNewNote = () => {
    this.setState({ isLoading: true, notification: {} });
    const { data } = this.state;
    const new_note = data.notes;
    const added_note = {
      author: {
        _id: this.props.auth.user.id,
        name: this.props.auth.user.name,
      },
      text: this.state.new_note.text,
      createdDate: moment(new Date()).format('MM/DD/YYYY, HH:mm:ss'),
    };
    new_note.push(added_note);

    const dataToSumbit = {
      // appointment ID
      _id: data._id,
      new_note,
    };

    this.props.userAddNewNote(dataToSumbit).then((res) => {
      if (res.status === 200) {
        const parse_notes = this.parseNotes(res.message.data);

        let sorted_notes = sortBy(parse_notes, (item) => {
          return moment(item.createdDate, 'MM/DD/YYYY');
        }).reverse();

        this.setState({
          isLoading: false,
          new_note: {
            text: '',
          },
          data: {
            ...res.message.data,
            notes: sorted_notes,
          },
        });
      } else {
        this.setState({
          isLoading: false,
          notification: {
            text: 'We are experiencing technical difficulties at the moment, please try again later',
            isError: true,
          },
        });
      }
    });
  };

  onEditNote = (idx) => {
    const new_notes = this.state.data.notes.map((note, sidx) => {
      if (idx !== sidx) {
        return note;
      } else {
        return {
          ...note,
          isEditing: true,
        };
      }
    });
    // console.log(new_notes)
    this.setState({
      data: {
        ...this.state.data,
        notes: new_notes,
      },
    });
  };

  onCancelEditNote = (idx) => {
    const new_notes = this.state.data.notes.map((note, sidx) => {
      if (idx !== sidx) {
        return note;
      } else {
        return {
          ...note,
          isEditing: false,
        };
      }
    });
    this.setState({
      data: {
        ...this.state.data,
        notes: new_notes,
      },
    });
  };

  onChangeEditNote = (e, idx) => {
    const new_notes = this.state.data.notes.map((note, sidx) => {
      if (idx !== sidx) {
        return note;
      } else {
        return {
          ...note,
          text: e.target.value,
        };
      }
    });
    this.setState({
      data: {
        ...this.state.data,
        notes: new_notes,
      },
    });
  };

  onSaveEditNote = (idx, type) => {
    this.setState({ isLoading: true, notification: {} });
    let new_note;
    if (type === 'delete') {
      new_note = this.state.data.notes.filter((obj, sidx) => {
        return idx !== sidx;
      });
    } else {
      new_note = this.state.data.notes.map((note, sidx) => {
        if (idx !== sidx) {
          return note;
        } else {
          return {
            ...note,
            isEditing: false,
            text: note.text,
          };
        }
      });
    }

    const dataToSumbit = {
      // appointment ID
      _id: this.state.data._id,
      new_note,
    };

    this.props.userAddNewNote(dataToSumbit).then((res) => {
      if (res.status === 200) {
        const parse_notes = this.parseNotes(res.message.data);
        const new_data = {
          ...res.message.data,
          notes: parse_notes,
        };
        this.setState({
          isLoading: false,
          data: new_data,
        });
      } else {
        this.setState({
          isLoading: false,
          notification: {
            text: 'We are experiencing technical difficulties at the moment, please try again later',
            isError: true,
          },
        });
      }
    });
  };

  render() {
    const { student_id, booking_id, notes } = this.state.data;

    const {
      isMobileScreen,
      isLoading,
      new_note,
      // notification,
      errors,
    } = this.state;

    const { auth } = this.props;

    let lastIdx;
    if (notes) lastIdx = notes.length - 1;

    const notes_render = notes.map((note, idx) => {
      return (
        <div
          id="single-note-detail"
          key={`note-${idx + 1}`}
          className={idx === lastIdx ? '' : 'border-bottom mb-10 pb-10'}
          style={{ borderBottomColor: '#f6f6f6!important' }}>
          <p className="fs-16 font-weight-bold mb-1">{note.author.name}</p>
          {note.isEditing ? (
            <TextAreaFieldGroup
              field="text"
              value={notes[idx].text}
              onChange={(e) => this.onChangeEditNote(e, idx)}
              disabled={notes[idx].isLoading}
              error={notes[idx].errors ? notes[idx].errors.text : ''}
            />
          ) : (
            <p className="fs-14 mb-1">{note.text}</p>
          )}
          {note.isEditing ? (
            <div className="d-flex justify-content-end">
              <button
                onClick={() => this.onCancelEditNote(idx)}
                className="btn btn-sm btn-outline-main-pink mr-2">
                Cancel
              </button>
              <button
                onClick={() => this.onSaveEditNote(idx, 'edit')}
                className="btn btn-sm btn-main-pink">
                Save
              </button>
            </div>
          ) : (
            <div className="d-flex">
              <small>
                {moment(note.createdDate, 'MM/DD/YYYY').format('MMMM DD, YYYY')}
              </small>
              {note.author._id === auth.user.id ? (
                <>
                  <small
                    id="edit-note"
                    onClick={() => this.onEditNote(idx)}
                    className="text-info mx-2 cursor-pointer">
                    Edit
                  </small>
                  <small
                    id="delete-note"
                    onClick={() => this.onSaveEditNote(idx, 'delete')}
                    className="text-info cursor-pointer">
                    Delete
                  </small>
                </>
              ) : null}
            </div>
          )}
        </div>
      );
    });

    return (
      <div>
        <div
          className="modal modal-center fade show"
          id="modal-center"
          style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div
              className="modal-content"
              style={{ width: isMobileScreen ? '100%' : '692px' }}>
              <div className="text-right p-10">
                <i
                  onClick={() => this.props.closeModal(this.state.data)}
                  className="ion-close-round cursor-pointer"></i>
              </div>
              <div className="modal-header justify-content-between pt-20 pb-10 px-30">
                <h5 className="modal-title fw-500 fs-25">
                  {student_id.first_name} {student_id.last_name}
                </h5>
                <h5 className="modal-title fw-500 fs-25">ID : {booking_id}</h5>
              </div>
              <div
                className={
                  isMobileScreen
                    ? 'modal-body m-0 p-30'
                    : 'modal-body m-0 py-30 px-40'
                }>
                <p className="mb-0">Add note</p>
                <TextAreaFieldGroup
                  field="text"
                  value={new_note.text}
                  placeholder="Type something"
                  onChange={this.onChangeNewNote}
                  disabled={isLoading}
                  error={errors.text}
                />
                <div className="text-right">
                  <button
                    onClick={this.onSaveNewNote}
                    className="btn btn-w-md btn-main-pink">
                    Add note
                  </button>
                </div>
                {notes.length > 0 ? (
                  <>
                    <div className="my-20 d-flex align-items-center">
                      <p className="mb-0 flex-grow-1">All notes</p>
                      <div style={{ flexGrow: 20, WebkitFlexGrow: 20 }}>
                        <hr
                          className="m-0"
                          style={{ border: 'solid 2px #f6f6f6' }}
                        />
                      </div>
                    </div>
                    <div className="">{notes_render}</div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { userAddNewNote })(
  StudentAppointmentNotesModal,
);
