import React from 'react';

const PackageTabs = ({ packages, selectedPackage, setSelectedPackage }) => {
  let render_packages = null;

  if (packages.length > 0) {
    render_packages = packages.map((item, idx) => {
      const isSelected = selectedPackage.package_id === item.package_id;
      return (
        <div
          key={`package${idx}-${item.package_id}`}
          className="col-md-3 px-10 mb-20">
          <div
            className={`card border-round cursor-pointer mb-0 ${
              isSelected ? 'selected-card' : ''
            }`}
            style={{ height: '100%' }}>
            {isSelected ? (
              <div className="selected-badge">
                <i className="fa fa-check mr-1"></i>
                <span className="fs-12">Selected</span>
              </div>
            ) : null}
            <div
              onClick={() => setSelectedPackage(item)}
              className={`card-body pt-20 ${isSelected ? 'pb-40' : ''}`}>
              <h4 className="fw-600 text-center cursor-pointer">
                {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
              </h4>
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center justify-content-center my-20">
                  <h4 className="font-weight-bold mb-0 fs-80 text-black lh-1">
                    {item.hours}
                  </h4>
                  <h4 className="font-weight-normal fs-25 text-black mb-0">
                    {item.hours > 1 ? 'hours' : 'hour'}
                  </h4>
                </div>
              </div>
              <div className="d-flex flex-column">
                <p className="text-center">{item.description}</p>
                <div className="price-box py-20 pb-0">
                  {item.origin_price !== item.sale_price ? (
                    <h5
                      className="mb-0 fs-24 lh-1 text-center mb-2"
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgba(0, 0, 0, 0.6)',
                      }}>
                      &euro;
                      {item.origin_price}
                    </h5>
                  ) : null}
                  <h5 className="mb-0 fs-35 lh-1 text-center font-weight-bold">
                    &euro;{item.sale_price}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div id="package-tab">
      <div className="row mx-auto mb-10">{render_packages}</div>
      {/* <ul className="nav nav-tabs nav-tabs-inverse-mode justify-content-center">
        <li className="nav-item mx-10">
          <a
            className="nav-link active"
            data-toggle="tab"
            href="#single-session"
            onClick={() => setSelectedPackage(singleSessionPackage)}
          >
            Buy single session
          </a>
        </li>
        <li className="nav-item mx-10">
          <a
            className="nav-link"
            data-toggle="tab"
            href="#main-package"
            onClick={() => setSelectedPackage(packages[0])}
          >
            Buy package
          </a>
        </li>
      </ul>
      <div className="tab-content bg-white px-0">
        <div className="tab-pane fade active show" id="single-session">
          { singleSessionPackage ?
            <div className="d-flex align-items-center justify-content-center">
              <div className={`card border-round cursor-pointer ${selectedPackage.package_id === singleSessionPackage.package_id ? "selected-card" : ''}`}>
                { selectedPackage.package_id === singleSessionPackage.package_id ?
                  <div className='selected-badge'>
                    <i className='fa fa-check mr-1'></i>
                    <span className='fs-12'>Selected</span>
                  </div>
                : null }
                <div className={`card-body pt-20 ${ selectedPackage.package_id === singleSessionPackage.package_id ? "pb-40" : ""}`}>
                  <h4 className="fw-600 text-center cursor-pointer">
                    { singleSessionPackage
                      ? singleSessionPackage.title.charAt(0).toUpperCase() + singleSessionPackage.title.slice(1)
                    : null}
                  </h4>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='d-flex flex-column align-items-center justify-content-center my-20'>
                      <h4 className='font-weight-bold mb-0 fs-80 text-black lh-1'>
                        {singleSessionPackage.credit_value}
                      </h4>
                      <h4 className='font-weight-normal fs-25 text-black mb-0'>hours</h4>
                    </div>
                  </div>
                  <p className='text-center'>
                    {singleSessionPackage.description}
                  </p>
                  <div className='price-box py-20 pb-0'>
                    <h5 className='mb-0 fs-24 lh-1 text-center mb-2' style={{textDecoration: 'line-through', color: 'rgba(0, 0, 0, 0.6)'}}>
                      &euro;
                      {singleSessionPackage.origin_price}
                    </h5>
                    <h5 className='mb-0 fs-35 lh-1 text-center font-weight-bold'>&euro;{singleSessionPackage.sale_price}</h5>
                  </div>
                </div>
              </div>
            </div>
          : null }
        </div>
        <div className="tab-pane fade" id="main-package">
          <div className='row mx-auto'>
            {render_packages}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PackageTabs;
