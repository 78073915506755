/* eslint-disable no-undef*/
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { DOMAIN } from '../../../utils/api';

function Footer() {
  const [smallScreen, setSmallScreen] = useState(false);

  const [footerCol1, setFooterCol1] = useState([]);
  const [footerCol2, setFooterCol2] = useState([]);
  const [footerCol3, setFooterCol3] = useState([]);

  function resizeScreen() {
    setSmallScreen(window.innerWidth <= 768);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeScreen);
    resizeScreen();

    // footer menu 1
    axios.get(`${DOMAIN}/wp-json/menus/v1/menus/footer-col-1`).then((res) => {
      const footer_col_1 = res.data.items.map((menu) => {
        return {
          title: menu.title,
          url: menu.url,
        };
      });
      setFooterCol1(footer_col_1);
    });

    axios.get(`${DOMAIN}/wp-json/menus/v1/menus/footer-col-2`).then((res) => {
      const footer_col_2 = res.data.items.map((menu) => {
        return {
          title: menu.title,
          url: menu.url,
        };
      });
      setFooterCol2(footer_col_2);
    });

    axios.get(`${DOMAIN}/wp-json/menus/v1/menus/footer-col-3`).then((res) => {
      const footer_col_3 = res.data.items.map((menu) => {
        return {
          title: menu.title,
          url: menu.url,
        };
      });
      setFooterCol3(footer_col_3);
    });

    return () => {
      window.removeEventListener('resize', resizeScreen);
    };
  }, []);

  const render_footer_col_1 = footerCol1.map((item, idx) => {
    return (
      <p className="fs-18 mb-2" key={`footer-1-${idx + 1}`}>
        <a className="text-white" href={item.url} target="blank">
          {item.title}
        </a>
      </p>
    );
  });

  const render_footer_col_2 = footerCol2.map((item, idx) => {
    return (
      <p className="fs-18 mb-2" key={`footer-2-${idx + 1}`}>
        <a className="text-white" href={item.url} target="blank">
          {item.title}
        </a>
      </p>
    );
  });

  const render_footer_col_3 = footerCol3.map((item, idx) => {
    return (
      <p className="fs-18 mb-2" key={`footer-3-${idx + 1}`}>
        <a className="text-white" href={item.url} target="blank">
          {item.title}
        </a>
      </p>
    );
  });

  return (
    <div className="footer-background">
      {/* <div className="m-0 p-0 footer-head"></div> */}
      <div className="" style={{ backgroundColor: '#93b9fc' }}>
        <div className="footer-content main-content mt-30 text-white">
          <div className="row m-auto">
            <div className={`col-md-3 ${smallScreen ? 'mb-4' : ''}`}>
              <h4 className="fs-20 mb-35 fw-600 text-white w-240px">
                About Flowently
              </h4>
              {footerCol1.length > 0 ? render_footer_col_1 : null}
            </div>
            <div className={`col-md-3 ${smallScreen ? 'mb-4' : ''}`}>
              <h4 className="fs-20 mb-35 fw-600 text-white">
                The Flowently Method
              </h4>
              {footerCol2.length > 0 ? render_footer_col_2 : null}
            </div>
            <div className={`col-md-3 ${smallScreen ? 'mb-4' : ''}`}>
              <h4 className="fs-20 mb-35 fw-600 text-white">
                More Information
              </h4>
              {footerCol3.length > 0 ? render_footer_col_3 : null}
            </div>
            <div
              className={`col-md-3 ${smallScreen ? 'mt-4 text-center' : ''}`}>
              <h4 className="fs-20 mb-35 fw-600 text-white">ABOUT FLOWENTLY</h4>
              <img
                src="/assets/img/white-logo.svg"
                className="my-2"
                alt=""
                style={{ width: '180px' }}
              />
              <p
                className="fs-16 fw-500 mb-0 mt-30 text-left"
                style={{ width: '90%' }}>
                Learn what you want, whenever & wherever!
                <a
                  href={`${DOMAIN}/about`}
                  className="d-block fs-16"
                  style={{
                    color: 'var(--mainPink)',
                  }}
                  target="blank">
                  Read more
                </a>
                <br />
                Azartplein 79, 1019 PB Amsterdam <br /> Tel: +31 (0)6 41339323{' '}
                <br />
                <a
                  href="mailto:info@flowently.com"
                  style={{ color: 'var(--mainPink)' }}>
                  info@flowently.com
                </a>{' '}
                <br />
                KvK: 34149671
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer
        className={`site-footer border-transparent bg-main-blue ${
          !smallScreen ? 'pt-60 pb-30' : ''
        }`}>
        <p className="fs-18 text-center text-center text-white">
          © {new Date().getFullYear()} Flowently
        </p>
      </footer>
    </div>
  );
}

export default Footer;
