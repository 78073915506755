import React from 'react';
import axios from 'axios';
import { DOMAIN } from '../../utils/api';
import TopHeader from './parts/topHeader';
import Breadcrumb from './parts/breadcrumb';
import Footer from './parts/footer';
import BottomTabMenuBar from './parts/BottomTabMenuBar';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uspHeader: {},
      isMobileScreen: false,
    };
  }

  componentDidMount = () => {
    this.getUSPHeader();
    window.addEventListener('screenSize', this.screenSize());
  };

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  getUSPHeader = async () => {
    let apiUrl = `${DOMAIN}/wp-json/wp/v2/layout/42783`;

    const res = await axios.get(apiUrl);
    if (res.status === 200) {
      const usps = res.data.acf.usps.map((item) => item.usps);
      const uspHeader = {
        id: res.data.id,
        usps,
      };

      this.setState({ uspHeader });
    }
  };

  render() {
    const { history, match } = this.props;
    const { uspHeader, isMobileScreen } = this.state;

    const isUSPdisplayed =
      history.location.pathname === '/book-language-course' &&
      uspHeader.usps &&
      uspHeader.usps.length > 0 &&
      !isMobileScreen;
    return (
      <div id="wrapper">
        <TopHeader
          history={history}
          uspHeader={uspHeader}
          isMobileScreen={isMobileScreen}
        />
        <main
          className="main-container"
          style={{ paddingTop: isUSPdisplayed ? '150px' : '100px' }}>
          <div
            className="breadcrumb-wrapper"
            style={{ backgroundColor: '#ffffff' }}>
            <Breadcrumb history={history} match={match} />
          </div>
          <div>{this.props.children}</div>
        </main>
        <Footer />
        <BottomTabMenuBar history={history} match={match} />
      </div>
    );
  }
}

export default Layout;
