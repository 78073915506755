import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import YouTube from 'react-youtube';
import Rating from 'react-rating';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { setSelectedTutor } from '../../../redux/actions/bookActions';

const youtubeComponentOpts = {
  height: 'auto',
  width: '100%',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};
class SearchTutorResultCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileScreen: false,
      isHovered: false,
      default_avatar: '/assets/img/icon/male.svg',
    };
  }

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentWillMount = () => {
    window.addEventListener('screenSize', this.screenSize());
  };

  handleHover = () => {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
    }));
  };

  onYoutubeReady = (e) => {
    e.target.stopVideo();
  };

  pushToTutorDetails = () => {
    const { data } = this.props;
    const name = data.display_name
      ? data.display_name.toLowerCase()
      : `${data.first_name.toLowerCase()} ${data.last_name.toLowerCase()}`;
    const params = name.replace(/\s/g, '-');

    this.props.setSelectedTutor(data);

    this.props.history.push({
      pathname: `/book-language-course/tutor-${params}`,
      state: { data },
    });
  };

  render() {
    const { isMobileScreen, isHovered, default_avatar } = this.state;

    const { data } = this.props;

    let youtubeID = '';
    if (!isEmpty(data.youtube_link)) {
      const query = queryString.parse(data.youtube_link);
      youtubeID = query[Object.keys(query)[0]];

      if (!youtubeID) {
        const splitURL = data.youtube_link.split('/');
        youtubeID = splitURL[splitURL.length - 1];
      }
    }

    const render_teach_language = data.teach_language.map((language, idx) => {
      const lastIdx = data.teach_language.length - 1;
      if (idx === lastIdx) return language.label;
      return `${language.label}, `;
    });

    const render_bridge_language = data.bridge_language.map((language, idx) => {
      const lastIdx = data.bridge_language.length - 1;
      if (idx === lastIdx) return language.label;
      return `${language.label}, `;
    });

    const render_session_type = data.session_type.map((language, idx) => {
      const lastIdx = data.session_type.length - 1;
      return (
        <p className="mb-0" key={`session-type-${idx + 1}`}>
          {idx === lastIdx ? `${language.label}` : `${language.label},`}
        </p>
      );
    });

    const render_location = data.country.map((el, idx) => {
      const lastIdx = data.country.length - 1;
      if (!isEmpty(el)) {
        return (
          <p key={`country-${idx}`} className="mb-0">
            {data.city[idx] && data.city[idx].label
              ? idx === lastIdx
                ? `${data.city[idx].label}`
                : `${data.city[idx].label},`
              : null}
          </p>
        );
      }
      return '-';
    });

    const resultCardHovered = isHovered ? 'card card-hovered' : 'card';

    const avatarImg = data.avatar_display
      ? data.avatar_display
      : default_avatar;

    return (
      <div
        className={resultCardHovered}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}>
        <div className="card-body p-20">
          <div className="row m-auto">
            <div
              role="button"
              onClick={this.pushToTutorDetails}
              className={isMobileScreen ? 'col-md-7' : 'col-md-7 pr-30'}>
              <div
                className={
                  isMobileScreen ? 'd-flex flex-column' : 'row mx-auto'
                }>
                {isMobileScreen ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      alt=""
                      className="h-70px w-70px"
                      style={{
                        backgroundImage: `url("${avatarImg}")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '50%',
                        boxShadow: '0px 2px 15px #e7e7e7',
                      }}
                    />
                  </div>
                ) : (
                  <div className="col-md-2 px-0 d-flex justify-content-center align-items-center">
                    <div
                      alt=""
                      className="h-70px w-70px"
                      style={{
                        backgroundImage: `url("${avatarImg}")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '50%',
                        boxShadow: '0px 2px 15px #e7e7e7',
                      }}
                    />
                  </div>
                )}
                <div className={isMobileScreen ? 'ml-0' : 'col-md-10'}>
                  <p
                    className={
                      isMobileScreen
                        ? 'fs-18 letter-spacing-0 text-center mt-20 mb-0 font-weight-bold text-capitalize'
                        : 'fs-18 letter-spacing-0 mb-0 font-weight-bold text-capitalize'
                    }>
                    {data.display_name
                      ? data.display_name
                      : `${data.first_name} ${data.last_name}`}
                  </p>
                  <div
                    className={
                      isMobileScreen
                        ? 'd-flex justify-content-center align-items-center'
                        : 'd-flex align-items-center'
                    }>
                    <Rating
                      emptySymbol="fa fa-star-o fs-12 star-rating"
                      fullSymbol="fa fa-star fs-12 star-rating"
                      initialRating={data.total_rating}
                      readonly
                    />
                    <span className="fs-12 ml-2">
                      {parseInt(data.total_rating) === 0
                        ? '( 0/5 rating from 0 review )'
                        : `( ${data.total_rating}/5 ratings from ${
                            data.reviews.length
                          } ${
                            data.reviews.length > 1 ? 'reviews' : 'review'
                          } )`}
                    </span>
                  </div>
                  <p
                    className={
                      isMobileScreen
                        ? 'font-weight-bold fs-14 mb-0 text-center mt-10'
                        : 'font-weight-bold fs-14 mb-0'
                    }>
                    Background :
                    <span className={isMobileScreen ? 'd-block' : 'ml-1'}>
                      {!isEmpty(data.background) ? data.background : '-'}
                    </span>
                  </p>
                </div>
              </div>
              <hr className="my-3" />
              <div className="row m-auto">
                <div className="col-md-4">
                  <p className="mb-0 fs-12 font-weight-light">
                    Number of session
                  </p>
                  <p className="fs-14">
                    {data.appointments.length}{' '}
                    {data.appointments.length > 1 ? 'sessions' : 'session'}
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="mb-0 fs-12 font-weight-light">
                    Availability Session
                  </p>
                  <div className="fs-14 mb-15">
                    {data.session_type.length > 0 ? render_session_type : '-'}
                  </div>
                </div>
                <div className="col-md-4">
                  <p className="mb-0 fs-12 font-weight-light">Location</p>
                  <div className="fs-14 mb-15">
                    {data.country.length > 0 && data.city.length > 0
                      ? render_location
                      : '-'}
                  </div>
                </div>
                <div className="col-md-4">
                  <p className="mb-0 fs-12 font-weight-light">Teaches</p>
                  <p className="fs-14">
                    {data.teach_language.length > 0
                      ? render_teach_language
                      : '-'}
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="mb-0 fs-12 font-weight-light">
                    Bridge Language
                  </p>
                  <p className="fs-14">
                    {data.bridge_language.length > 0
                      ? render_bridge_language
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="mx-15 mb-10">
                <button
                  onClick={this.pushToTutorDetails}
                  className="btn button-transparent border-transparent letter-spacing-0 cursor-pointer text-info p-0 fs-13">
                  View tutor &#8594;
                </button>
              </div>
            </div>

            {/* Tutor video and intro */}
            <div
              className={isMobileScreen ? 'd-none' : 'col-md-5'}
              style={{
                borderLeft: isMobileScreen
                  ? '0'
                  : '1px solid rgba(0, 0, 0, 0.1)',
              }}>
              <div
                id="tutor-intro-section"
                className="d-flex justify-content-center">
                <ul className="nav nav-tabs nav-justified nav-tabs-info">
                  <li className="nav-item">
                    <a
                      className={youtubeID ? 'nav-link' : 'nav-link active'}
                      data-toggle="tab"
                      href={`#text-intro-${data._id}`}>
                      Intro
                    </a>
                  </li>
                  {!isEmpty(youtubeID) ? (
                    <li className="nav-item">
                      <a
                        className={youtubeID ? 'nav-link active' : 'nav-link'}
                        data-toggle="tab"
                        href={`#video-intro-${data._id}`}>
                        Video
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div className="tab-content p-10">
                <div
                  className={
                    youtubeID ? 'tab-pane fade' : 'tab-pane fade active show'
                  }
                  id={`text-intro-${data._id}`}>
                  <p className="fs-14 letter-spacing-0 text-intro-desc">
                    {data.introduction}
                  </p>
                  {isEmpty(data.introduction) ? null : (
                    <span className="fs-14">
                      <button
                        onClick={this.pushToTutorDetails}
                        className="btn button-transparent border-transparent letter-spacing-0 cursor-pointer text-info p-0">
                        Read more and book
                      </button>
                    </span>
                  )}
                </div>
                {!isEmpty(youtubeID) ? (
                  <div
                    className={
                      youtubeID ? 'tab-pane fade active show' : 'tab-pane fade'
                    }
                    id={`video-intro-${data._id}`}>
                    <div className="d-flex justify-content-center">
                      <YouTube
                        videoId={youtubeID}
                        opts={youtubeComponentOpts}
                        onReady={this.onYoutubeReady}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { setSelectedTutor })(SearchTutorResultCard);
