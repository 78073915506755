import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import CheckoutBooking from './views/CheckoutBooking';
import CheckoutBookingPaymentStatus from './views/CheckoutBookingPaymentStatus';

class BuyAndBookPage extends Component {
  render() {
    const { match, auth, history } = this.props;
    return (
      <div
        className="main-content mb-50 mt-0 py-20"
        style={{ backgroundColor: '#ffffff' }}>
        <Switch>
          {auth.user.type === 'tutor' ? (
            <Redirect to="/profile/appointments" />
          ) : (
            <>
              <Route
                exact
                path={`${match.path}/payment/:order_id`}
                match={match}
                history={history}
                component={CheckoutBookingPaymentStatus}
              />
              <Route
                exact
                path={`${match.path}`}
                match={match}
                history={history}
                component={CheckoutBooking}
              />
            </>
          )}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(BuyAndBookPage);
