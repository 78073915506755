import { API } from '../../utils/api';

export function userGetSinglePackageDetail(data) {
  return (dispatch) => {
    return API.post('/single-session/detail', data).then((res) => {
      return res.data;
    });
  };
}

export function userCreateNewBookPayment(data) {
  return (dispatch) => {
    return API.post('/single-session/create-payment', data).then((res) => {
      return res.data;
    });
  };
}

export function userCheckBookPaymentStatus(data) {
  return (dispatch) => {
    return API.post('/single-session/check-payment', data).then((res) => {
      return res.data;
    });
  };
}

export function userUpdatePurchaseGtmTracked(data) {
  return (dispatch) => {
    return API.post('/single-session/update-gtm', data).then((res) => {
      return res.data;
    });
  };
}
