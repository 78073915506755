import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export function validateInputTutor(data) {
  let errors = {};

  if (!isEmpty(data.email)) {
    if (!Validator.isEmail(data.email)) {
      errors.email = 'Email is not valid';
    }
  } else {
    errors.email = 'Email is required';
  }

  if (isEmpty(data.phone)) {
    errors.phone = 'Please input your phone number';
  } else {
    if (isNaN(data.phone)) {
      errors.phone = 'Phone number is not valid';
    }
  }

  if (isEmpty(data.password)) {
    errors.password = 'Password is required';
  }

  if (Validator.isEmpty(data.first_name)) {
    errors.first_name = 'Please input first name';
  }

  if (Validator.isEmpty(data.last_name)) {
    errors.last_name = 'Please input last name';
  }

  if (data.mode === 'tutor' && !data.cv) {
    errors.cv = 'Please pick cv file';
  }

  if (data.mode === 'tutor' && !data.motivation) {
    errors.motivation = 'Please pick motivation file';
  }

  if (!data.is_agree) {
    errors.is_agree = 'Please agree to our terms and privacy policy';
  }
  // if(Validator.isEmpty(data.hearUsFrom)){
  // 	errors.hearUsFrom = "This fill is required";
  // }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export function validateInputStudent(data) {
  let errors = {};

  if (!isEmpty(data.email)) {
    if (!Validator.isEmail(data.email)) {
      errors.email = 'Email is not valid';
    }
  } else {
    errors.email = 'Email is required';
  }

  if (isEmpty(data.phone)) {
    errors.phone = 'Please input your phone number';
  } else {
    if (isNaN(data.phone)) {
      errors.phone = 'Phone number is not valid';
    }
  }

  if (isEmpty(data.password)) {
    errors.password = 'Account password is required';
  }

  if (Validator.isEmpty(data.first_name)) {
    errors.first_name = 'Please input first name';
  }

  if (Validator.isEmpty(data.last_name)) {
    errors.last_name = 'Please input last name';
  }

  if (!data.is_agree) {
    errors.is_agree = 'Please agree to our terms and privacy policy';
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
