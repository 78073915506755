import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MDSpinner from 'react-md-spinner';

import Notification from '../../common/Notification';
import TextFieldGroupLogin from '../../common/TextFieldGroupLogin';
import CustomCheckbox from '../../common/CustomCheckbox';

import { connect } from 'react-redux';
import validateInput from '../../common/validations/login';
import { cleanCache, userLogin } from '../../../redux/actions/authActions';
import { BOOKING_DOMAIN } from '../../../utils/api';

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notification: {},
      errors: {},
      email: '',
      password: '',
      remember_me: false,
      type: 'student',
    };
  }

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, notification: {}, errors: {} });
    if (this.isValid()) {
      const dataToSubmit = {
        type: this.state.type,
        email: this.state.email,
        password: this.state.password,
        rememberMe: this.state.remember_me,
      };
      this.props.userLogin(dataToSubmit).then((res) => {
        if (res.status === 200) {
          this.setState({ isLoading: false });
          this.props.getStudentData();
          this.props.closeModal();
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: res.message,
              isError: true,
            },
          });
        }
      });
    } else {
      this.setState({
        isLoading: false,
        notification: {
          text: 'Please complete the form',
          isError: true,
        },
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeRememberMe = () => {
    this.setState({ remember_me: !this.state.remember_me });
  };

  render() {
    const { closeModal, isMobileScreen, selectedTutorName } = this.props;
    const {
      email,
      password,
      remember_me,
      isLoading,
      notification,
      errors,
      type,
    } = this.state;
    return (
      <div>
        <div
          className="modal modal-center fade show"
          id="modal-center"
          style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div
              className="modal-content"
              style={{ width: isMobileScreen ? '' : '429px' }}>
              <div className="text-right px-30 pt-20">
                <i
                  onClick={closeModal}
                  className="ion-close-round cursor-pointer"></i>
              </div>
              <div className="modal-body m-0 p-30">
                <h5 className="text-center fs-26 font-weight-bold mb-4">
                  Login to book your session
                </h5>
                {notification.text && (
                  <Notification notification={notification} />
                )}
                <form className="" onSubmit={this.onSubmit}>
                  <TextFieldGroupLogin
                    autoFocus={true}
                    error={errors.email}
                    label="Email"
                    onChange={this.onChange}
                    value={email}
                    field="email"
                  />
                  <TextFieldGroupLogin
                    error={errors.password}
                    label="Password"
                    onChange={this.onChange}
                    value={password}
                    field="password"
                    type="password"
                  />
                  <div className="form-group flexbox">
                    <label className=" px-1">
                      <CustomCheckbox
                        onChange={this.onChangeRememberMe}
                        className="mr-3"
                        checked={remember_me}
                      />
                      Keep me logged in
                    </label>
                    <Link
                      to={`/forget-password/${type}`}
                      disabled={isLoading}
                      className="primary-hover fs-13">
                      Forgot password?
                    </Link>
                  </div>
                  <div className="form-group mt-10">
                    <button
                      disabled={isLoading}
                      className="btn btn-block p-10 btn-main-pink fs-16 fw-500">
                      {isLoading ? <MDSpinner /> : 'Login'}
                    </button>
                  </div>

                  <div className="d-flex my-20 fs-12 justify-content-center">
                    <p className="fs-13">
                      Don`t have an account?
                      <a
                        className="cursor-pointer ml-2"
                        href={`${BOOKING_DOMAIN}/signup/student?ref=${selectedTutorName}`}>
                        Sign up
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { cleanCache, userLogin })(LoginModal);
