import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import CheckoutVoucher from './views/CheckoutVoucher';
import PaymentStatusVoucher from './views/PaymentStatusVoucher';

import { connect } from 'react-redux';
import { resetSearchData } from '../../redux/actions/bookActions';

class BuyPackagePage extends Component {
  componentWillUnmount = () => {
    this.props.resetSearchData();
  };

  render() {
    const { match, auth, history } = this.props;
    return (
      <div>
        <div
          className="mb-50 mt-0 py-40"
          style={{ backgroundColor: '#ffffff' }}>
          <Switch>
            {auth.user.type === 'tutor' ? (
              <Redirect to="/profile/appointments" />
            ) : (
              <>
                {/* <Route path={`${match.path}/booking-overview`} match={match} history={history} component={BookConfirmation} />
                <Route path={`${match.path}/detail`} match={match} history={history} component={TutorDetails} /> */}
                <Route
                  exact
                  path={`${match.path}/payment/:order_id`}
                  match={match}
                  history={history}
                  component={PaymentStatusVoucher}
                />
                <Route
                  exact
                  path={`${match.path}/checkout/:id`}
                  match={match}
                  history={history}
                  component={CheckoutVoucher}
                />
              </>
            )}
          </Switch>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(
  connect(mapStateToProps, { resetSearchData })(BuyPackagePage),
);
