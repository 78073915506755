import React from 'react';
import PrivateRoute from './routes/privateRoute';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import SignupPage from './components/signup/signupPage';
import LoginPage from './components/login/loginPage';
import Layout from './components/layout/Layout';
import ProfilePage from './components/profile/profilePage';
import AccountActivationPage from './components/account-activation/accountActivationPage';
import ForgetPasswordPage from './components/forget-password/forgetPasswordPage';
import ResetPasswordPage from './components/reset-password/resetPasswordPage';
import BookingPage from './components/booking/bookingPage';
import BuyPackagePage from './components/buy-package/BuyPackagePage';
import SetPasswordPage from './components/set-password/setPasswordPage';
import ResetTokenPage from './components/reset-token/resetTokenPage';
import RateTutor from './components/profile/views/RateTutor';
import GiftVoucherPage from './components/gift-voucher/GiftVoucherPage';

import { connect } from 'react-redux';
import BuyAndBookPage from './components/buy-and-book/BuyAndBookPage';

import 'App.css';

function App(props) {
  const { history, auth } = props;

  return (
    <Layout history={history}>
      <Switch>
        <Route path="/signup" component={SignupPage} />
        <Route path="/login" component={LoginPage} />
        <Route
          exact
          path="/activate-account"
          component={AccountActivationPage}
        />
        <Route
          exact
          path="/forget-password/:type"
          component={ForgetPasswordPage}
        />
        <Route exact path="/set-password/:type" component={SetPasswordPage} />
        <Route exact path="/reset-token/:type" component={ResetTokenPage} />
        <Route
          exact
          path="/reset-password/:type"
          component={ResetPasswordPage}
        />
        <Route exact path="/review-tutor/:id" component={RateTutor} />
        <Route path="/book-language-course" component={BookingPage} />
        <Route path="/checkout-and-book" component={BuyAndBookPage} />
        <Route path="/packages" component={BuyPackagePage} />
        <Route path="/gift-card" component={GiftVoucherPage} />
        <PrivateRoute path="/profile" component={ProfilePage} />
        {auth.user.type === 'tutor' ? (
          <Redirect to="/profile/appointments" />
        ) : (
          <Redirect to="/book-language-course" />
        )}
      </Switch>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default withRouter(connect(mapStateToProps, {})(App));
