import React, { Component } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';

import MDSpinner from 'react-md-spinner';
import Notification from '../../../common/Notification';
import SelectFieldGroup from '../../../common/SelectFieldGroup';
import SelectLanguageFieldGroup from '../../../common/SelectLanguageFieldGroup';
import TextAreaFieldGroup from '../../../common/TextAreaFieldGroup';
import TextFieldGroupLogin from '../../../common/TextFieldGroupLogin';
// import AsyncSelectFieldGroup from '../../../common/AsyncSelectFieldGroup';

import {
  // language_options,
  timezone_options,
} from '../../../common/SelectOptionsData';

import { connect } from 'react-redux';
import { validateProfile } from '../../../common/validations/studentProfile';
import {
  userGetSingleProfile,
  userUpdateProfileInfo,
  userUploadAvatar,
} from '../../../../redux/actions/studentActions';
import {
  userGetAllLanguages,
  userGetAllCountries,
  userGetAllWorldCountries,
  userGetCountryDetails,
} from '../../../../redux/actions/commonActions';

class StudentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfile: false,
      data: {
        // Personal Info
        first_name: '',
        last_name: '',
        date_of_birth: '',
        // gender: null,
        from_country: null,
        from_city: null,
        living_in_country: null,
        living_in_city: null,
        skype_id: '',
        timezone: null,
        // Languages Info
        native_language: null,
        bridge_language: [],
        learn_language: [],
        language_options: [],
        introduction: '',
      },
      country_options: [],
      city_options: [],
      living_in_city_options: [],
      isLoading: false,
      notification: {},
      notification_personal: {},
      errors: {},
      errors_image: '',
      editSuccess: false,
      isMobileScreen: false,
      isOpenUploadForm: false,
      imageFile: null,
      previewImg: '/assets/img/icon/male.svg',
      isLoading_image: false,
    };
  }

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
    this.getAllWorldCountries();
    this.setState({ isLoading: true, errors: {}, notification: {} });
    this.props
      .userGetSingleProfile({ email: this.props.auth.user.email })
      .then((res) => {
        if (res.status === 200) {
          const parse_bridge = (data) => {
            // const result = data.map(item => {
            //   return {
            //     ...item,
            //     error: null
            //   };
            // });
            // return result;
            return data;
          };

          const parse_learn = (data) => {
            // const result = data.map(item => {
            //   return {
            //     ...item,
            //     error: null
            //   };
            // });
            // return result;
            return data;
          };

          const bridge_language_default = [
            {
              language: null,
              language_level: null,
              error: null,
            },
          ];

          const learn_language_default = [
            {
              language: null,
              language_level: null,
              error: null,
            },
          ];
          const data = {
            ...res.message.data,
            date_of_birth: res.message.data.date_of_birth
              ? moment(res.message.data.date_of_birth, 'MM/DD/YYYY').format(
                  'DD-MM-YYYY',
                )
              : '',
            learn_language:
              res.message.data.learn_language.length === 0
                ? learn_language_default
                : parse_learn(res.message.data.learn_language),
            bridge_language:
              res.message.data.bridge_language.length === 0
                ? bridge_language_default
                : parse_bridge(res.message.data.bridge_language),
          };

          if (
            res.message.data.from_country &&
            !isEmpty(res.message.data.from_country)
          ) {
            this.props
              .userGetCountryDetails({
                country_code: res.message.data.from_country.value,
              })
              .then((res) => {
                if (res.status === 200) {
                  this.setState({
                    city_options: res.message.data[0].city_options,
                  });
                } else {
                  console.log('Failed to collect the city options.');
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.getAllWorldCountries();
          }

          if (
            res.message.data.living_in_country &&
            !isEmpty(res.message.data.living_in_country)
          ) {
            this.props
              .userGetCountryDetails({
                country_code: res.message.data.living_in_country.value,
              })
              .then((res) => {
                if (res.status === 200) {
                  this.setState({
                    living_in_city_options: res.message.data[0].city_options,
                  });
                } else {
                  console.log('Failed to collect the city options.');
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.getAllWorldCountries();
          }

          this.setState({
            isLoading: false,
            showProfile: true,
            data,
          });
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: 'Failed to load your profile data. Please try again later.',
              isError: true,
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          notification: {
            text: 'Failed to load your profile data. Please try again later.',
            isError: true,
          },
        });
      });
    
    this.props.userGetAllLanguages()
			.then((res) => {
				if (res.status === 200) {
					// SORT LANGUAGE OPTIONS
					const sorted_language_options = res.message.data.sort(
						(a, b) =>
							a.value > b.value ? 1 : b.value > a.value ? -1 : 0
					);
          this.setState({
            language_options: sorted_language_options,
          });
				}
			})
			.catch((err) => {
				console.log(err);
			});
  };

  getAllWorldCountries = () => {
    this.props
      .userGetAllWorldCountries()
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            country_options: res.message.data,
          });
          return res.message.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangePersonal = (e) => {
    const data = {
      ...this.state.data,
      [e.target.name]: e.target.value,
    };
    this.setState({
      data,
      errors: {
        ...this.state.errors,
        [e.target.name]: null,
      },
    });
  };

  onChangeDisplay = (e) => {
    const data = {
      ...this.state.data,
      [e.target.name]: e.target.value,
    };
    this.setState({
      data,
      errors: {
        ...this.state.errors,
        [e.target.name]: null,
      },
    });
  };

  onChangeDate = (date) => {
    const data = {
      ...this.state.data,
      date_of_birth: date,
    };
    this.setState({ data });
  };

  onChangeSelect = (e) => {
    const data = {
      ...this.state.data,
      [e.target.name]: e.target.value,
    };

    this.setState({
      data,
      errors: {
        ...this.state.errors,
        [e.target.name]: null,
      },
    });
  };

  onChangeSwitch = () => {
    const data = {
      ...this.state.data,
      closeAppointment: !this.state.data.closeAppointment,
    };
    this.setState({ data });
  };

  isValid = () => {
    const { errors, isValid } = validateProfile(this.state.data);
    if (!isValid) {
      this.setState({ errors: errors });
    }
    return isValid;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { data } = this.state;

    this.setState({ isLoading: true, notification_personal: {}, errors: {} });
    const dataToSubmit = {
      email: this.props.auth.user.email,
      type: this.props.auth.user.type,
      data: {
        first_name: data.first_name,
        last_name: data.last_name,
        date_of_birth: !isEmpty(data.date_of_birth)
          ? moment(data.date_of_birth, 'DD-MM-YYYY').format('MM/DD/YYYY')
          : null,
        skype_id: data.skype_id,
        timezone: data.timezone,
        from_country: data.from_country,
        from_city: data.from_city,
        living_in_country: data.living_in_country,
        living_in_city: data.living_in_city,
        native_language: data.native_language,
        bridge_language: data.bridge_language,
        learn_language: data.learn_language,
        introduction: data.introduction,
      },
    };

    if (this.isValid()) {
      this.props.userUpdateProfileInfo(dataToSubmit).then((res) => {
        if (res.status === 200) {
          this.setState({
            isLoading: false,
            data: {
              ...res.message.data,
              date_of_birth: moment(
                res.message.data.date_of_birth,
                'MM/DD/YYYY',
              ).format('DD-MM-YYYY'),
            },
            editSuccess: true,
          });
          setTimeout(() => {
            this.setState({ editSuccess: false });
          }, 2000);
        } else {
          this.setState({
            isLoading: false,
            notification_personal: {
              text: 'We are experiencing technical difficulties at the moment, please try again later',
              isError: true,
            },
          });
        }
      });
    } else {
      this.setState({
        isLoading: false,
        notification_personal: {
          text: 'Please complete the form above',
          isError: true,
        },
      });
    }
  };

  onChangeSelectCountry = (val) => {
    const { data, errors } = this.state;

    const selected_country = {
      value: val.value,
      label: val.label,
    };

    const city_options = val.city_options;

    this.setState({
      errors: {
        ...errors,
        from_country: null,
      },
      data: {
        ...data,
        from_country: selected_country,
        from_city:
          data.from_country &&
          data.from_country.value !== selected_country.value
            ? null
            : data.from_city,
      },
      city_options,
    });
  };

  onChangeSelectCountryLive = (val) => {
    const { data, errors } = this.state;
    const selected_country = {
      value: val.value,
      label: val.label,
    };
    const city_options = val.city_options;

    this.setState({
      errors: {
        ...errors,
        living_in_country: null,
      },
      data: {
        ...data,
        living_in_country: selected_country,
        living_in_city:
          data.living_in_country &&
          data.living_in_country.value !== selected_country.value
            ? null
            : data.living_in_city,
      },
      living_in_city_options: city_options,
    });
  };

  onChangeBridgeLanguage = (value, idx) => {
    const { data } = this.state;
    const new_data = data.bridge_language.map((item, sidx) => {
      if (idx !== sidx) {
        return item;
      } else {
        return {
          ...item,
          language: value,
        };
      }
    });

    this.setState({
      data: {
        ...data,
        bridge_language: new_data,
      },
    });
  };

  onChangeBridgeLanguageLevel = (value, idx) => {
    const { data } = this.state;
    const new_data = data.bridge_language.map((item, sidx) => {
      if (idx !== sidx) {
        return item;
      } else {
        return {
          ...item,
          language_level: value,
        };
      }
    });

    this.setState({
      data: {
        ...data,
        bridge_language: new_data,
      },
    });
  };

  addBridgeLanguageHandler = () => {
    const { data } = this.state;
    const added_data = {
      language: null,
      laguage_level: null,
    };

    this.setState({
      data: {
        ...data,
        bridge_language: data.bridge_language.concat([added_data]),
      },
    });
  };

  removeBridgeLanguageHandler = (idx) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        bridge_language: data.bridge_language.filter((s, sidx) => idx !== sidx),
      },
    });
  };

  onChangeLearnLanguage = (value, idx) => {
    const { data } = this.state;
    const new_data = data.learn_language.map((item, sidx) => {
      if (idx !== sidx) {
        return item;
      } else {
        return {
          ...item,
          language: value,
        };
      }
    });

    this.setState({
      data: {
        ...data,
        learn_language: new_data,
      },
    });
  };

  onChangeLearnLanguageLevel = (value, idx) => {
    const { data } = this.state;
    const new_data = data.learn_language.map((item, sidx) => {
      if (idx !== sidx) {
        return item;
      } else {
        return {
          ...item,
          language_level: value,
        };
      }
    });

    this.setState({
      data: {
        ...data,
        learn_language: new_data,
      },
    });
  };

  addLearnLanguageHandler = () => {
    const { data } = this.state;
    const added_data = {
      language: null,
      laguage_level: null,
    };

    this.setState({
      data: {
        ...data,
        learn_language: data.learn_language.concat([added_data]),
      },
    });
  };

  removeLearnLanguageHandler = (idx) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        learn_language: data.learn_language.filter((s, sidx) => idx !== sidx),
      },
    });
  };

  onChangeUploadImage = (e) => {
    this.setState({
      errors_image: '',
      imageFile: e.target.files[0],
      previewImg: URL.createObjectURL(e.target.files[0]),
    });
  };

  cancelUpload = () => {
    this.setState({
      imageFile: null,
      errors_image: '',
      previewImg: '/assets/img/icon/male.svg',
      isOpenUploadForm: false,
    });
  };

  isValidImage = (file) => {
    const maxSize = 2 * 1024 * 1024;
    const validSize = file.size <= maxSize;
    return validSize;
  };

  onSaveImageUpload = () => {
    this.setState({ errors_image: '', isLoading_image: true });
    const dataToSubmit = {
      file: this.state.imageFile,
      _id: this.props.auth.user.id,
    };
    if (this.isValidImage(this.state.imageFile)) {
      this.props
        .userUploadAvatar(dataToSubmit)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.setState({
              isLoading_image: false,
              previewImg: '',
              data: {
                ...this.state.data,
                avatar_display: res.avatar,
              },
              isOpenUploadForm: false,
            });
          } else {
            this.setState({
              isLoading_image: false,
              errors_image:
                'Failed to upload image. Please make sure the uploaded image matches with the above conditions',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading_image: false,
            errors_image: 'Internal server error. Please try again later',
          });
        });
    } else {
      this.setState({
        isLoading_image: false,
        errors_image: 'File is too large. Maximum size is 2 MB',
      });
    }
  };

  removeAvatar = () => {};

  render() {
    const {
      isMobileScreen,
      data,
      country_options,
      city_options,
      living_in_city_options,
      language_options,
      showProfile,
      isLoading,
      notification,
      notification_personal,
      errors,
      editSuccess,
      isOpenUploadForm,
      imageFile,
      previewImg,
      errors_image,
      isLoading_image,
    } = this.state;

    const render_bridge_language = data.bridge_language.map((item, idx) => {
      return (
        <div key={`bridge-${idx + 1}`} className="row m-auto">
          <div className="col-md-4">
            <SelectLanguageFieldGroup
              label="Bridge language"
              labelClass={!isMobileScreen && idx > 0 ? 'd-none' : ''}
              labelWeightNormal={true}
              value={item.language}
              options={language_options}
              onChange={(val) => this.onChangeBridgeLanguage(val, idx)}
              error={errors.language}
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={false}
            />
          </div>
          {/* <div className='col-md-4'>
            <SelectLanguageFieldGroup
              label='Language level'
              labelClass={!isMobileScreen && idx > 0 ? "d-none" : ''}
              labelWeightNormal={true}
              value={item.language_level}
              options={language_level_options}
              onChange={(val) => this.onChangeBridgeLanguageLevel(val, idx)}
              error={errors.language_level}
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={false}
            />
          </div> */}
          {idx > 0 ? (
            <div className={isMobileScreen ? 'col-md-4' : 'col-md-4 pl-0'}>
              <SelectLanguageFieldGroup
                label="Delete button"
                labelClass={!isMobileScreen && idx > 0 ? 'd-none' : 'd-none'}
                buttonClass={isMobileScreen ? 'm-auto' : ''}
                buttonText={isMobileScreen ? 'Remove' : ''}
                deleteButton={true}
                onClick={() => this.removeBridgeLanguageHandler(idx)}
              />
            </div>
          ) : null}
          {idx !== data.bridge_language.length - 1 && isMobileScreen ? (
            <div className="col-12">
              <hr
                className={idx === 0 ? 'my-20' : 'mt-0 mb-20'}
                style={{ borderTop: '1px solid #e1e1e1' }}
              />
            </div>
          ) : null}
        </div>
      );
    });

    const render_learn_language = data.learn_language.map((item, idx) => {
      return (
        <div
          key={`bridge-${idx + 1}`}
          className={idx === 0 ? 'row m-auto mt-30' : 'row m-auto'}>
          <div className="col-md-4">
            <SelectLanguageFieldGroup
              label="Language you want to learn"
              labelClass={!isMobileScreen && idx > 0 ? 'd-none' : ''}
              labelWeightNormal={true}
              value={item.language}
              options={language_options}
              onChange={(val) => this.onChangeLearnLanguage(val, idx)}
              error={errors.language}
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={false}
            />
          </div>
          {/* <div className='col-md-4'>
            <SelectLanguageFieldGroup
              label='Language level'
              labelClass={!isMobileScreen && idx > 0 ? "d-none" : ''}
              labelWeightNormal={true}
              value={item.language_level}
              options={language_level_options}
              onChange={(val) => this.onChangeLearnLanguageLevel(val, idx)}
              error={errors.language_level}
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={false}
            />
          </div> */}
          {idx > 0 ? (
            <div className={isMobileScreen ? 'col-md-4' : 'col-md-4 pl-0'}>
              <SelectLanguageFieldGroup
                label="Delete button"
                labelClass={!isMobileScreen && idx > 0 ? 'd-none' : 'd-none'}
                buttonClass={isMobileScreen ? 'm-auto' : ''}
                buttonText={isMobileScreen ? 'Remove' : ''}
                deleteButton={true}
                onClick={() => this.removeLearnLanguageHandler(idx)}
              />
            </div>
          ) : null}
          {idx !== data.bridge_language.length - 1 && isMobileScreen ? (
            <div className="col-12">
              <hr
                className={idx === 0 ? 'my-20' : 'mt-0 mb-20'}
                style={{ borderTop: '1px solid #e1e1e1' }}
              />
            </div>
          ) : null}
        </div>
      );
    });

    const avatarImg = isEmpty(data.avatar_display)
      ? previewImg
      : isOpenUploadForm
      ? previewImg
      : data.avatar_display;

    return (
      <div className="col-md-8">
        <div className="card">
          <div
            className={
              isMobileScreen
                ? 'card-body px-20 pt-20 pb-0'
                : 'card-body px-40 pt-40 pb-0'
            }>
            <h3 className="fw-600 fs-20 p-20">Personal Information</h3>
            <div className="row mx-auto mb-40 px-20">
              <div className="col-md-3 px-20 d-flex justify-content-center align-items-center">
                <div
                  style={{
                    backgroundImage: `url("${avatarImg}")`,
                    backgroundPosition: 'top',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '50%',
                    boxShadow: '0px 2px 15px #e7e7e7',
                    height: 150,
                    width: 150,
                    margin: isMobileScreen ? 'auto' : 0,
                  }}></div>
              </div>
              <div
                className={
                  isMobileScreen ? 'col-md-9 mt-30' : 'col-md-9 pl-40'
                }>
                <h5>Edit Profile Photo</h5>
                <ul className="pl-20" style={{ color: '#6B6B6B' }}>
                  <li>At least 500 x 500 pixels</li>
                  <li>JPG, PNG, BMP formats only</li>
                  <li>Maximum size of 2 MB</li>
                </ul>
                {isOpenUploadForm ? (
                  <form encType="multipart/form-data">
                    <div className="input-group form-type-line file-group">
                      <input
                        type="text"
                        name="avatar"
                        style={{
                          borderBottom: errors_image ? '1px solid red' : '',
                        }}
                        className="form-control file-value"
                        placeholder="Choose file..."
                        readOnly
                      />
                      <input
                        type="file"
                        multiple={true}
                        ref="avatar"
                        name="avatar"
                        onChange={this.onChangeUploadImage}
                      />
                      {!imageFile ? (
                        <span className="input-group-append">
                          <button
                            style={{ borderRadius: 4 }}
                            className="btn btn-main-pink file-browser"
                            type="button">
                            <i className="fa fa-upload mr-2"></i>
                            Choose file
                          </button>
                          <button
                            onClick={this.cancelUpload}
                            style={{ borderRadius: 4 }}
                            className="btn border-0 button-transparent text-dark"
                            type="button">
                            <i className="ion-close-round f mr-2"></i>
                          </button>
                        </span>
                      ) : null}
                      {isLoading_image ? (
                        <MDSpinner />
                      ) : imageFile ? (
                        <span className="input-group-append">
                          <button
                            onClick={this.cancelUpload}
                            style={{ borderRadius: 4 }}
                            className="btn button-transparent mr-2"
                            type="button">
                            Cancel
                          </button>
                          <button
                            onClick={this.onSaveImageUpload}
                            style={{ borderRadius: 4 }}
                            className="btn btn-main-pink"
                            type="button">
                            Save
                          </button>
                        </span>
                      ) : null}
                    </div>
                  </form>
                ) : (
                  <div>
                    <button
                      onClick={() => this.setState({ isOpenUploadForm: true })}
                      className="btn button-transparent btn-w-xl d-block"
                      style={{
                        borderWidth: 2,
                        margin: isMobileScreen ? 'auto' : '0',
                      }}>
                      Upload photo
                    </button>
                  </div>
                )}
                {errors_image && (
                  <div className="form-control-feedback">{errors_image}</div>
                )}
              </div>
            </div>
            <Notification notification={notification} />
            {showProfile ? (
              <>
                <div className="row m-auto">
                  <div className="col-md-6">
                    <TextFieldGroupLogin
                      label="First Name"
                      field="first_name"
                      value={data.first_name}
                      placeholder="Type something"
                      onChange={this.onChangePersonal}
                      disabled={isLoading}
                      error={errors.first_name}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextFieldGroupLogin
                      label="Last Name"
                      field="last_name"
                      value={data.last_name}
                      placeholder="Type something"
                      onChange={this.onChangePersonal}
                      disabled={isLoading}
                      error={errors.last_name}
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectFieldGroup
                      label="Country of Origin"
                      placeholder="Select..."
                      value={data.from_country}
                      options={country_options}
                      onChange={this.onChangeSelectCountry}
                      error={errors.from_country}
                      isDisabled={isLoading}
                      isClearable={false}
                      isSearchable={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectFieldGroup
                      label="City"
                      value={data.from_city}
                      options={city_options}
                      onChange={(val) =>
                        this.onChangeSelect({
                          target: { name: 'from_city', value: val },
                        })
                      }
                      error={errors.from_city}
                      isClearable={false}
                      isDisabled={isLoading || isEmpty(data.from_country)}
                      isSearchable={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectFieldGroup
                      label="Current country"
                      placeholder="Select..."
                      value={data.living_in_country}
                      options={country_options}
                      onChange={this.onChangeSelectCountryLive}
                      error={errors.living_in_country}
                      isDisabled={isLoading}
                      isClearable={false}
                      isSearchable={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectFieldGroup
                      label="City"
                      value={data.living_in_city}
                      options={living_in_city_options}
                      onChange={(val) =>
                        this.onChangeSelect({
                          target: { name: 'living_in_city', value: val },
                        })
                      }
                      error={errors.living_in_city}
                      isClearable={false}
                      isDisabled={isLoading || isEmpty(data.living_in_country)}
                      isSearchable={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectFieldGroup
                      label="Time zone"
                      value={data.timezone}
                      options={timezone_options}
                      onChange={(val) =>
                        this.onChangeSelect({
                          target: { name: 'timezone', value: val },
                        })
                      }
                      isClearable={false}
                      isDisabled={isLoading}
                      isSearchable={true}
                      error={errors.timezone}
                    />
                  </div>
                </div>
                <div className="mx-15">
                  <hr className="my-20" />
                </div>
                <div className="">
                  <h3 className="fw-600 fs-20 px-15 py-20">Languages</h3>
                  <div className="row m-auto">
                    <div className="col-md-4">
                      <SelectFieldGroup
                        label="Native language"
                        labelWeightNormal={true}
                        value={data.native_language}
                        options={language_options}
                        onChange={(val) =>
                          this.onChangeSelect({
                            target: { name: 'native_language', value: val },
                          })
                        }
                        error={errors.native_language}
                        isClearable={false}
                        isDisabled={isLoading}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                  {isMobileScreen ? (
                    <div className="row m-auto">
                      <div className="col-12">
                        <hr
                          className="my-20"
                          style={{ borderTop: '1px solid #e1e1e1' }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {data.bridge_language.length > 0 ? (
                    render_bridge_language
                  ) : (
                    <div className="row m-auto">
                      <div className="col-md-4">
                        <SelectFieldGroup
                          label="Bridge language"
                          labelWeightNormal={true}
                          value={data.bridge_language}
                          options={language_options}
                          onChange={(val) =>
                            this.onChangeBridgeLanguage(val, 1)
                          }
                          error={errors.bridge_language}
                          isClearable={false}
                          isDisabled={isLoading}
                          isSearchable={false}
                        />
                      </div>
                      {/* <div className='col-md-4'>
                      <SelectFieldGroup
                        label='Language level'
                        labelWeightNormal={true}
                        value={data.laguage_level}
                        options={language_level_options}
                        onChange={(val) => this.onChangeBridgeLanguageLevel(val, 1)}
                        error={errors.laguage_level}
                        isClearable={false}
                        isDisabled={isLoading}
                        isSearchable={false}
                      />
                    </div> */}
                    </div>
                  )}
                  {data.bridge_language.length <= 2 ? (
                    <button
                      onClick={this.addBridgeLanguageHandler}
                      className="btn button-transparent border-transparent m-auto align-items-center px-15"
                      style={{
                        display: isMobileScreen ? 'flex' : 'inline-flex',
                      }}>
                      <i className="ion-plus-round text-info fs-14 mr-1 cursor-pointer"></i>
                      <label className="fs-14 mb-0 text-info cursor-pointer">
                        Add more
                      </label>
                    </button>
                  ) : null}
                  {isMobileScreen ? (
                    <div className="row m-auto">
                      <div className="col-12">
                        <hr
                          className="my-20"
                          style={{ borderTop: '1px solid #e1e1e1' }}
                        />
                      </div>
                    </div>
                  ) : null}

                  {/* LEARN LANGUAGE */}
                  {data.learn_language.length > 0 ? (
                    render_learn_language
                  ) : (
                    <div className="row mx-auto mt-30">
                      <div className="col-md-4">
                        <SelectFieldGroup
                          label="Language you want to learn"
                          labelWeightNormal={true}
                          value={data.learn_language}
                          options={language_options}
                          onChange={(val) => this.onChangeLearnLanguage(val, 1)}
                          error={errors.learn_language}
                          isClearable={false}
                          isDisabled={isLoading}
                          isSearchable={false}
                        />
                      </div>
                      {/* <div className='col-md-4'>
                      <SelectFieldGroup
                        label='Language level'
                        labelWeightNormal={true}
                        value={data.laguage_level}
                        options={language_level_options}
                        onChange={(val) => this.onChangeLearnLanguageLevel(val, 1)}
                        error={errors.laguage_level}
                        isClearable={false}
                        isDisabled={isLoading}
                        isSearchable={false}
                      />
                    </div> */}
                    </div>
                  )}
                  {data.learn_language.length <= 2 ? (
                    <button
                      onClick={this.addLearnLanguageHandler}
                      className="btn button-transparent border-transparent m-auto align-items-center px-15"
                      style={{
                        display: isMobileScreen ? 'flex' : 'inline-flex',
                      }}>
                      <i className="ion-plus-round text-info fs-14 mr-1 cursor-pointer"></i>
                      <label className="fs-14 mb-0 text-info cursor-pointer">
                        Add more
                      </label>
                    </button>
                  ) : null}
                  <div className="px-15">
                    <hr className="my-20" />
                  </div>
                  <div className="px-15">
                    <TextAreaFieldGroup
                      label="Introduction"
                      field="introduction"
                      value={data.introduction}
                      placeholder="Type something"
                      onChange={this.onChangeDisplay}
                      disabled={isLoading}
                      error={errors.introduction}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="px-40 mx-15 mt-20">
            <Notification notification={notification_personal} />
          </div>
          <div
            className={
              isMobileScreen
                ? 'd-flex align-items-center flex-column px-40 bg-white border-0 pt-20 pb-40'
                : 'd-flex align-items-center justify-content-between px-60 bg-white border-0 py-40'
            }>
            <div
              style={{ visibility: editSuccess ? 'visible' : 'hidden' }}
              className={isMobileScreen ? 'mb-20' : ''}>
              <label className="fs-13 mb-0 text-success d-flex align-items-center mt-0">
                <span>Changes saved successfully</span>
                <i className="fa fa-check-circle-o fs-20 mx-2"></i>
              </label>
            </div>
            {isLoading ? (
              <MDSpinner />
            ) : (
              <button
                onClick={this.onSubmit}
                className="btn btn-main-pink letter-spacing-0">
                Save changes
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  userGetSingleProfile,
  userUpdateProfileInfo,
  userGetAllLanguages,
  userGetAllCountries,
  userUploadAvatar,
  userGetAllWorldCountries,
  userGetCountryDetails,
})(StudentProfile);
