import React from 'react';
import moment from 'moment';
import Notification from '../../common/Notification';
import MDSpinner from 'react-md-spinner';

function CancelBookingOverview(props) {
  const { data, onCancelBooking, onCloseModal, isLoading, notification } =
    props;

  return (
    <div>
      <div
        className="modal modal-center fade show"
        id="modal-center"
        style={{ display: 'block', paddingRight: 15 }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Booking Cancellation</h5>
              <button
                onClick={onCloseModal}
                type="button"
                className="close cursor-pointer">
                <span className="cursor-pointer" aria-hidden="true">
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body" style={{ overflowY: 'auto!important' }}>
              <Notification notification={notification} />
              <p className="mb-2">
                Are you sure want to cancel this appointment ?
              </p>
              <ol className="pl-20 fs-14">
                <li className="mb-1">
                  Session ID :{' '}
                  <strong className="font-weight-bold">
                    {data.booking_id}
                  </strong>
                </li>
                <li className="mb-1">
                  Session date :{' '}
                  <strong className="font-weight-bold">
                    {moment(data.appointment_date, 'MM/DD/YYYY').format(
                      'MMM DD, YYYY',
                    )}
                  </strong>
                </li>
                <li className="mb-1">
                  Tutor name :{' '}
                  <strong className="font-weight-bold">
                    {data.tutor_id.first_name} {data.tutor_id.last_name}
                  </strong>
                </li>
                <li className="mb-1">
                  Number of credits :{' '}
                  <strong className="font-weight-bold">
                    {data.book_price}
                  </strong>{' '}
                  flowently credit
                </li>
              </ol>
              <p className="mb-10 font-italic fs-12 pl-1 mt-20">
                <span>
                  <i className="ion-ios-information-outline mr-2 fs-16"></i>
                </span>
                Credit will be restored to your account after cancellation
              </p>
            </div>
            <div className="modal-footer px-20 py-15">
              {isLoading ? (
                <MDSpinner />
              ) : (
                <>
                  <button
                    onClick={onCloseModal}
                    type="button"
                    className="btn button-transparent">
                    Back
                  </button>
                  <button
                    onClick={onCancelBooking}
                    type="button"
                    className="btn btn-main-pink">
                    Cancel booking
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade show"
        style={{ opacity: '0.8!important' }}></div>
    </div>
  );
}

export default CancelBookingOverview;
