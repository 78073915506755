import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const TextFieldGroupLogin = ({
  field,
  value,
  id,
  label,
  error,
  type,
  onChange,
  disabled,
  icon,
  placeholder,
  nonBoldLabel,
  labelAddStyle,
  readOnly,
  alreadyHaveAccountButton,
  loginLink,
  loadingRender,
}) => {
  return (
    <div
      className={classnames('form-group', { 'has-error has-danger': error })}>
      {alreadyHaveAccountButton ? (
        <div className="d-flex align-items-center justify-content-between">
          <label
            style={labelAddStyle}
            className={
              nonBoldLabel
                ? 'col-form-label letter-spacing-0 fs-15 pl-12'
                : 'col-form-label font-weight-bold letter-spacing-0 fs-15 pl-12'
            }>
            {label}
          </label>
          <Link
            to={loginLink}
            style={{ color: '#999999' }}
            className="col-form-label letter-spacing-0 fs-13 pl-12">
            Already have an account ?
          </Link>
        </div>
      ) : (
        <label
          style={labelAddStyle}
          className={
            nonBoldLabel
              ? 'col-form-label letter-spacing-0 fs-15 pl-12'
              : 'col-form-label font-weight-bold letter-spacing-0 fs-15 pl-12'
          }>
          {label}
        </label>
      )}
      {loadingRender ? (
        <div>
          <div
            className="loading-form-wrapper form-control px-20 py-0"
            style={{ borderRadius: '25px', height: '42px!important' }}>
            <div className="running-background"></div>
          </div>
        </div>
      ) : (
        <div className="px-15 py-0">
          <p className="mb-0">{value ? value : '-'}</p>
          {error && <div className="form-control-feedback">{error}</div>}
        </div>
      )}
      {icon && <div className={icon}></div>}
    </div>
  );
};

export default TextFieldGroupLogin;
