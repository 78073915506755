import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';
import TagManager from 'react-gtm-module';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  userCheckBookPaymentStatus,
  userUpdatePurchaseGtmTracked,
} from '../../../redux/actions/singleSessionActions';
import { userUpdateBookingGtmTrackedStatus } from '../../../redux/actions/appointmentActions';

class CheckoutBookingPaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notification: {},
      payment_status: '',
      checkout_url: '',
      booking_id: '',
    };
  }

  componentDidMount = () => {
    console.log('did mount');
    window.scrollTo(0, 0);
    const { params } = this.props.match;
    this.setState({
      order_id: params.order_id,
    });

    this.getTransactionDetails(params.order_id);
  };

  initializeTransactionGTM = (purchase_data, package_id) => {
    const tax = parseFloat((parseFloat(purchase_data.amount.value) / 121) * 21);
    const tagManagerArgs = {
      gtmId: 'GTM-NG4WRF7',
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          transaction_id: purchase_data.order_id,
          value: purchase_data.amount.value,
          tax: tax.toString(),
          shipping: 0,
          currency: "EUR",
          items: [{
            item_id: package_id,
            item_name: purchase_data.description,
            item_category: 'package',
            price: purchase_data.amount.value,
            quantity: 1,
          }],
          currencyCode: 'EUR',
          purchase: {
            actionField: {
              id: purchase_data.order_id,
              revenue: purchase_data.amount.value,
              tax: tax.toString(),
              shipping: 0,
            },
            products: [
              {
                id: package_id,
                name: purchase_data.description,
                category: 'package',
                price: purchase_data.amount.value,
                quantity: 1,
              },
            ],
          },
        },
      },
    };
    TagManager.initialize(tagManagerArgs);

    this.props
      .userUpdatePurchaseGtmTracked({ order_id: purchase_data.order_id })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    return true;
  };

  initializeAppointmentGTM = (booking_id) => {
    const tagManagerArgs = {
      gtmId: 'GTM-NG4WRF7',
      dataLayer: {
        event: 'appointment',
      },
    };

    TagManager.initialize(tagManagerArgs);

    this.props
      .userUpdateBookingGtmTrackedStatus({ booking_id })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  getTransactionDetails = (order_id) => {
    this.setState({ isLoading: true, notification: null });
    this.props
      .userCheckBookPaymentStatus({ order_id })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          const { payment_status, checkout_url, gtmTracked } = res.message.data;

          if (payment_status === 'paid' && !gtmTracked) {
            this.initializeTransactionGTM(
              res.message.data,
              res.message.package_id,
            );
          }

          if (res.message.booking_id) {
            this.initializeAppointmentGTM(res.message.booking_id);
          }

          this.setState({
            isLoading: false,
            payment_status,
            checkout_url,
          });
        } else {
          this.setState({
            isLoading: false,
            notification: {
              isError: true,
              text: res.message,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          notification: {
            isError: true,
            text: 'Network connection error. Please check your connection and try again.',
          },
        });
      });
  };

  render() {
    const { isLoading, payment_status, checkout_url } = this.state;

    return (
      <div className="mx-20">
        {isLoading ? (
          <div className="card py-150" style={{ borderRadius: 8 }}>
            <div className="text-center">
              <MDSpinner />
              <p className="mb-0 mt-4 fs-14">Please wait...</p>
              <p className="mb-0 fs-14">Checking your payment status</p>
            </div>
          </div>
        ) : (
          <div className="card py-150" style={{ borderRadius: 8 }}>
            <div className="text-center mb-10">
              {payment_status === 'paid' ? (
                <img
                  src="/assets/img/icon/success.svg"
                  className="h-50px"
                  alt="success"
                />
              ) : null}
            </div>
            {payment_status === 'paid' ? (
              <h1 className="mb-0 text-center fs-25">
                You have booked your session!
              </h1>
            ) : payment_status === 'open' ? (
              <h1 className="mb-0 text-center fs-25">
                Your order has been received - Please complete your payment.
              </h1>
            ) : (
              <h1 className="mb-0 text-center fs-25">
                Your payment is {payment_status}
              </h1>
            )}
            {payment_status === 'paid' ? (
              <div className="text-center">
                <p className="mb-0">
                  Your tutor will contact you directly by phone or email to
                  discuss details of your session (exact time, place and topic).
                </p>
                <p className="mb-0">
                  Click on the button below to view your appointments in your
                  account or send your tutor a message.
                </p>
              </div>
            ) : payment_status === 'expired' ? (
              <div className="text-center">
                <p className="mb-0">You need to book again.</p>
              </div>
            ) : null}
            <div className="text-center mt-30">
              {payment_status === 'open' ? (
                <a
                  href={checkout_url}
                  className="btn btn-main-pink text-white letter-spacing-0">
                  Continue payment
                </a>
              ) : payment_status === 'paid' ? (
                <Link to="/profile/appointments">
                  <button className="btn btn-main-pink">See appointment</button>
                </Link>
              ) : (
                <Link to="/packages">
                  <button className="btn btn-main-pink">Buy package</button>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, {
  userCheckBookPaymentStatus,
  userUpdatePurchaseGtmTracked,
  userUpdateBookingGtmTrackedStatus,
})(CheckoutBookingPaymentStatus);
