import React, { Component } from 'react';
import { BOOKING_DOMAIN, DOMAIN } from '../../../utils/api';

class BottomTabMenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrollingUp: false,
    };
  }

  componentDidMount() {
    this.prev = window.scrollY;
    window.addEventListener('scroll', (e) => this.handleNavigation(e));
  }

  handleNavigation = (e) => {
    const window = e.currentTarget;

    if (this.prev > window.scrollY) {
      this.setState({ isScrollingUp: true });
    } else if (this.prev < window.scrollY) {
      this.setState({ isScrollingUp: false });
    }
    this.prev = window.scrollY;
  };

  render() {
    const { isScrollingUp } = this.state;
    const { pathname } = this.props.history.location;
    // if(isScrollingUp) {
    //   return null;
    // } else {
    return (
      <div>
        <div
          id="BottomTabMenuContainer"
          className={`${isScrollingUp ? 'hide-menu' : 'show-menu'}`}>
          <ul
            id="BottomTabNavigation"
            className="d-flex justify-content-between mb-0 px-40">
            <li className="menu-link">
              <a
                href={`${BOOKING_DOMAIN}/profile`}
                className={`d-flex flex-column ${
                  pathname.includes('/profile') ? 'active' : ''
                }`}>
                <i className="fa fa-user-circle-o fs-20 mb-1 text-center"></i>
                <p className="mb-0">Profile</p>
              </a>
            </li>
            <li className="menu-link">
              <a
                href={`${BOOKING_DOMAIN}/book-language-course`}
                className={`d-flex flex-column ${
                  pathname.includes('/book-language-course') ? 'active' : ''
                }`}>
                <i className="fa fa-search fs-20 mb-1 text-center"></i>
                <p className="mb-0">Find a tutor</p>
              </a>
            </li>
            <li className="menu-link">
              <a
                href={`${DOMAIN}/contact`}
                className={`d-flex flex-column ${
                  pathname === '/contact' ? 'active' : ''
                }`}>
                <i className="fa fa-envelope fs-20 mb-1 text-center"></i>
                <p className="mb-0">Contact</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
    // }
  }
}

export default BottomTabMenuBar;
