import React, { Component } from 'react';
// import queryString from 'query-string';
import MDSpinner from 'react-md-spinner';
import { isEmpty } from 'lodash';

import LoginModal from '../../booking/views/LoginModal';
import Notification from '../../common/Notification';
import CustomCheckbox from '../../common/CustomCheckbox';
import CustomRadioButton from '../../common/CustomRadioButton';
import TextFieldGroupLogin from '../../common/TextFieldGroupLogin';
import TextOutputFieldGroup from '../../common/TextOutputFieldGroup';
// import AsyncSelectFieldGroup from '../../common/AsyncSelectFieldGroup';
// import UnderConstructionModal from '../../common/UnderConstructionModal';

import { connect } from 'react-redux';
import { userGetSingleProfile } from '../../../redux/actions/studentActions';
import { userGetSingleVoucher } from '../../../redux/actions/voucherActions';
import {
  userGetAllCountries,
  userGetAllWorldCountries,
  userGetCountryDetails,
} from '../../../redux/actions/commonActions';
import {
  userCreateNewVoucherPayment,
  userGetPaymentMethodsList,
} from '../../../redux/actions/paymentActions';
import {
  validateInputReceiverInfo,
  validatePayment,
} from '../../common/validations/checkoutPayment';
import { DOMAIN } from '../../../utils/api';

class CheckoutVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileScreen: false,
      isLoading_payment: false,
      payment_notification: {},
      isLoading_voucher: false,
      voucher_notification: {},
      isLoading_checkout: false,
      checkout_notification: {},
      selected_voucher: {},
      payment_errors: {},
      isOpenLoginModal: false,
      country_options: [],
      city_options: [],
      // --- Sender Data --- //
      _id: '',
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      isLoading_sender: false,
      sender_notification: {},
      error_sender: {},
      // --- Receiver Data --- //
      receiver_id: '',
      receiver_email: '',
      receiver_first_name: '',
      receiver_last_name: '',
      receiver_phone: '',
      isLoading_receiver: false,
      receiver_notification: {},
      error_receiver: {},
      // --- Payment Method --- //
      selected_payment: '',
      payment_methods_list: [],
      terms_and_conditions_checked: false,
      subscribe_checked: false,
      // isCheckoutModalOpen: false,
      paymentInProgress: false,
      checkout_url: '',
    };
  }

  componentWillMount = () => {
    const voucher_id = this.props.match.params.id;

    if (voucher_id && !isEmpty(voucher_id)) {
      this.getSingleVoucher(voucher_id);
      // Get single student data
      if (this.props.auth.isAuthenticated) {
        this.getSingleStudent();
      }

      // Get payment methods list
      this.getPaymentMethodsList();
      this.getAllWorldCountries();
    } else {
      // this.props.history.push('/book-language-course');
      console.log('redirected');
    }
  };

  getSingleVoucher = (voucher_id) => {
    this.setState({ isLoading_voucher: true, voucher_notification: {} });
    this.props
      .userGetSingleVoucher({ voucher_id })
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            isLoading_voucher: false,
            voucher_notification: {
              text: 'Internal server error. Please try again later',
              isError: true,
            },
          });
          // this.props.history.push('/book-language-course');
          console.log('redirected');
        } else {
          this.setState({
            isLoading_voucher: false,
            selected_voucher: res.message.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading_voucher: false,
          voucher_notification: {
            text: 'We are experiencing technical difficulties while load your selected voucher data, please try again later.',
            isError: true,
          },
        });
      });
  };

  // get sender account info
  getSingleStudent = () => {
    this.setState({
      isLoading_sender: true,
      sender_notification: {},
      error_sender: {},
    });
    this.props
      .userGetSingleProfile({ email: this.props.auth.user.email })
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            isLoading_sender: false,
            sender_notification: {
              text: 'Internal server error. Please try again later',
              isError: true,
            },
          });
        } else {
          const { _id, email, first_name, last_name, phone } = res.message.data;

          // if(res.message.data.living_in_country && !isEmpty(res.message.data.living_in_country)) {
          //   this.props.userGetCountryDetails({ country_code: res.message.data.living_in_country.value }).then(res => {
          //     // console.log(res.message.data[0].city_options)
          //     if(res.status === 200) {
          //       this.setState({
          //         city_options : res.message.data[0].city_options
          //       });
          //     } else {
          //       console.log('Failed to collect the city options.')
          //     }
          //   }).catch(err => {
          //     console.log(err);
          //   });
          // } else {
          //   this.getAllWorldCountries();
          // };

          this.setState({
            isLoading_sender: false,
            _id,
            email,
            first_name,
            last_name,
            phone,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading_sender: false,
          sender_notification: {
            text: 'We are experiencing technical difficulties while load your sender data, please try again later.',
            isError: true,
          },
        });
      });
  };

  getPaymentMethodsList = () => {
    this.setState({ isLoading_payment: true, payment_notification: {} });
    this.props
      .userGetPaymentMethodsList()
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            isLoading_payment: false,
            payment_notification: {
              text: 'Internal server error. Please try again later',
              isError: true,
            },
          });
        } else {
          this.setState({
            isLoading_payment: false,
            payment_methods_list: res.message.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading_payment: false,
          payment_notification: {
            text: 'We are experiencing technical difficulties while load payment methods, please try again later.',
            isError: true,
          },
        });
      });
  };

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
  };

  onChangeReceiverDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      receiver_notification: {},
      error_receiver: {
        ...this.state.error_receiver,
        [e.target.name]: null,
      },
    });
  };

  openLoginModalHandler = () => {
    this.setState({ isOpenLoginModal: true });
  };

  closeLoginModalHandler = () => {
    this.setState({ isOpenLoginModal: false });
  };

  loadOptionsCountry = (inputValue, callback) => {
    this.props
      .userGetAllCountries({ query: inputValue })
      .then((response) => {
        callback(response.message.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  onChangeSelectCountry = (val) => {
    const { living_in_country, living_in_city, error_sender } = this.state;

    const selected_country = {
      value: val.value,
      label: val.label,
    };

    const city_options = val.city_options;

    this.setState({
      error_sender: {
        ...error_sender,
        living_in_country: null,
      },
      living_in_country: selected_country,
      living_in_city:
        living_in_country && living_in_country.value !== selected_country.value
          ? null
          : living_in_city,
      city_options,
    });
  };

  onChangeSelectedPayment = (method_id) => {
    this.setState({
      selected_payment: method_id,
      payment_errors: {
        ...this.state.payment_errors,
        selected_payment: null,
      },
      checkout_notification: {},
    });
  };

  onChangeRadioButton = (e) => {
    if (e.name === 'terms_and_conditions_checked') {
      this.setState({
        terms_and_conditions_checked: !this.state.terms_and_conditions_checked,
        payment_errors: {
          ...this.state.payment_errors,
          terms_and_conditions_checked: null,
        },
        checkout_notification: {},
      });
    } else {
      this.setState({
        subscribe_checked: !this.state.subscribe_checked,
      });
    }
  };

  isValidReceiverInfo = () => {
    const { errors, isValid } = validateInputReceiverInfo(this.state);
    if (!isValid) {
      this.setState({ error_receiver: errors });
    }
    return isValid;
  };

  isValidPayment = () => {
    const { errors, isValid } = validatePayment(this.state);
    if (!isValid) {
      this.setState({ payment_errors: errors });
    }
    return isValid;
  };

  getAllWorldCountries = () => {
    this.props
      .userGetAllWorldCountries()
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            country_options: res.message.data,
          });
          return res.message.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onCheckout = () => {
    this.setState({ isLoading_checkout: true, checkout_notification: {} });
    // Check personal info form
    if (this.isValidReceiverInfo()) {
      // Check payment form
      if (this.isValidPayment() && !isEmpty(this.state.selected_voucher)) {
        const { title, sale_price } = this.state.selected_voucher;
        const {
          selected_payment,
          receiver_email,
          receiver_first_name,
          receiver_last_name,
          receiver_phone,
          _id,
          email,
        } = this.state;

        const receiver_data = {
          email: receiver_email,
          first_name: receiver_first_name,
          last_name: receiver_last_name,
          display_name: `${receiver_first_name} ${receiver_last_name}`,
          phone: receiver_phone,
        };

        const sender_data = {
          _id,
          email,
        };

        if (!isEmpty(this.state.receiver_id)) {
          receiver_data['receiver_id'] = this.state.receiver_id;
        }

        const dataToSubmit = {
          voucher_id: this.state.selected_voucher.voucher_id,
          voucher_title: title,
          voucher_price: sale_price,
          payment_method: selected_payment,
          sender_data,
          receiver_data,
        };

        this.props
          .userCreateNewVoucherPayment(dataToSubmit)
          .then((res) => {
            if (res.status !== 200) {
              this.setState({
                isLoading_checkout: false,
                checkout_notification: {
                  text: res.message,
                  isError: true,
                },
              });
            } else {
              this.setState({
                isLoading: true,
                paymentInProgress: true,
                checkout_url: res.message.data.payment._links.checkout.href,
              });
              this.props.history.push(
                `/gift-card/payment/${res.message.data.payment.metadata.order_id}`,
                {
                  payment_method: res.message.data.payment.method,
                  voucher_title:
                    res.message.data.payment.metadata.voucher_title,
                  payment_status: res.message.data.payment.status,
                  checkout_url: res.message.data.payment._links.checkout.href,
                },
              );
              window.open(
                res.message.data.payment._links.checkout.href,
                '_blank',
              );
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              isLoading_checkout: false,
              checkout_notification: {
                text: 'Internal server error. Please try again later',
                isError: true,
              },
            });
          });
      } else {
        this.setState({
          isLoading_checkout: false,
          checkout_notification: {
            text: 'Please complete the payment form below',
            isError: true,
          },
        });
      }
    } else {
      this.setState({
        isLoading_checkout: false,
        receiver_notification: {
          text: 'Please complete the form below',
          isError: true,
        },
      });
    }
  };

  render() {
    const {
      isMobileScreen,
      isOpenLoginModal,
      // isCheckoutModalOpen,
      // --- Voucher Details --- //
      selected_voucher,
      isLoading_voucher,
      // --- Sender Details --- //
      email,
      first_name,
      last_name,
      phone,
      error_sender,
      isLoading_sender,
      // --- Receiver Details --- //
      receiver_email,
      receiver_first_name,
      receiver_last_name,
      receiver_phone,
      isLoading_receiver,
      receiver_notification,
      error_receiver,
      // --- Payment Methods --- //
      selected_payment,
      isLoading_payment,
      payment_notification,
      payment_methods_list,
      payment_errors,
      paymentInProgress,
      checkout_url,
      terms_and_conditions_checked,
      subscribe_checked,
      isLoading_checkout,
      checkout_notification,
    } = this.state;

    const { auth } = this.props;

    let render_benefit;
    if (!isEmpty(selected_voucher)) {
      render_benefit = selected_voucher.benefit_list.map((item, idx) => (
        <div
          key={`benefit-${idx + 1}`}
          className={
            isMobileScreen ? 'd-flex mb-2' : 'd-flex align-items-center mb-2'
          }>
          <i
            className={
              isMobileScreen
                ? 'fa fa-check-circle-o mr-2 mt-1'
                : 'fa fa-check-circle-o mr-2'
            }
            style={{ color: '#2acf7f' }}></i>
          <h6 className="fs-16 text-capitalize mb-0">{item.text}</h6>
        </div>
      ));
    } else {
      render_benefit = (
        <h6 className="fs-18 text-capitalize">No voucher Selected</h6>
      );
    }

    let render_payment_methods;
    if (!isEmpty(payment_methods_list)) {
      render_payment_methods = payment_methods_list.map((method, idx) => {
        return (
          <div
            key={`method-${idx + 1}`}
            style={{ width: '95%' }}
            className="pt-30 pb-20 border-bottom mx-auto">
            <label
              className={
                isMobileScreen
                  ? 'd-flex align-items-center px-20'
                  : 'd-flex align-items-center px-25'
              }>
              <CustomCheckbox
                error={
                  !isEmpty(payment_errors) &&
                  !isEmpty(payment_errors.selected_payment)
                }
                onChange={() => this.onChangeSelectedPayment(method.id)}
                className="mr-2"
                checked={selected_payment === method.id}
              />
              <h6 className="mb-0 fs-16 mr-3">{method.description}</h6>
              <img src={method.image.svg} alt={method.id} />
            </label>
          </div>
        );
      });
    } else {
      render_payment_methods = (
        <div className={isMobileScreen ? 'px-20' : 'px-40'}>
          <Notification notification={payment_notification} />
        </div>
      );
    }

    return (
      <div id="package-wrapper" className={isMobileScreen ? 'px-0' : 'px-0'}>
        <div className="row mx-auto">
          <div className="col-md-7">
            <div
              className={
                isMobileScreen
                  ? 'card card-body p-20'
                  : 'card card-body py-20 px-60'
              }>
              {/* GIFT SENDER INFORMATION CARD */}
              <h1 className="fs-25 mt-20 mb-0 text-black font-weight-bold">
                Gift sender information
              </h1>
              <hr
                className="my-20 mx-auto border-top"
                style={{ width: '100%' }}
              />
              {isLoading_sender ? (
                <div className="d-flex justify-content-center flex-column my-40">
                  <div className="text-center">
                    <MDSpinner />
                  </div>
                  <div className="text-center mt-3">
                    <small>
                      Getting your personal information. Please wait...
                    </small>
                  </div>
                </div>
              ) : (
                <>
                  {auth.isAuthenticated ? (
                    <div className="row">
                      <div className="col-md-6">
                        <TextOutputFieldGroup
                          label="First name"
                          error={error_sender.first_name}
                          value={first_name}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextOutputFieldGroup
                          label="Last name"
                          error={error_sender.last_name}
                          value={last_name}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextOutputFieldGroup
                          label="Email"
                          error={error_sender.email}
                          value={email}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextOutputFieldGroup
                          label="Phone"
                          error={error_sender.phone}
                          value={phone}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center mt-2 mb-0">
                      <p className="mb-0 fs-16 mr-2">
                        Please sign in to continue your order
                      </p>
                      <button
                        onClick={this.openLoginModalHandler}
                        className="btn btn-main-pink border-0 px-20 letter-spacing-0 fs-16">
                        Sign in here
                      </button>
                    </div>
                  )}
                </>
              )}
              {/* GIFT RECEIVER INFORMATION CARD */}
              <h1 className="fs-25 mt-20 mb-0 text-black font-weight-bold">
                Gift recipient information
              </h1>
              <hr
                className="my-20 mx-auto border-top"
                style={{ width: '100%' }}
              />
              {auth.isAuthenticated ? (
                <div className="">
                  <Notification notification={receiver_notification} />
                  <div className="row">
                    <div className="col-md-6">
                      <TextFieldGroupLogin
                        label="First name *"
                        field="receiver_first_name"
                        value={receiver_first_name}
                        placeholder="Type something"
                        onChange={this.onChangeReceiverDetails}
                        disabled={isLoading_receiver || isLoading_checkout}
                        error={error_receiver.receiver_first_name}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextFieldGroupLogin
                        label="Last name *"
                        field="receiver_last_name"
                        value={receiver_last_name}
                        placeholder="Type something"
                        onChange={this.onChangeReceiverDetails}
                        disabled={isLoading_receiver || isLoading_checkout}
                        error={error_receiver.receiver_last_name}
                      />
                    </div>
                  </div>
                  <TextFieldGroupLogin
                    label="Email *"
                    field="receiver_email"
                    value={receiver_email}
                    placeholder="Type something"
                    onChange={this.onChangeReceiverDetails}
                    disabled={isLoading_receiver || isLoading_checkout}
                    error={error_receiver.receiver_email}
                  />
                  <TextFieldGroupLogin
                    label="Phone Number *"
                    field="receiver_phone"
                    value={receiver_phone}
                    placeholder="Type something"
                    onChange={this.onChangeReceiverDetails}
                    disabled={isLoading_receiver || isLoading_checkout}
                    error={error_receiver.receiver_phone}
                  />
                </div>
              ) : (
                <div className="mb-30">
                  <p className="mb-0 fs-14">
                    You need to sign in to send a gift voucher. Click the sign
                    in button above to sign in.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-5">
            {/* PACKAGE OVERVIEW CARD */}
            <div className="card card-body">
              <h1 className="fs-25 mt-10 mb-0 text-center text-black font-weight-bold">
                Your order
              </h1>
              <hr
                className="my-20 mx-auto border-top"
                style={{ width: '95%' }}
              />
              {isLoading_voucher ? (
                <div className="d-flex justify-content-center flex-column mx-auto my-40">
                  <div className="text-center">
                    <MDSpinner />
                  </div>
                  <div className="text-center mt-3">
                    <small>Getting voucher information. Please wait...</small>
                  </div>
                </div>
              ) : (
                <>
                  <div className={isMobileScreen ? 'px-10' : 'px-40'}>
                    <h6 className="fs-18 mb-20 text-capitalize">
                      {selected_voucher.credit_value} hours voucher
                    </h6>
                    <div className="mb-10">{render_benefit}</div>
                  </div>
                  <hr
                    className="my-20 mx-auto border-top"
                    style={{ width: '95%' }}
                  />
                  <div
                    className={
                      isMobileScreen
                        ? 'd-flex justify-content-between align-items-center px-10 mt-0 mb-20'
                        : 'd-flex justify-content-between align-items-center px-40 mt-0 mb-20'
                    }>
                    <h6 className="fs-16 mb-0">Price</h6>
                    <h6 className="fs-16 mb-0">
                      &euro; {selected_voucher.sale_price}
                    </h6>
                  </div>
                </>
              )}
            </div>
            {/* PAYMENT CARD */}
            <div className="card card-body pt-20">
              <h1 className="fs-25 mt-0 mb-0 text-center text-black font-weight-bold">
                Payment
              </h1>
              {isLoading_payment ? (
                <div className="d-flex justify-content-center flex-column my-40">
                  <div className="text-center">
                    <MDSpinner />
                  </div>
                  <div className="text-center mt-3">
                    <small className="fs-14">
                      Getting available payment methods. Please wait...
                    </small>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={isMobileScreen ? 'px-20 mt-20' : 'px-30 mt-20'}>
                    <Notification notification={checkout_notification} />
                  </div>
                  <div
                    className={checkout_notification ? 'mt-0 mb-10' : 'my-10'}>
                    {render_payment_methods}
                  </div>
                  {/* Payment form validation error message */}
                  {!isEmpty(payment_errors) &&
                    !isEmpty(payment_errors.selected_payment) && (
                      <div
                        className="form-control-feedback mx-0"
                        style={{
                          padding: isMobileScreen ? '0 20px' : '0 36px',
                        }}>
                        {payment_errors.selected_payment}
                      </div>
                    )}
                  {isEmpty(payment_notification) ? (
                    <div
                      className="my-20"
                      style={{ padding: isMobileScreen ? '0 20px' : '0 36px' }}>
                      <div className="mb-20">
                        <label
                          className={
                            isMobileScreen
                              ? 'd-flex'
                              : 'd-flex align-items-center px-1'
                          }>
                          <CustomRadioButton
                            error={
                              !isEmpty(payment_errors) &&
                              !isEmpty(
                                payment_errors.terms_and_conditions_checked,
                              )
                            }
                            onChange={() =>
                              this.onChangeRadioButton({
                                name: 'terms_and_conditions_checked',
                              })
                            }
                            className={isMobileScreen ? 'mt-1 mr-2' : 'mr-2'}
                            checked={terms_and_conditions_checked}
                            disabled={isLoading_checkout}
                          />
                          <div className="d-flex align-items-center">
                            <p
                              className="mb-0 fs-16 letter-spacing-0"
                              style={{ lineHeight: '1.5' }}>
                              I have read and agree to the website
                              <a
                                href={`${DOMAIN}/terms-and-conditions`}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="ml-1 text-black text-bold">
                                terms and conditions*
                              </a>
                            </p>
                          </div>
                        </label>
                        {/* Payment form validation error message */}
                        {!isEmpty(payment_errors) &&
                          !isEmpty(
                            payment_errors.terms_and_conditions_checked,
                          ) && (
                            <div className="form-control-feedback mx-0 pl-24">
                              {payment_errors.terms_and_conditions_checked}
                            </div>
                          )}
                      </div>
                      <div className="">
                        <label
                          className={
                            isMobileScreen
                              ? 'd-flex'
                              : 'd-flex align-items-center px-1'
                          }>
                          <CustomRadioButton
                            onChange={() =>
                              this.onChangeRadioButton({
                                name: 'subscribe_checked',
                              })
                            }
                            className={isMobileScreen ? 'mt-1 mr-2' : 'mr-2'}
                            checked={subscribe_checked}
                            disabled={isLoading_checkout}
                          />
                          <p
                            className="mb-0 fs-16 letter-spacing-0"
                            style={{ lineHeight: '1.5' }}>
                            Send me promos and product updates
                          </p>
                        </label>
                      </div>
                      <div className="form-group mt-40 mb-0">
                        {paymentInProgress && !isEmpty(checkout_url) ? (
                          <a
                            href={checkout_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-block p-10 btn-main-pink fs-18 fw-500">
                            Continue payment
                          </a>
                        ) : (
                          <button
                            onClick={this.onCheckout}
                            disabled={isLoading_checkout}
                            className="btn btn-block p-10 btn-main-pink fs-18 fw-500">
                            {isLoading_checkout ? <MDSpinner /> : 'Place order'}
                          </button>
                        )}
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
        {isOpenLoginModal ? (
          <LoginModal
            isMobileScreen={isMobileScreen}
            closeModal={this.closeLoginModalHandler}
            getStudentData={this.getSingleStudent}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  userGetSingleProfile,
  userGetAllCountries,
  userGetAllWorldCountries,
  userGetCountryDetails,
  userGetSingleVoucher,
  userGetPaymentMethodsList,
  userCreateNewVoucherPayment,
})(CheckoutVoucher);
