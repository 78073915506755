import React, { Component } from 'react';
import QS from 'query-string';
import MDSpinner from 'react-md-spinner';

/*Import common component*/
import validateInput from '../common/validations/resetPassword';
import TextFieldGroupLogin from '../common/TextFieldGroupLogin';
import Notification from '../common/Notification';

/*Import redux*/
import { connect } from 'react-redux';
import { userActivateAccount } from '../../redux/actions/authActions';

class accountActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      token: '',
      repeat_new_password: '',
      new_password: '',
      type: '',
      errors: {},
      isLoading: false,
      notification: {},
      signup_referrer: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    let queries = QS.parse(this.props.history.location.search);
    if (queries && queries.token) this.setState({ token: queries.token });
    if (queries && queries.email) this.setState({ email: queries.email });
    if (queries && queries.signup)
      this.setState({ signup_referrer: queries.signup });
    if (queries && queries.type) this.setState({ type: queries.type });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ notification: {}, errors: {}, isLoading: true });
    if (this.isValid()) {
      const dataToSubmit = {
        email: this.state.email,
        token: this.state.token,
        new_password: this.state.new_password,
        signup_referrer: this.state.signup_referrer,
        type: this.state.type,
      };

      this.props
        .userActivateAccount(dataToSubmit)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ isLoading: false });
            this.props.history.push(`/login/${this.state.type}`);
          } else if (response.status === 401) {
            this.setState({
              isLoading: false,
              notification: {
                text: response.message,
                isError: true,
                path: `/reset-token/${this.state.type}?to=${this.state.email}`,
                email: this.state.email,
                type: this.state.type,
              },
            });
          } else {
            this.setState({
              isLoading: false,
              notification: {
                text: response.message,
                isError: true,
              },
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            notification: {
              text: 'Internal Server Error, please try again later',
              isError: true,
            },
          });
        });
    }
  }

  render() {
    const {
      errors,
      email,
      repeat_new_password,
      new_password,
      isLoading,
      notification,
    } = this.state;

    return (
      <div className="card card-round card-shadowed px-50 py-30 w-400px mb-0">
        <img
          className="logo-login"
          src="/assets/img/logo.png"
          alt="logo icon"
        />
        <h5 className="text-center">Create your account password</h5>
        <div className="form-group">
          <Notification notification={notification} />
        </div>
        <form onSubmit={this.onSubmit}>
          <TextFieldGroupLogin
            error={errors.email}
            label="Email"
            onChange={this.onChange}
            value={email}
            field="identifier"
            disabled={true}
          />
          <TextFieldGroupLogin
            error={errors.new_password}
            label="Password"
            onChange={this.onChange}
            value={new_password}
            field="new_password"
            type="password"
          />
          <TextFieldGroupLogin
            error={errors.repeat_new_password}
            label="Repeat Password"
            onChange={this.onChange}
            value={repeat_new_password}
            field="repeat_new_password"
            type="password"
          />
          <div className="form-group mt-30">
            <button
              disabled={isLoading}
              className="btn btn-round btn-block btn-main-pink py-2">
              {isLoading ? <MDSpinner /> : <span>Set password</span>}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(null, { userActivateAccount })(accountActivation);
