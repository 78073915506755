import Validator from 'validator';

export default function validateInput(data) {
  let errors = {};
  let isValid = true;

  if (!data.email) {
    errors.email = 'Email is required';
    isValid = false;
  }
  if (!Validator.isEmail(data.email)) {
    errors.email = 'Email is not valid';
    isValid = false;
  }
  return {
    errors,
    isValid,
  };
}
