import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import MDSpinner from 'react-md-spinner';
import { detect } from 'detect-browser';

import DownloadErrorModal from './DownloadErrorModal';
import TransactionDetailsModal from './TransactionDetailsModal';
import Notification from '../../../common/Notification';
import SelectFieldGroup from '../../../common/SelectFieldGroup';
import TextFieldGroupLogin from '../../../common/TextFieldGroupLogin';
// import AsyncSelectFieldGroup from '../../../common/AsyncSelectFieldGroup';

import { connect } from 'react-redux';
import {
  validateAddressDetails,
  validateInputLoginDetails,
} from '../../../common/validations/studentProfile';
import {
  userChangePassword,
  userGetSingleProfile,
  userUpdateProfileInfo,
} from '../../../../redux/actions/studentActions';
import {
  userDownloadReceipt,
  userDownloadVoucherReceipt,
  userGetAllCountries,
  userGetAllWorldCountries,
  userGetCountryDetails,
} from '../../../../redux/actions/commonActions';
import { BOOKING_DOMAIN } from '../../../../utils/api';

const browser = detect();
class StudentAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileScreen: false,
      data: {
        credit_balance: 0,
        credit_expired: '',
        transactions_history: [],
        voucher_transactions: [],
        // Login Details
        email: '',
        current_password: '',
        new_password: '',
        confirm_password: '',
        // Address Details
        living_in_country: '',
        living_in_city: '',
        postcode: '',
        street_name: '',
        house_number: '',
        phone: '',
      },
      modal_title: '',
      selected_transaction: {},
      living_in_country_options: [],
      living_in_city_options: [],
      isLoading: false,
      notification: {},
      showAccountDetails: false,
      isLoading_login_details: false,
      isLoading_address_details: false,
      isLoading_download: [],
      isLoading_download_voucher: [],
      notification_login_details: {},
      notification_address_details: {},
      errors_login_details: {},
      errors_address_details: {},
      isEditLoginSuccess: false,
      isEditAddressSuccess: false,
      isDownloadError: false,
      isOpenTransactionDetailModal: false,
    };
  }

  componentWillMount = () => {
    this.getAllWorldCountries();
    this.setState({ isLoading: true, notification: {} });
    this.props
      .userGetSingleProfile({ email: this.props.auth.user.email })
      .then((res) => {
        if (res.status === 200) {
          const {
            transactions_history,
            voucher_transactions,
            credit_expired,
            living_in_country,
          } = res.message.data;

          const isLoadingDownload = transactions_history.map(() => {
            return false;
          });

          const isLoadingDownloadVoucher = voucher_transactions.map(() => {
            return false;
          });

          const sorted_transactions = transactions_history.sort(function (
            a,
            b,
          ) {
            return new Date(b.created_date) - new Date(a.created_date);
          });

          const data = {
            ...res.message.data,
            current_password: '',
            new_password: '',
            confirm_password: '',
            transactions_history: sorted_transactions,
            credit_expired: credit_expired
              ? moment(credit_expired, 'MM/DD/YYYY').format('MMMM Do, YYYY')
              : '',
          };

          if (living_in_country && !isEmpty(living_in_country)) {
            this.props
              .userGetCountryDetails({ country_code: living_in_country.value })
              .then((resp) => {
                if (resp.status === 200) {
                  this.setState({
                    living_in_city_options: resp.message.data[0].city_options,
                  });
                } else {
                  console.log('Failed to collect the city options.');
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.getAllWorldCountries();
          }

          this.setState({
            isLoading: false,
            showAccountDetails: true,
            data,
            isLoading_download: isLoadingDownload,
            isLoading_download_voucher: isLoadingDownloadVoucher,
          });
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: 'Failed to load your profile data. Please try again later.',
              isError: true,
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          notification: {
            text: 'Failed to load your profile data. Please try again later.',
            isError: true,
          },
        });
      });
  };

  getAllWorldCountries = () => {
    this.props
      .userGetAllWorldCountries()
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            living_in_country_options: res.message.data,
          });
          return res.message.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
  };

  onChangeLoginDetails = (e) => {
    this.setState({
      errors_login_details: {
        ...this.state.errors_login_details,
        [e.target.name]: null,
      },
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeAddressDetails = (e) => {
    this.setState({
      errors_address_details: {
        ...this.state.errors_address_details,
        [e.target.name]: null,
      },
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeContactDetails = (e) => {
    this.setState({
      errors_ec_details: {
        ...this.state.errors_ec_details,
        [e.target.name]: null,
      },
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  isValid = (form_type) => {
    if (form_type === 'login_details') {
      const { errors, isValid } = validateInputLoginDetails(this.state.data);
      if (!isValid) {
        this.setState({ errors_login_details: errors });
      }
      return {
        value: isValid,
        form_type,
      };
    }

    if (form_type === 'address_details') {
      const { errors, isValid } = validateAddressDetails(this.state.data);
      if (!isValid) {
        this.setState({ errors_address_details: errors });
      }
      return {
        value: isValid,
        form_type,
      };
    }
  };

  onSubmitLoginDetails = (e) => {
    e.preventDefault();
    const { data } = this.state;

    this.setState({
      isLoading_login_details: true,
      notification_login_details: {},
      errors_login_details: {},
    });
    const data_changed = {
      current_password: data.current_password,
      new_password: data.new_password,
      confirm_password: data.confirm_password,
    };

    const dataToSubmit = {
      email: this.props.auth.user.email,
      type: this.props.auth.user.type,
      data: data_changed,
    };

    if (this.isValid('login_details').value) {
      this.props
        .userChangePassword(dataToSubmit)
        .then((res) => {
          if (res.status === 200) {
            const data = {
              ...res.message.data,
              current_bank_number: res.message.data.bank_number,
              new_bank_number: res.message.data.bank_number,
              current_password: '',
              new_password: '',
              confirm_password: '',
            };
            this.setState({
              isEditLoginSuccess: true,
              data,
            });
            setTimeout(() => {
              this.setState({
                isLoading_login_details: false,
                isEditLoginSuccess: false,
              });
            }, 3000);
          } else if (res.status === 401) {
            this.setState({
              isLoading_login_details: false,
              errors_login_details: {
                current_password: 'Your old password is does not match',
              },
              notification_login_details: {
                text: 'Failed to update your password. Please insert your old password correctly',
                isError: true,
              },
              data,
            });
          } else if (res.status === 400) {
            this.setState({
              isLoading_login_details: false,
              errors_login_details: {
                new_password: 'New password must be different with the old one',
              },
              notification_login_details: {
                text: 'Failed to update your password. Please insert different new password',
                isError: true,
              },
              data,
            });
          } else {
            this.setState({
              isLoading_login_details: false,
              notification_login_details: {
                text: 'Internal server error. Please try again later',
                isError: true,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading_login_details: false,
            notification_login_details: {
              text: 'We are experiencing technical difficulties at the moment, please try again later',
              isError: true,
            },
          });
        });
    } else {
      this.setState({
        isLoading_login_details: false,
        notification_login_details: {
          text: 'Please complete the form',
          isError: true,
        },
      });
    }
  };

  onSubmit = (e, form_type) => {
    e.preventDefault();
    let data_changed;
    const { data } = this.state;

    if (form_type === 'address_details') {
      data_changed = {
        living_in_country: data.living_in_country,
        living_in_city: data.living_in_city,
        postcode: data.postcode,
        street_name: data.street_name,
        house_number: data.house_number,
        phone: data.phone,
      };
      this.setState({
        isLoading_address_details: true,
        notification_address_details: {},
        errors_address_details: {},
      });
    }

    if (this.isValid(form_type).value) {
      const dataToSubmit = {
        email: this.props.auth.user.email,
        type: this.props.auth.user.type,
        data: data_changed,
        form_type,
      };

      // Redux action
      this.props
        .userUpdateProfileInfo(dataToSubmit)
        .then((res) => {
          const data = {
            ...res.message.data,
            current_bank_number: res.message.data.bank_number,
            new_bank_number: res.message.data.bank_number,
            current_password: '',
            new_password: '',
            confirm_password: '',
          };
          if (form_type === 'address_details') {
            this.setState({
              isEditAddressSuccess: true,
              data,
            });
            setTimeout(() => {
              this.setState({
                isLoading_address_details: false,
                isEditAddressSuccess: false,
              });
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          if (form_type === 'address_details') {
            this.setState({
              isLoading_address_details: false,
              notification_address_details: {
                text: 'We are experiencing technical difficulties at the moment, please try again later',
                isError: true,
              },
            });
          }
        });
    } else {
      if (form_type === 'address_details') {
        this.setState({
          isLoading_address_details: false,
          notification_address_details: {
            text: 'Please complete the form',
            isError: true,
          },
        });
      }
    }
  };

  loadOptionsCountry = (inputValue, callback) => {
    this.props
      .userGetAllCountries({ query: inputValue })
      .then((response) => {
        callback(response.message.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  onChangeSelectCountry = (val, form_type) => {
    const { data, errors_address_details } = this.state;
    let selected_living_in_country, living_in_city_options;

    if (form_type === 'address_details') {
      selected_living_in_country = {
        value: val.value,
        label: val.label,
      };

      living_in_city_options = val.city_options;

      this.setState({
        errors_address_details: {
          ...errors_address_details,
          living_in_country: null,
        },
        data: {
          ...data,
          living_in_country: selected_living_in_country,
          living_in_city: null,
        },
        living_in_city_options,
      });
    }
  };

  onChangeSelect = (e) => {
    const data = {
      ...this.state.data,
      [e.target.name]: e.target.value,
    };

    this.setState({
      data,
      errors_address_details: {
        ...this.state.errors_address_details,
        [e.target.name]: null,
      },
    });
  };

  downloadVoucherReceiptHandler = (order_id, idx) => {
    let { isLoading_download_voucher } = this.state;
    isLoading_download_voucher[idx] = true;

    this.setState({ isLoading_download_voucher });
    this.props
      .userDownloadVoucherReceipt({ order_id })
      .then((res) => {
        if (res.status !== 200) {
          isLoading_download_voucher[idx] = false;
          this.setState({ isDownloadError: true, isLoading_download_voucher });
        } else {
          // redirect to receipt url
          window.open(res.message.receipt_url, '_blank');

          isLoading_download_voucher[idx] = false;
          this.setState({ isLoading_download_voucher });
        }
      })
      .catch((err) => {
        console.log(err);
        isLoading_download_voucher[idx] = false;
        this.setState({ isLoading_download_voucher, isDownloadError: true });
      });
  };

  downloadReceiptHandler = (order_id, idx) => {
    let { isLoading_download } = this.state;
    isLoading_download[idx] = true;

    this.setState({ isLoading_download });
    this.props
      .userDownloadReceipt({ order_id })
      .then((res) => {
        if (res.status !== 200) {
          isLoading_download[idx] = false;
          this.setState({ isDownloadError: true, isLoading_download });
        } else {
          if (browser.name === 'safari') {
            console.log('open disable popup blocker modal');
            const { receipt_url } = res.message;
            window.open(receipt_url, '_blank');
          } else {
            // redirect to receipt url
            const { receipt_url } = res.message;
            window.open(receipt_url, '_blank');
          }

          isLoading_download[idx] = false;
          this.setState({ isLoading_download });
        }
      })
      .catch((err) => {
        console.log(err);
        isLoading_download[idx] = false;
        this.setState({ isLoading_download, isDownloadError: true });
      });
  };

  transactionDetailsModalHandler = (transaction, modal_title) => {
    this.setState({
      selected_transaction: transaction,
      modal_title,
      isOpenTransactionDetailModal: true,
    });
  };

  closeTransactionModalHandler = () => {
    this.setState({
      selected_transaction: {},
      modal_title: '',
      isOpenTransactionDetailModal: false,
    });
  };

  render() {
    const {
      data,
      isMobileScreen,
      isLoading,
      isLoading_login_details,
      isLoading_address_details,
      isLoading_download,
      isLoading_download_voucher,
      notification_login_details,
      notification_address_details,
      errors_login_details,
      errors_address_details,
      isEditLoginSuccess,
      isEditAddressSuccess,
      living_in_city_options,
      living_in_country_options,
      isDownloadError,
      isOpenTransactionDetailModal,
      modal_title,
      selected_transaction,
    } = this.state;

    const render_transactions = data.transactions_history.map((item, idx) => {
      return (
        <tr key={`transaction-${idx + 1}`} id="appointment-table-row">
          <td className={isMobileScreen ? '' : 'pl-30'}>{item.order_id}</td>
          {!isMobileScreen ? (
            <td>{`${item.description
              .charAt(0)
              .toUpperCase()}${item.description.slice(1)}`}</td>
          ) : null}
          {!isMobileScreen ? <td>&euro; {item.amount.value}</td> : null}
          <td className="text-center">{`${item.payment_status
            .charAt(0)
            .toUpperCase()}${item.payment_status.slice(1)}`}</td>
          {item.payment_status === 'open' ? (
            <td className="">
              <a
                href={`${BOOKING_DOMAIN}/packages/payment/${item.order_id}`}
                className="btn btn-warning btn-round px-10 py-0 letter-spacing-0 fs-14"
                style={{
                  minWidth: '85px',
                }}>
                Pay now
              </a>
            </td>
          ) : (
            <td className="text-center">
              {isLoading_download[idx] ? (
                <div className="text-center">
                  <MDSpinner className="h-20px w-20px" />
                </div>
              ) : (
                <button
                  className="btn btn-primary btn-round px-10 py-0 letter-spacing-0 fs-14"
                  disabled={item.payment_status !== 'paid'}
                  onClick={() =>
                    this.downloadReceiptHandler(item.order_id, idx)
                  }>
                  <i className="fa fa-download mr-1 fs-11"></i>
                  Receipt
                </button>
              )}
            </td>
          )}
          <td className="text-center">
            <button
              className="btn btn-info btn-round px-10 py-0 letter-spacing-0 fs-14"
              onClick={() =>
                this.transactionDetailsModalHandler(item, 'Package')
              }>
              Details
            </button>
          </td>
        </tr>
      );
    });

    const render_voucher_transactions = data.voucher_transactions.map(
      (item, idx) => {
        return (
          <tr key={`transaction-${idx + 1}`} id="appointment-table-row">
            <td className={isMobileScreen ? '' : 'pl-30'}>{item.order_id}</td>
            {!isMobileScreen ? (
              <td>{`${item.description
                .charAt(0)
                .toUpperCase()}${item.description.slice(1)}`}</td>
            ) : null}
            {!isMobileScreen ? <td>{`${item.receiver_id.email}`}</td> : null}
            {!isMobileScreen ? <td>&euro; {item.amount.value}</td> : null}
            <td className="text-center">{`${item.payment_status
              .charAt(0)
              .toUpperCase()}${item.payment_status.slice(1)}`}</td>
            <td className="">
              {isLoading_download_voucher[idx] ? (
                <MDSpinner className="h-20px w-20px" />
              ) : (
                <button
                  className="btn btn-primary btn-round px-10 py-0 letter-spacing-0 fs-14"
                  disabled={item.payment_status !== 'paid'}
                  onClick={() =>
                    this.downloadVoucherReceiptHandler(item.order_id, idx)
                  }>
                  <i className="fa fa-download mr-1 fs-11"></i>
                  Receipt
                </button>
              )}
            </td>
            <td className="text-center">
              <button
                className="btn btn-info btn-round px-10 py-0 letter-spacing-0 fs-14"
                onClick={() =>
                  this.transactionDetailsModalHandler(item, 'Gift card')
                }>
                Details
              </button>
            </td>
          </tr>
        );
      },
    );

    let tableStyle = {};
    if (data.transactions_history.length > 4) {
      tableStyle = {
        overflowY: 'scroll',
        maxHeight: 316,
      };
    }

    const todayDate = moment(new Date());
    const creditExpiryDate = moment(data.credit_expired, 'MMMM Do YYYY');
    const isCreditExpired = moment(todayDate).isAfter(creditExpiryDate);

    return (
      <div id="account-details" className="col-md-8">
        {/* CREDIT DETAIL */}
        {isLoading ? (
          <div
            style={{ height: '185px' }}
            className="card bg-white d-flex justify-content-center align-items-center">
            <MDSpinner />
          </div>
        ) : (
          <div className="card">
            <div className="card-body p-30">
              <div className="row align-items-center m-auto credit-card">
                <div className="col-md-4">
                  <div className="d-flex align-items-center credit-wrapper">
                    <img
                      className="h-25px mr-2"
                      src="/assets/img/icon/flowently-credit.svg"
                      alt=""
                    />
                    <h3 className="fw-400 m-0 fs-16">Flowently Credit</h3>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <p className="fw-600 fs-40 mb-0">
                    {data.credit_balance.toFixed(2)}
                  </p>
                  <p className="lh-1 fs-13 mb-0">Total Credit</p>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                  <Link to="/packages">
                    <i className="ion-plus-round text-info fs-14 mr-2 cursor-pointer"></i>
                    <label className="fs-14 mb-0 text-info cursor-pointer">
                      Add Credits
                    </label>
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-footer text-center px-30 py-10">
              {isCreditExpired ? (
                <p className="mb-0 text-danger fs-12">
                  Your credit has expired.
                </p>
              ) : (
                <p className="mb-0 fs-13 text-black font-weight-bold">
                  Expiry date: {data.credit_expired}
                </p>
              )}
            </div>
            {/* <div className='card-footer bg-white text-dark text-center p-30'>
              <p className='mb-0 fs-14'>{data.credit_balance > 0 ? `This credit is valid until ${data.credit_expired}` : 'Top up your credits for create an appointment'}</p>
            </div> */}
          </div>
        )}

        {/* TRANSACTIONS HISTORY */}
        {isLoading ? null : data.transactions_history.length > 0 ? (
          <div className="card px-0">
            <div className={isMobileScreen ? 'px-20 py-40' : 'px-40 py-40'}>
              <h2 className="mb-0 fs-20">Transactions History</h2>
            </div>
            <hr
              className="my-0 border-top"
              style={{
                borderTopColor: '#e2e2e2!important',
                borderTopWidth: '2px!important',
              }}
            />
            <div
              style={tableStyle}
              className={isMobileScreen ? 'p-20' : 'px-40 py-20'}>
              <table id="appointment-table" className="table bg-white">
                <thead>
                  <tr>
                    <th
                      className={
                        isMobileScreen ? 'fw-600 fs-15' : 'fw-600 fs-15 pl-24'
                      }
                      style={{ width: isMobileScreen ? '30%' : '15%' }}>
                      Order ID
                    </th>
                    {!isMobileScreen ? (
                      <th className="fw-600 fs-15" style={{ width: '30%' }}>
                        Package
                      </th>
                    ) : null}
                    {!isMobileScreen ? (
                      <th className="fw-600 fs-15" style={{ width: '10%' }}>
                        Amount
                      </th>
                    ) : null}
                    <th
                      className="fw-600 fs-15 text-center"
                      style={{ width: '10%' }}>
                      Status
                    </th>
                    <th
                      className="fw-600 fs-15 text-center"
                      style={{ width: '10%' }}>
                      Receipt
                    </th>
                    <th className="fw-600 fs-15" style={{ width: '10%' }}></th>
                  </tr>
                </thead>
                <tbody>{render_transactions}</tbody>
              </table>
            </div>
          </div>
        ) : null}

        {/* VOUCHER TRANSACTIONS HISTORY */}
        {isLoading ? null : data.voucher_transactions.length > 0 ? (
          <div className="card px-0">
            <div className={isMobileScreen ? 'px-20 py-40' : 'px-40 py-40'}>
              <h2 className="mb-0 fs-20">Gift card transactions history</h2>
            </div>
            <hr
              className="my-0 border-top"
              style={{
                borderTopColor: '#e2e2e2!important',
                borderTopWidth: '2px!important',
              }}
            />
            <div
              style={tableStyle}
              className={isMobileScreen ? 'p-20' : 'px-40 py-20'}>
              <table id="appointment-table" className="table bg-white">
                <thead>
                  <tr>
                    <th
                      className={
                        isMobileScreen ? 'fw-600 fs-15' : 'fw-600 fs-15 pl-24'
                      }
                      style={{ width: isMobileScreen ? '30%' : '15%' }}>
                      Order ID
                    </th>
                    {!isMobileScreen ? (
                      <th className="fw-600 fs-15" style={{ width: '30%' }}>
                        Gift card
                      </th>
                    ) : null}
                    {!isMobileScreen ? (
                      <th className="fw-600 fs-15" style={{ width: '30%' }}>
                        Recipient
                      </th>
                    ) : null}
                    {!isMobileScreen ? (
                      <th className="fw-600 fs-15" style={{ width: '10%' }}>
                        Amount
                      </th>
                    ) : null}
                    <th
                      className="fw-600 fs-15 text-center"
                      style={{ width: '10%' }}>
                      Status
                    </th>
                    <th className="fw-600 fs-15" style={{ width: '10%' }}>
                      Receipt
                    </th>
                    <th className="fw-600 fs-15" style={{ width: '10%' }}></th>
                  </tr>
                </thead>
                <tbody>{render_voucher_transactions}</tbody>
              </table>
            </div>
          </div>
        ) : null}

        {/* LOGIN DETAIL */}
        {isLoading ? null : (
          <div className="card">
            <div
              className={
                isMobileScreen
                  ? 'card-body justify-content-start align-items-center p-20 mb-20'
                  : 'card-body justify-content-start align-items-center px-40 py-20 mb-20'
              }>
              <h3 className="fs-20 fw-600 my-20">Login Details</h3>
              <TextFieldGroupLogin
                label="Email"
                field="email"
                value={data.email}
                placeholder="Type something"
                onChange={this.onChangeLoginDetails}
                disabled={true}
                error={errors_login_details.email}
              />
              <label className="col-form-label font-weight-bold letter-spacing-0 fs-15">
                Password
              </label>
              <TextFieldGroupLogin
                label="Current password"
                field="current_password"
                nonBoldLabel={true}
                type="password"
                value={data.current_password}
                placeholder="Your current password"
                onChange={this.onChangeLoginDetails}
                disabled={isLoading_login_details}
                error={errors_login_details.current_password}
              />
              <TextFieldGroupLogin
                label="New password"
                field="new_password"
                nonBoldLabel={true}
                type="password"
                value={data.new_password}
                placeholder="Type new password"
                onChange={this.onChangeLoginDetails}
                disabled={isLoading_login_details}
                error={errors_login_details.new_password}
              />
              <TextFieldGroupLogin
                label="Confirm password"
                field="confirm_password"
                nonBoldLabel={true}
                type="password"
                value={data.confirm_password}
                placeholder="Type your new password again"
                onChange={this.onChangeLoginDetails}
                disabled={isLoading_login_details}
                error={errors_login_details.confirm_password}
              />
              <Notification notification={notification_login_details} />
              <div
                className={
                  isMobileScreen
                    ? 'd-flex align-items-center flex-column mt-40 text-center bg-white'
                    : 'd-flex align-items-center mt-40 justify-content-between bg-white'
                }>
                <label
                  className="fs-13 mb-0 text-success d-flex align-items-center justify-content-center mt-0"
                  style={{
                    visibility: isEditLoginSuccess ? 'visible' : 'hidden',
                  }}>
                  <span>Changes saved successfully</span>
                  <i className="fa fa-check-circle-o fs-20 mx-2"></i>
                </label>
                <button
                  onClick={this.onSubmitLoginDetails}
                  className="btn btn-main-pink letter-spacing-0">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        )}

        {/* ADDRESS DETAIL */}
        {isLoading ? null : (
          <div className="card">
            <div
              className={
                isMobileScreen
                  ? 'card-body justify-content-start align-items-center p-20 mb-20'
                  : 'card-body justify-content-start align-items-center px-40 py-20 mb-20'
              }>
              <h3 className="fs-20 fw-600 my-20">Address</h3>
              <div className="row m-auto">
                <div
                  className={
                    isMobileScreen ? 'col-md-6 p-0' : 'col-md-6 pl-0 pr-30'
                  }>
                  {/* <AsyncSelectFieldGroup
                    placeholder='Type to search'
                    label="Country"
                    value={data.living_in_country}
                    loadOptions={this.loadOptionsCountry}
                    onChange={(val) => this.onChangeSelectCountry(val, 'address_details')}
                    error={errors_address_details.living_in_country}
                    isDisabled={isLoading_address_details}
                    isClearable={false}
                  /> */}
                  <SelectFieldGroup
                    label="Country"
                    value={data.living_in_country}
                    options={living_in_country_options}
                    onChange={(val) =>
                      this.onChangeSelectCountry(val, 'address_details')
                    }
                    error={errors_address_details.living_in_country}
                    isClearable={false}
                    isDisabled={isLoading_address_details}
                    isSearchable={true}
                  />
                </div>
                <div className="col-md-6 p-0">
                  <SelectFieldGroup
                    label="City"
                    value={data.living_in_city}
                    options={living_in_city_options}
                    onChange={(val) =>
                      this.onChangeSelect({
                        target: { name: 'living_in_city', value: val },
                      })
                    }
                    error={errors_address_details.living_in_city}
                    isClearable={false}
                    isDisabled={
                      isLoading_address_details ||
                      isEmpty(data.living_in_country)
                    }
                    isSearchable={true}
                  />
                </div>
                <div
                  className={
                    isMobileScreen ? 'col-md-6 p-0' : 'col-md-6 pl-0 pr-30'
                  }>
                  <TextFieldGroupLogin
                    label="Street Name"
                    field="street_name"
                    value={data.street_name}
                    placeholder="Type something"
                    onChange={this.onChangeAddressDetails}
                    disabled={isLoading_address_details}
                    error={errors_address_details.street_name}
                  />
                </div>
                <div className="col-md-6 p-0">
                  <TextFieldGroupLogin
                    label="House Number"
                    field="house_number"
                    value={data.house_number}
                    placeholder="Type something"
                    onChange={this.onChangeAddressDetails}
                    disabled={isLoading_address_details}
                    error={errors_address_details.house_number}
                  />
                </div>
                <div
                  className={
                    isMobileScreen ? 'col-md-6 p-0' : 'col-md-6 pl-0 pr-30'
                  }>
                  <TextFieldGroupLogin
                    label="Postal code"
                    field="postcode"
                    value={data.postcode}
                    placeholder="Type something"
                    onChange={this.onChangeAddressDetails}
                    disabled={isLoading_address_details}
                    error={errors_address_details.postcode}
                  />
                </div>
                <div className="col-md-6 p-0">
                  <TextFieldGroupLogin
                    label="Phone Number"
                    field="phone"
                    value={data.phone}
                    placeholder="Type something"
                    onChange={this.onChangeAddressDetails}
                    disabled={isLoading_address_details}
                    error={errors_address_details.phone}
                  />
                </div>
              </div>
              <Notification notification={notification_address_details} />
              <div
                className={
                  isMobileScreen
                    ? 'd-flex align-items-center flex-column mt-40 text-center bg-white'
                    : 'd-flex align-items-center mt-40 justify-content-between bg-white'
                }>
                <label
                  className="fs-13 mb-0 text-success d-flex align-items-center justify-content-center mt-0"
                  style={{
                    visibility: isEditAddressSuccess ? 'visible' : 'hidden',
                  }}>
                  <span>Changes saved successfully</span>
                  <i className="fa fa-check-circle-o fs-20 mx-2"></i>
                </label>
                {isLoading_address_details ? (
                  <MDSpinner />
                ) : (
                  <button
                    onClick={(e) => this.onSubmit(e, 'address_details')}
                    className="btn btn-main-pink letter-spacing-0">
                    Save changes
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {isDownloadError ? (
          <DownloadErrorModal
            closeModal={() => this.setState({ isDownloadError: false })}
          />
        ) : null}
        {isOpenTransactionDetailModal ? (
          <TransactionDetailsModal
            modal_title={modal_title}
            data={selected_transaction}
            onClose={this.closeTransactionModalHandler}
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  userGetSingleProfile,
  userUpdateProfileInfo,
  userChangePassword,
  userGetAllCountries,
  userGetAllWorldCountries,
  userGetCountryDetails,
  userDownloadReceipt,
  userDownloadVoucherReceipt,
})(StudentAccountDetails);
