import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { userLogout } from '../../../redux/actions/authActions';
// import { userGetSingleCustomers } from 'actions/customersActions';

const activeStyles = {
  backgroundColor: 'var(--mainPink)',
  color: '#ffffff',
};

const TopActiveStyles = {
  backgroundColor: 'var(--mainPink)',
  color: '#ffffff',
  borderRadius: '8px 8px 0 0',
};

class SideBarNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // _id: this.props.auth.user._id,
      first_name: '',
      last_name: '',
      // email: this.props.auth.user.email,
      notification: {},
      isLoading: false,
    };
  }

  // componentDidMount = () => {
  //   this.setState({ isLoading: true, notification: {} })
  //   this.props.userGetSingleCustomers({_id : this.state._id}).then(res => {
  //     if(res.status !== 200) {
  //       this.setState({
  //         isLoading: false,
  //         notification: {
  //           text: "Failed to load your profile data. Please try again later",
  //           isError: true,
  //         }
  //       })
  //     } else {
  //       this.setState({
  //         isLoading: false,
  //         first_name: res.message.data.first_name,
  //         last_name: res.message.data.last_name,
  //       })
  //     }
  //   }).catch(err => {
  //     this.setState({
  //       isLoading: false,
  //       notification: {
  //         text: "We are experiencing technical difficulties at the moment, please try again later",
  //         isError: true
  //       }
  //     })
  //   })
  // }

  userLogout = () => {
    this.props.userLogout();
  };

  render() {
    // const { first_name, last_name, email } = this.state;

    const { type } = this.props.auth.user;

    return (
      <div id="profile-sidebar" className="card">
        <div className="card-body p-0">
          <div className="sidebar-menu">
            <ul className="menu menu-md mb-10">
              <li className="menu-item">
                <NavLink
                  className="menu-link py-30 cursor-pointer"
                  to="/profile/edit"
                  activeStyle={TopActiveStyles}>
                  <div className="sidebar-icon icon-edit"></div>
                  <span className="title fs-18 cursor-pointer">
                    Edit Profile
                  </span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  className="menu-link py-30 cursor-pointer"
                  to="/profile/appointments"
                  activeStyle={activeStyles}>
                  <div className="sidebar-icon icon-appointment"></div>
                  <span className="title fs-18 cursor-pointer">
                    My Appointments
                  </span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  className="menu-link py-30 cursor-pointer"
                  to="/profile/account"
                  activeStyle={activeStyles}>
                  <div className="sidebar-icon icon-account"></div>
                  <span className="title fs-18 cursor-pointer">
                    Account Details
                  </span>
                </NavLink>
              </li>
              <li className="menu-item" role="button" onClick={this.userLogout}>
                <NavLink className="menu-link py-30 cursor-pointer" to={type === 'tutor' ? "/login/tutor" : "/login"}>
                  <div className="sidebar-icon icon-logout"></div>
                  <span className="title fs-18 cursor-pointer">Logout</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(connect(mapStateToProps, { userLogout })(SideBarNav));
