import axios from 'axios';

// Set config defaults url
const { REACT_APP_BASE_API, REACT_APP_BASE_BOOKING_DOMAIN } = process.env;

export const API = axios.create({
	baseURL: REACT_APP_BASE_API,
});

export const DOMAIN = 'https://flowently.com';

export const BOOKING_DOMAIN = REACT_APP_BASE_BOOKING_DOMAIN;
