import { API } from '../../utils/api';

export default Object.freeze({
	register: ({
		email,
		password,
		firstName,
		lastName,
		living_in_country,
		living_in_city,
		session_type,
		learn_language,
		type,
		phone,
		files,
	}) =>
		API.post(
			`/auth/register?email=${email}&password=${password}&first_name=${firstName}&last_name=${lastName}&living_in_country=${JSON.stringify(
				living_in_country
			)}&living_in_city=${JSON.stringify(
				living_in_city
			)}&session_type=${JSON.stringify(
				session_type
			)}&learn_language=${JSON.stringify(
				learn_language
			)}&type=${type}&phone=${phone}`,
			files,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			}
		),
	registerStudent: ({
		email,
		password,
		firstName,
		lastName,
		living_in_country,
		living_in_city,
		session_type,
		learn_language,
		phone,
		type,
		withRef,
	}) =>
		API.post(
			`/auth/register?email=${email}&password=${password}&first_name=${firstName}&last_name=${lastName}&living_in_country=${JSON.stringify(
				living_in_country
			)}&living_in_city=${JSON.stringify(
				living_in_city
			)}&session_type=${JSON.stringify(
				session_type
			)}&learn_language=${JSON.stringify(
				learn_language
			)}&type=${type}&withRef=${withRef}&phone=${phone}`,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			}
		),
	setPassword: (payload) =>
		API.post('/auth/password', payload, {
			headers: { 'Content-Type': 'application/json' },
		}),
	login: (payload) =>
		API.post('/auth/login', payload, {
			headers: { 'Content-Type': 'application/json' },
		}),
	forgot: (payload) =>
		API.post('/auth/forgot', payload, {
			headers: { 'Content-Type': 'application/json' },
		}),
	reset: (payload) =>
		API.post('/auth/reset', payload, {
			headers: { 'Content-Type': 'application/json' },
		}),
});
